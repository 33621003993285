import NumberFormat from 'react-number-format';
import {ArrowSmRightIcon, ArrowSmLeftIcon} from '@heroicons/react/outline';
import SpinnerLottie from '../../CommonModules/spinnerLottie';
export default function Storage(props) {
	const {
		handleChange,
		weightConst,
		stackableConst,
		rackableConst,
		quantityConst,
		volumeConst,
		inboundModeConst,
		outboundModeConst,
		transportationAssistanceConst,
		state,
		setAverageOutboundVolumeFormatted,
		averageOutboundVolumeFormatted,
		setAverageOutboundVolume,
		validateBack,
		validateStorage,
		setAverageInboundVolume,
		setAverageInboundVolumeFormatted,
		averageInboundVolumeFormatted,
		quantityFormatted,
		setQuantityFormatted,
		setQuantity,
		approxRequiredSqaureFootageFormatted,
		setApproxRequiredSqaureFootageFormatted,
		setApproxRequiredSqaureFootage,
		setAverageInboundQtyFormatted,
		averageInboundQtyFormatted,
		setAverageInboundQty,
		setAverageOutboundQtyFormatted,
		averageOutboundQtyFormatted,
		setAverageOutboundQty,
		stepThirdFrom,
		approxRequiredSqaureFootage,
		final,
	} = props;
	return (
		<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
			<div className="col-span-3 md:col-span-3">
				<label
					htmlFor="productDimensions"
					className="block text-sm font-medium text-gray-700"
				>
					Quantity:
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						autoFocus
						value={quantityFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setQuantityFormatted(formattedValue);
							setQuantity(value);
						}}
					/>
				</div>
			</div>
			<div className="col-span-1">
				<label htmlFor="quantityType" className="block text-sm font-medium text-gray-700 mt-6"></label>
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="quantityType"
					value={state.quantityType}
					onChange={handleChange}
				>
					<option value="">Select</option>
					{quantityConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="col-span-2">
				<label
					htmlFor="rackable"
					className="block text-sm font-medium text-gray-700"
				>
					Approximate SqFt Required
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						placeholder="Approx Sq. Ft. Required"
						value={approxRequiredSqaureFootageFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setApproxRequiredSqaureFootageFormatted(formattedValue);
							setApproxRequiredSqaureFootage(value);
						}}
					/>
					{stepThirdFrom && approxRequiredSqaureFootage === '' && (
						<div className="block text-sm font-medium text-red-500">
							Approximate SqFt is required
						</div>
					)}
				</div>
			</div>
			<div className="sm:col-span-1">
				<label
					htmlFor="rackable"
					className="block text-sm font-medium text-gray-700"
				>
					Rackable
				</label>
				<div className="mt-1">
					<select
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						name="rackable"
						value={state.rackable}
						onChange={handleChange}
					>
						<option value="">Select</option>
						<option value={true}>Yes</option>
						<option value={false}>No</option>
					</select>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-2">
				<label
					htmlFor="quantityType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={
						state.rackable === ''
							? true
							: state.rackable === 'false'
							? true
							: false
					}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						state.rackable === ''
							? 'bg-gray-200'
							: state.rackable === 'false'
							? ' bg-gray-200'
							: ''
					} border-gray-300 rounded-md`}
					name="rackableType"
					value={state.rackableType}
					onChange={handleChange}
				>
					<option value="">Select</option>
					{rackableConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="sm:col-span-1">
				<label
					htmlFor="stackable"
					className="block text-sm font-medium text-gray-700"
				>
					Stackable
				</label>
				<div className="mt-1">
					<select
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						name="stackable"
						value={state.stackable}
						onChange={handleChange}
					>
						<option value="">Select</option>
						<option value={true}>Yes</option>
						<option value={false}>No</option>
					</select>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-2">
				<label
					htmlFor="quantityType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={
						state.stackable === ''
							? true
							: state.stackable === 'false'
							? true
							: false
					}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						state.stackable === ''
							? 'bg-gray-200'
							: state.stackable === 'false'
							? 'bg-gray-200'
							: ''
					} border-gray-300 rounded-md`}
					name="stackableType"
					value={state.stackableType}
					onChange={handleChange}
				>
					<option value="">Select</option>
					{stackableConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="sm:col-span-1">
				<label
					htmlFor="productDimensions"
					className="block text-sm font-medium text-gray-700"
				>
					Avg. Inbound
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						value={averageInboundQtyFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setAverageInboundQtyFormatted(formattedValue);
							setAverageInboundQty(value);
						}}
					/>
				</div>
			</div>
			<div className="sm:col-span-2">
				<label
					htmlFor="averageInboundQtyType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={averageInboundQtyFormatted === '' ? true : false}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						averageInboundQtyFormatted === '' ? ' bg-gray-200' : ''
					} border-gray-300 rounded-md`}
					name="averageInboundQtyType"
					value={state.averageInboundQtyType}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Select</option>
					{quantityConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="sm:col-span-1">
				<label className="block text-sm font-medium text-gray-700">
					{'Avg. Inb. Vol'}
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						value={averageInboundVolumeFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setAverageInboundVolumeFormatted(formattedValue);
							setAverageInboundVolume(value);
						}}
					/>
				</div>
			</div>
			<div className="sm:col-span-2">
				<label
					htmlFor="averageInboundVolumeType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={averageInboundVolumeFormatted === '' ? true : false}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						averageInboundVolumeFormatted === '' ? ' bg-gray-200' : ''
					} border-gray-300 rounded-md`}
					name="averageInboundVolumeType"
					value={state.averageInboundVolumeType}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Select</option>
					{volumeConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="sm:col-span-1">
				<label className="block text-sm font-medium text-gray-700">
					Avg. Outbound
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						value={averageOutboundQtyFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setAverageOutboundQtyFormatted(formattedValue);
							setAverageOutboundQty(value);
						}}
					/>
				</div>
			</div>
			<div className="col-span-2">
				<label
					htmlFor="averageOutboundQtyType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={averageOutboundQtyFormatted === '' ? true : false}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						averageOutboundQtyFormatted === '' ? ' bg-gray-200' : ''
					} border-gray-300 rounded-md`}
					name="averageOutboundQtyType"
					value={state.averageOutboundQtyType}
					onChange={handleChange}
					// onChange={(e) => {
					// 	this.handleChange(e);
					// 	// if (e.target.value == SOWEnum.Quantity.Other) {
					// 	// 	this.setState({averageOutboundQtyTypeOther: ''});
					// 	// }
					// }}
				>
					<option value="">Select</option>
					{quantityConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="col-span-1">
				<label className="block text-sm font-medium text-gray-700">
					Avg. Out Vol
				</label>
				<div className="mt-1">
					<NumberFormat
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
						value={averageOutboundVolumeFormatted}
						thousandSeparator={true}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setAverageOutboundVolumeFormatted(formattedValue);
							setAverageOutboundVolume(value);
						}}
					/>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-2">
				<label
					htmlFor="averageOutboundVolumeType"
					className="block text-sm font-medium text-gray-700 mt-6"
				>
					{`\n`}
				</label>
				<select
					disabled={averageOutboundVolumeFormatted === '' ? true : false}
					className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400 ${
						averageOutboundVolumeFormatted === '' ? ' bg-gray-200' : ''
					} border-gray-300 rounded-md`}
					name="averageOutboundVolumeType"
					value={state.averageOutboundVolumeType}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Select</option>
					{volumeConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="col-span-2">
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="inboundMode"
					value={state.inboundMode}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Select InBound Mode</option>
					{inboundModeConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="col-span-2">
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="outboundMode"
					value={state.outboundMode}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Select OutBound Mode</option>
					{outboundModeConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>

			<div className="col-span-2">
				<select
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					name="transportationAssistance"
					value={state.transportationAssistance}
					onChange={(e) => {
						handleChange(e);
					}}
				>
					<option value="">Transportation Assistance</option>
					{transportationAssistanceConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateBack(e, 1)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						Previous
					</button>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={final ? null : (e) => validateStorage(e)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						{final ? (
							<SpinnerLottie isOpen={true} />
						) : (
							<>
								{' '}
								Complete
								<ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
							</>
						)}
					</button>
				</div>
			</div>
		</div>
	);
}
