import create from "zustand";
import {
  filterWarehouseByScopeId,
  filterWarehouseByType,
  getAllSowsShipper,
  logoutAction,
  pairwarehouseList,
  scopeCreatedAction,
} from "../actions";
import {useMixpanel} from "./useMixpanel";

export const useShipperStore = create((set, get) => ({
  loading: false,
  allSows: [],
  scopeCreated: false,
  warehouses: [],

  listAllSowsShipper: (history) => {
    set({ loading: true, allSows: [] });
    return new Promise((resolve, reject) => {
      getAllSowsShipper()
        .then((resp) => {
          if (resp.status === 200) {
            set({
              loading: false,
              allSows: resp.data.value && resp.data.value.soWs
            });
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, allSows: [] });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          console.warn(JSON.stringify(err));
          set({ loading: false, allSows: [] });
          reject(err);
        });
    });
  },

  isScopeCreated: (requestObj, history, internalPage) => {
    set({ loading: true, scopeCreated: true });
    return new Promise((resolve, reject) => {
      scopeCreatedAction(requestObj)
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, scopeCreated: true });
            useMixpanel.getState().track('Scope Created', resp.data);
            if (internalPage === false) {
              history.push('/explore-warehouses')
            } else {
              localStorage.setItem('lockPage', 'true');
            }
            get().listAllSowsShipper(history);
          } else {
            reject(resp.data);
            set({ loading: false, scopeCreated: false });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          console.warn(JSON.stringify(err));
          set({ loading: false, scopeCreated: false });
          reject(err);
        });
    });
  },

  filterWareHouseByScopeID: (scopeId, history) => {
    set({ loading: true, warehouses: [] });
    return new Promise((resolve, reject) => {
      filterWarehouseByScopeId(scopeId)
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, warehouses: resp.data.value && resp.data.value.warehouses  });
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, warehouses: []  });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          console.warn(JSON.stringify(err));
          set({ loading: false, warehouses: []  });
          reject(err);
        });
    });
  },

  filterWareHouseByType: (filterObj, history) => {
    set({ loading: true, warehouses: [] });
    return new Promise((resolve, reject) => {
      filterWarehouseByType(filterObj)
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, warehouses: resp.data && resp.data.warehouses });
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, warehouses: []  });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          set({ loading: false, warehouses: []  });
          reject(err);
        });
    });
  },

  pairWarehouseList: (history) => {
    set({ loading: true, warehouses: [] });
    return new Promise((resolve, reject) => {
      pairwarehouseList()
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: true, warehouses: resp.data && resp.data.value && resp.data.value.warehouses });
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, warehouses: [] });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          set({ loading: false, warehouses: [] });
          reject(err);
        });
    });
  },
}))