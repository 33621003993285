/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import {Fragment} from 'react';
export default function WareHouseNav(props) {
	const {navigation, headerType, headerSet} = props;

	return (
		<div className="ml-10 flex items-end space-x-4 select-none">
			{navigation.map((item, itemIdx) =>
				itemIdx === 0 ? (
					<Fragment key={item}>
						<a
							className={
								headerType === item
									? 'flex flex-row pb-4 pt-3 px-3 bg-gray-200 text-gray-700 rounded-t-md text-sm font-medium'
									: 'flex flex-row mt-2 pb-4 pt-3 px-3 text-white hover:bg-indigo-700 hover:bg-opacity-75 rounded-t-md text-sm font-medium'
							}
							onClick={(e) => headerSet(e, item)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 mr-2"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/>
							</svg>
							{item}
						</a>
					</Fragment>
				) : (
					<a
						key={item}
						className={
							headerType === item
								? 'flex flex-row pb-4 pt-3 px-3 bg-gray-200 text-gray-700 rounded-t-md text-sm font-medium'
								: 'flex flex-row mt-2 pb-4 pt-3 px-3 text-white hover:bg-indigo-700 hover:bg-opacity-75 rounded-t-md text-sm font-medium'
						}
						onClick={(e) => headerSet(e, item)}
					>
						{itemIdx === 1 ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 mr-2"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 mr-2"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
								/>
							</svg>
						)}
						{item}
					</a>
				)
			)}
		</div>
	);
}
