import create from 'zustand';
import {
  addUserPaymentAction, getMySubscriptionAction,
  getSubscriptionPlanAction,
  hasPaymentMethodAction,
  logoutAction,
  subscribedPlanAction
} from "../actions";
import {actionCreator} from "../utils";
import {toastAlert} from "../components/Modules/CommonModules/alertTemplate";

export const usePaymentStore = create((set, get) => ({
  loading: false,
  subscription: null,
  haspaymentVal: null,
  haspaymentStatus: null,
  planList: [],

  listSubscriptions: (history) => {
    set({ loading: true, subscription: null });
    return new Promise((resolve, reject) => {
      getMySubscriptionAction()
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, subscription: 200 });
            if (resp.data.value !== null) {
              // const endTime = resp.data.value !== null && resp.data.value.subscription_End ? moment(resp.data.value.subscription_End).isAfter(new Date()) : false
              let lapsMethod = resp.data.value && resp.data.value.lapsedMethod && resp.data.value.lapsedMethod === true ? true : false
              localStorage.setItem('subscription', lapsMethod ? 400 : 200);
            } else if (resp.data.value === null) {
              localStorage.setItem('subscription', 400);
            }

          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, subscription: 400 });
            localStorage.setItem('subscription', 400);
          }
          resolve(resp);
        })
        .catch((err) => {
          console.warn(JSON.stringify(err));
          set({ loading: false, subscription: 400 });
          reject(err);
        });
    });
  },

  hasPaymentMethod: (history, type) => {
    set({ loading: true, haspaymentVal: null, haspaymentStatus: null });
    return new Promise((resolve, reject) => {
      hasPaymentMethodAction()
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: true, haspaymentVal: resp.data.value, haspaymentStatus: 200 });
            // localStorage.setItem('subscription', resp.status);
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            localStorage.setItem('subscription', resp.status);
            set({ loading: false, haspaymentVal: null, haspaymentStatus: 400 });
            get().listSubscriptionList(history, type);
          }
          resolve(resp);
        })
        .catch((err) => {
          console.warn(JSON.stringify(err));
          set({ loading: false, haspaymentVal: null, haspaymentStatus: 400 });
          reject(err);
        });
    });
  },

  addUserPayment: (requestObj, history, type, alert) => {
    set({ loading: true, haspaymentVal: null, haspaymentStatus: null });
    return new Promise((resolve, reject) => {
      addUserPaymentAction(requestObj)
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, haspaymentVal: resp.data, haspaymentStatus: 200 });
            get().listSubscriptionList(history, type);
            // history.push('/explore-warehouses')
          } else {
            set({ loading: false, haspaymentVal: null, haspaymentStatus: 400 });
            const { errors } = resp.data;

            if (errors && errors.CardNumber) {
              toastAlert(
                { title: 'Error', discription: errors.CardNumber[0] },
                { type: 'error' })
            } else if (errors && errors.CardOwnerLastName) {
              toastAlert(
                { title: 'Error', discription: errors.CardOwnerLastName[0] },
                { type: 'error' })
            } else if (errors && errors.CardOwnerFirstName) {
              toastAlert(
                { title: 'Error', discription: errors.CardOwnerFirstName[0] },
                { type: 'error' })
            } else if (resp.data && resp.data.value) {
              toastAlert(
                { title: 'Error', discription: resp.data.value },
                { type: 'error' })
            }

          }
          resolve(resp.data);
        })
        .catch((err) => {
          console.warn(err)
          set({ loading: false, haspaymentVal: null, haspaymentStatus: 400 });
          toastAlert(
            { title: 'Error', discription: JSON.stringify(err.message) },
            { type: 'error' })
          reject(err);
        });
    });
  },

  listSubscriptionList: (history, type) => {
    set({ loading: true, planList: [] });
    return new Promise((resolve, reject) => {
      getSubscriptionPlanAction()
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, planList: resp.data.value });
            const data = {
              "PriceId": resp.data.value.subscriptions[0].id
            }
            get().fetchSubscribedPlan(data, history, type);
          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, planList: [] });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          set({ loading: false, planList: [] });
          reject(err);
        });
    });
  },

  fetchSubscribedPlan: (requestObj, history, type) => {
    set({ loading: true, subscription: null });
    return new Promise((resolve, reject) => {
      subscribedPlanAction(requestObj)
        .then((resp) => {
          if (resp.status === 200) {
            set({ loading: false, subscription: 200 });
            localStorage.removeItem('lockPage')
            localStorage.setItem('subscription', 200);
            if (type.toLowerCase() === 'supplier'.toLowerCase()) {
              history.push('/');
            } else if (type.toLowerCase() === 'Shipper'.toLowerCase()) {
              history.push('/');
            } else {
              history.push('/login');
            }

          } else if (resp.status === 401) {
            logoutAction(history);
          } else {
            set({ loading: false, subscription: 400 });
          }
          resolve(resp.data);
        })
        .catch((err) => {
          set({ loading: false, subscription: 400 });
          reject(err);
        });
    });
  }
}));

