import {Tabs, TabPanel} from 'react-tabs';
import {useState} from 'react';
import {Transition} from '@headlessui/react';

import Lottie from 'react-lottie-player';
import lockUnlock from '../../../../assets/anim/lock_unlock.json';

import AddWarehouse from '../../addWarehouse';
import AddCardMethod from '../../paymentModule/addCardMethod';

export default function OnBoarding({
	currentIndex,
	history,
	warehouseList,
	subscribedUser,
	setPremiumBG,
}) {
	const [segmentFrom, setSegmentFrom] = useState(20);
	const [segmentTo, setSegmentTo] = useState(50);
	const [segmentsEnabled, setSegmentsEnabled] = useState(true);
	const [play, setPlay] = useState(true);
	const [loop, setLoop] = useState(false);
	const [speed, setSpeed] = useState(1);
	const [direction, setDirection] = useState(1); //-1
	const segments = [segmentFrom, segmentTo];

	const [tabIndex, setTabIndex] = useState(0);

	function playForwards() {
		setSegmentFrom(20);
		setSegmentTo(50);
		setPlay(true);
	}

	function playBackwards() {
		setSegmentFrom(50);
		setSegmentTo(20);
		setPlay(true);
	}

	function sendToParent(index) {
		if (warehouseList && warehouseList.length > 0 && subscribedUser === '400') {
			currentIndex(2);
			setTabIndex(2);
			setPremiumBG(0);
		} else if (
			warehouseList &&
			warehouseList.length === 0 &&
			subscribedUser === '400'
		) {
			currentIndex(index);
			setTabIndex(index);
			setPremiumBG(0);
		} else {
		}
		//history.push('/add-scope');
	}

	return (
		<div className="flex flex-row h-full w-full jusify-center px-7">
			<Tabs
				selectedIndex={tabIndex}
				onSelect={(index) => setTabIndex(index)}
				className={'w-full'}
			>
				<TabPanel>
					<Transition
						appear
						show={true}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<div>
							<Lottie
								loop={loop}
								speed={speed}
								play={play}
								animationData={lockUnlock}
								direction={direction}
								segments={segmentsEnabled && segments}
								style={{
									width: 200,
									height: 200,
									margin: -10,
									alignSelf: 'center',
								}}
							/>
							<h1 className="mx-auto text-xl font-bold text-gray-800">
								Upgrade Now To Instantly Find Your Perfect Shipper.
							</h1>
							<p className="text-md text-gray-700">
								With Popcapacity Premium, you receive on-demand warehousing
								services, including instant matching and market insights that
								will make your warehouse sourcing experience even more
								convenient.
							</p>
							<button
								type="button"
								onMouseLeave={() => playBackwards()}
								onMouseEnter={() => playForwards()}
								onClick={() => {
									setTimeout(() => {
										sendToParent(1);
									}, 1000);
								}}
								className="flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white mt-10 bg-yellow-600 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600"
							>
								Get Started
							</button>
						</div>
					</Transition>
				</TabPanel>
				<TabPanel>
					<Transition
						appear
						show={true}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<AddWarehouse sendToParent={sendToParent} />
					</Transition>
				</TabPanel>
				<TabPanel>
					<Transition
						appear
						show={true}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<AddCardMethod />
					</Transition>
				</TabPanel>
			</Tabs>
		</div>
	);
}
