export const activeContracts = 'Active Contracts';
export const sentProposals = 'Sent Proposals';
export const inComingContracts = 'Incoming Proposals';
export const yourProfile = 'Your Profile';
export const settings = 'Settings';
export const signout = 'Sign out';
export const explorehipperConstant = 'Explore Shippers';
export const contractsProposal = 'Contracts and Proposals';
export const myWarehouseConstant = 'My Warehouses';
export const startDateConstant = '0001-01-01';
export const supplier = "Supplier";
export const shipper = "Shipper";
export const exploreWarehouseConstant = 'Explore Warehouses';
export const proposalsConstant = 'Proposals';
export const myproposalsConstant = 'My Proposals';
export const scopeBy = 'scope';
export const typeBy = 'type';
export const addNewScope = 'Add New Scope';
export const manageScope = 'Manage Scope';
export const warehouse = 'Warehouse';


export const contractHeaderList = [activeContracts, sentProposals, inComingContracts];
export const navigationHeaderList = [
    explorehipperConstant,
    contractsProposal,
    myWarehouseConstant,
];
export const navigationHeaderListShipper = [
    exploreWarehouseConstant,
    proposalsConstant
];
export const profileList = [signout]; //yourProfile, settings,
export const userRoleList = [supplier, shipper];
export const proposalListHeader = [myproposalsConstant, activeContracts]