import React from 'react';

export default function Modal({isOpen, setOpen, modalTitle, children}) {
	const [showModal, setShowModal] = React.useState(isOpen);
	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex h-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="p-10 relative mx-auto w-full max-w-7xl h-full">
							{/*content*/}
							<div className="h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-3xl font-semibold">{modalTitle || ''}</h3>
									<button
										className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										onClick={() => setOpen(false)}
									>
										Close
									</button>
								</div>
								{/*body*/}
								<div className="relative p-6 flex-auto">{children}</div>
								{/*footer*/}
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}
