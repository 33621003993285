import {Fragment} from 'react';

export default function NotFound() {
	return (
		<Fragment>
			<div className="flex justify-center">
				<span className="flex-1 text-sm text-gray-700 text-center">
					No warehouses that match the category were found. Please refine your search and try again.
					<br/>
					<div className="px-1 py-1 rounded-lg bg-gray-200 text-md my-2 font-semibold">Tip: Select from the drop down when searching a location for better search results.</div>
				</span>
			</div>
		</Fragment>
	);
}
