// https://hooks.slack.com/services/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

import RestClient from "../utils/RestClient";

export const reportBot = (data) => {
  RestClient.post(`https://hooks.slack.com/services/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`, { text: data }, "application/json")
    .then((resp) => {
      if (resp.status === 200) {
        console.log("Success", resp);
      } else if (resp.status === 401) {
        console.log("Error", resp);
      } else {
        console.log("Error From Else", resp);
      }
    })
    .catch((err) => {
      console.warn(JSON.stringify(err));
    });
};
