import {ArrowSmLeftIcon} from '@heroicons/react/outline';

export default function HeaderBox(props) {
	const {backToMyWareHouses} = props;
	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full grid md:grid-flow-col space-x-96">
				<button
					type="button"
					onClick={(e) => backToMyWareHouses(e)}
					className="flex flex-row items-center text-gray-500 bg-gray-300 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 w-24"
				>
					<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
					Back
				</button>
			</div>
		</div>
	);
}
