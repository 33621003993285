import { actionCreator, API_BASE_URL } from "../utils/reduxMethods";
import RestClient from "../utils/RestClient";
import { logoutAction } from "./userAction";
import {toastAlert} from "../components/Modules/CommonModules/alertTemplate";
import {useMixpanel} from "../store/useMixpanel";

export const exploreShippersAction = (signupObj) => {
  return new Promise((resolve, reject) => {
    RestClient
      .post(
        `${API_BASE_URL}/Supplier/exploreshippers`,
        JSON.stringify(signupObj)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getsowbyidShippersAction = (id) => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/Shipper/getsowbyid?Id=${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getsowbyidShipper = (id) => {
  return new Promise((resolve, reject) => {
    RestClient.get(`${API_BASE_URL}/Shipper/getsowbyid?Id=${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value);
        } else {
          reject(resp.data.errors && resp.data.errors.id[0]);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierContracts = (type = null) => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/${type === true ? "Shipper" : "Supplier"}/getcontracts`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierProposals = (type = null) => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/${type === true ? "Shipper" : "Supplier"}/getproposals`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierIncomingProposals = () => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/Proposal/GetIncomingProposal`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProposalByIdService = (id) => {
  return new Promise((resolve, reject) => {
    RestClient.get(`${API_BASE_URL}/Supplier/getproposalbyid?Id=${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value);
        } else {
          reject(resp.data.errors && resp.data.errors.id[0]);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getContractDeatil = (id) => {
  return new Promise((resolve, reject) => {
    RestClient.get(`${API_BASE_URL}/Account/getcontactinfo?UserId=${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value);
        } else {
          reject(resp.data.errors && resp.data.errors.id[0]);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierWarehouse = () => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/Warehouse/getbysupplier`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierWarehouseList = () => {
  return new Promise((resolve, reject) => {
    RestClient.get(`${API_BASE_URL}/Warehouse/getbysupplier`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value.warehouses);
        } else if (resp.status === 400) {
          reject([]);
        }
      })
      .catch((err) => {
        console.warn(err);
        reject([]);
      });
  });
};
/**
 *
 * @param {warehouseId} warehouseId
 * @param {*} history
 * it fetches the  warehouse by Id and oth parameters are required
 */
export const getWareHouseById = (warehouseId, history) => {
  return new Promise((resolve, reject) => {
    RestClient.get(`${API_BASE_URL}/Warehouse/getmywarehousebyid?id=${warehouseId}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value);
        } else if (resp.status === 400) {
          reject(resp.data.errors && resp.data.errors.id[0]);
        } else if (resp.status === 401) {
          logoutAction(history);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addRemoveService = (formData, history) => {
  return new Promise((resolve, reject) => {
    RestClient.post(`${API_BASE_URL}/Photosphere/addremove`, formData, true)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp);
        } else if (resp.status === 400) {
          reject(resp.data.errors && resp.data.errors.WarehouseId[0]);
        } else if (resp.status === 401) {
          logoutAction(history);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateWarehouseDetail = (formData, history) => {
  return new Promise((resolve, reject) => {
    RestClient.post(`${API_BASE_URL}/Warehouse/update`, formData, true)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.value);
        } else if (resp.status === 400) {
          reject(resp.data.errors && resp.data.errors.WarehouseId[0]);
        } else if (resp.status === 401) {
          logoutAction(history);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *
 * supplier registeration
 *
 */
export const supplierRegisteration = (requestObj) => {
  return new Promise((resolve, reject) => {
    RestClient
      .post(
        `${API_BASE_URL}/Account/registersupplier`,
        JSON.stringify(requestObj)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 *
 * @param {*} formData
 * @param {*} history
 * @returns
 */
export const addWarehouseDetail = (formData, history) => {
  return new Promise((resolve, reject) => {
    RestClient.post(`${API_BASE_URL}/Warehouse/create`, formData, true)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else if (resp.status === 400) {
          reject(resp.data.errors && resp.data.errors.WarehouseId[0]);
        } else if (resp.status === 401) {
          logoutAction(history);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitProposalMethod = (obj) => {
  return new Promise((resolve, reject) => {
    RestClient.post(`${API_BASE_URL}/api/Shipper/updateproposalstatus`, obj)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data.value);
        } else {
          reject(resp.data.errors && resp.data.errors.id[0]);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deletWarehouseMethod = (id) => {
  return new Promise((resolve, reject) => {
    RestClient.delete(`${API_BASE_URL}/api/Warehouse/delete?id=${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve("true");
        } else {
          reject(resp.data.errors);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitProposalWarehouse = (data, history, alert) => {
  return new Promise((resolve, reject) => {
    RestClient.post(`${API_BASE_URL}/Supplier/submitproposal`, data, false)
      .then((resp) => {
        //
        //
        if (resp.status === 200) {
          useMixpanel.getState().track("Proposal Submitted", { "details": data });
          toastAlert({ title: "Success", discription: resp.data }, { type: "success" });
          resolve(resp.data.value);
        } else if (resp.status === 400) {
          toastAlert({ title: "Error", discription: resp.data.value }, { type: "error" });
          reject(resp.data.value);
        } else if (resp.status === 401) {
          logoutAction(history);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
