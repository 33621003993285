import {Fragment, useState} from 'react';
import {
	ArrowCircleLeftIcon,
	ArrowCircleRightIcon,
} from '@heroicons/react/outline';

export default function Slider({images, index = null}) {
	const imagesLength = images.length;
	const [activeIndex, setActiveIndex] = useState(index ? index : 0);
	const nextTick = (e) => {
		e.preventDefault();
		if (activeIndex < imagesLength - 1) {
			setActiveIndex(activeIndex + 1);
		}
	};
	const prevTick = (e) => {
		e.preventDefault();
		if (activeIndex !== 0) {
			setActiveIndex(activeIndex - 1);
		}
	};

	return (
		<div className="relative group overflow-hidden rounded-xl h-56 bg-gray-200">
			<ArrowCircleLeftIcon
				className={`absolute my-auto h-10 w-10 bg-black rounded-full shadow-lg z-10 top-0 bottom-0 ${
					activeIndex === 0 && 'hidden'
				} left-4 bg-opacity-40 text-white`}
				aria-hidden="true"
				onClick={prevTick}
			/>
			<ArrowCircleRightIcon
				className={`absolute my-auto h-10 w-10 bg-black rounded-full shadow-lg z-10 top-0 bottom-0 ${
					activeIndex === imagesLength - 1 && 'hidden'
				} right-4 bg-opacity-40 text-white`}
				aria-hidden="true"
				onClick={nextTick}
			/>
			{images.map((v, i) => {
				return (
					<Fragment key={i}>
						<img
							className={`absolute block h-full w-full ${
								activeIndex === i ? '' : 'hidden'
							}`}
							src={`${v.imageURL}&amp;auto=format&amp;fit=crop&amp;w=512&amp;q=80`}
							alt=""
							style={{objectFit: 'cover'}}
						/>
					</Fragment>
				);
			})}
		</div>
	);
}
