import React, { Fragment } from 'react';
import { Routes } from './Routes'


function App() {
  const versionApp = localStorage.getItem('app_version');
  if (versionApp && versionApp === 'v2') {
    
//
  } else {
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies()
    localStorage.setItem('app_version', 'v2');
    window.location.reload();
  }
  return (
    <Fragment>
      <Routes />
    </Fragment>
  );
}
function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
export default App;
