import {useState, useMemo, Fragment} from 'react';
import ActiveContracts from './components/activeContracts';
import MyProposals from './components/myProposals';
import ImcomingContracts from './components/incomingContracts';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import ContractHeader from './components/contractHeader';
import {
	activeContracts,
	inComingContracts,
	sentProposals,
} from '../../../constants';
import {useWarehouseStore} from "../../../store/index";

export default function Contracts() {
	const [ctHeader, setCtHeader] = useState(activeContracts);
	const { listContracts, listProposals, listIncomingProposals, listWarehouses } = useWarehouseStore()
	const headerctHeader = (e, val) => {
		e.preventDefault();
		setCtHeader(val);
	};
	useMemo(() => {
		if (ctHeader.toLowerCase() === activeContracts.toLowerCase()) {
			listContracts();
		} else if (ctHeader.toLowerCase() === sentProposals.toLowerCase()) {
			listProposals();
		} else if (ctHeader.toLowerCase() === inComingContracts.toLowerCase()) {
			listWarehouses();
			listIncomingProposals();
		}
	}, [ctHeader]);
	return (
		<Fragment>
			<header className="shadow-sm bg-gray-200">
				<ContractHeader ctHeader={ctHeader} headerctHeader={headerctHeader} />
			</header>
			<TableWrapperHoc>
				{ctHeader.toLowerCase() === activeContracts.toLowerCase() ? (
					<ActiveContracts />
				) : ctHeader.toLowerCase() === sentProposals.toLowerCase() ? (
					<MyProposals />
				) : ctHeader.toLowerCase() === inComingContracts.toLowerCase() ? (
					<ImcomingContracts />
				) : null}
			</TableWrapperHoc>
		</Fragment>
	);
}
