import AmenitiesIcons from "../../shipperDashboard/components/amenities";

export default function StackWarehouseLoaders() {
  return (
    <div className="flex max-w-sm max-h-sm bg-gray-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden animate-pulse">
      <div className="flex flex-col justify-between flex-1 p-3">
        <div className="flex justify-between select-none">
          {/* <AmenitiesIcons responsive={false}/> */}
          <div
            className="flex text-sm mb-4 justify-end text-gray-500 invisible"
          >
            ...
          </div>
        </div>
        <div
          className="flex pl-2 rounded-xl h-8 bg-gray-200 mb-2 items-center text-gray-500"
          
          style={{ fontFamily: "ocr-a-bt, sans-serif" }}
        >
        </div>
        <div className="relative group overflow-hidden rounded-xl h-56 bg-gray-200"></div>
        <div className="mt-2">
          <div className="border border-gray-100 mb-2"></div>
          <div className="flex flex-row items-center justify-between">
            <div className="w-5/12 rounded-md h-2">
              {/* <ProgressBar
                height={"10px"}
                isLabelVisible={false}
                completed={Number(capacityPercentage)}
                bgColor={"#F87171"}
                baseBgColor={"#10B981"}
              /> */}
            </div>
            <span className="text-sm text-gray-200 bg-gray-200 rounded-lg select-none">
              Available Capacity
              <span className="font-semibold text-gray-500">
                
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
