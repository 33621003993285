import { MultiSelect } from 'react-multi-select-component';
import NumberFormat from 'react-number-format';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import ReactGoogleMapLoader from 'react-google-maps-loader';

export default function ExploreShipperHeader(props) {
	const {
		searchLocation,
		handleAddressSelect,
		setLocation,
		setSearchLocation,
		GOOGLE_API_KEY,
		location,
		handleSearchFilter,
		requiredSqaureFootageFormatted,
		setRequiredSqaureFootageFormatted,
		setRequiredSqaureFootage,
		jsonList,
		facilityList,
		handleDropdownChangeFacility,
	} = props;

	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full grid md:grid-flow-col gap-4">
				<ReactGoogleMapLoader
					params={{
						key: GOOGLE_API_KEY,
						libraries: 'places,geocode',
					}}
					render={(googleMaps) =>
						googleMaps && (
							<ReactGooglePlacesSuggest
								autocompletionRequest={{
									input: searchLocation,
								}}
								onSelectSuggest={(data) => handleAddressSelect(data)}
								googleMaps={googleMaps}
								displayPoweredByGoogle={false}
							>
								<input
									type="text"
									value={location}
									className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
									placeholder="Location Search"
									onChange={(e) => {
										setLocation(e.target.value);
										setSearchLocation(e.target.value);
									}}
									onKeyDown={(e) =>
										e.key === 'Enter' ? handleSearchFilter() : ''
									}
								/>
							</ReactGooglePlacesSuggest>
						)
					}
				/>
				<NumberFormat
					className="p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-10"
					placeholder="SqFt Required"
					value={requiredSqaureFootageFormatted}
					thousandSeparator={true}
					onValueChange={(values) => {
						const {formattedValue, value} = values;
						setRequiredSqaureFootageFormatted(formattedValue);
						setRequiredSqaureFootage(value);
					}}
				/>
				<MultiSelect
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10 max-w-sm w-full md:w-96"
					options={jsonList && jsonList.facilityCertifications}
					value={facilityList}
					onChange={handleDropdownChangeFacility}
					labelledBy={'Facility Certifications'}
					overrideStrings={{
						selectSomeItems: 'Facility Certifications',
					}}
					disableSearch={true}
					hasSelectAll={false}
				/>
				<button
					type="button"
					onClick={handleSearchFilter}
					className="flex justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-border bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					Search
				</button>
			</div>
		</div>
	);
}
