import {useState, Fragment} from 'react';
import {
	ArrowSmRightIcon,
	ArrowSmLeftIcon,
	RefreshIcon,
} from '@heroicons/react/outline';
import {useHistory} from 'react-router-dom';
import SpinnerLottie from '../../CommonModules/spinnerLottie';
import {Transition} from '@headlessui/react';
import {useUserStore} from "../../../../store/userStore";

export default function Verification({backClick, email, type, alert}) {
	const history = useHistory();
	const [code, setCode] = useState('');
	const { loading, rloading, verify, resendCode: resendCodeAPI, emptySignup } = useUserStore();

	const onChangeCode = (e) => {
		e.preventDefault();
		setCode(e.target.value);
	};
	const validateCode = (e) => {
		e.preventDefault();
		if (code && code.length > 3) {
			verify(email, code, redirectMethod, alert, history, type);
		}
	};
	const redirectMethod = () => {
		if (type.toLowerCase() === 'shipper'.toLowerCase()) {
			history.push('/explore-warehouses');
		} else {
			history.push('/explore-shippers');
		}
	};
	const resendCode = (e) => {
		e.preventDefault();
		resendCodeAPI(email, alert);
	};
	return (
		<Transition
			appear
			as={Fragment}
			show={true}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div className="relative flex-1 md:px-5 md:py-10">
				<div className="flex-1 bg-gray-900 bg-opacity-10 w-full h-full rounded-md px-8 overflow-y-auto">
					<div className="pt-8">
						<div>
							<h3 className="text-lg leading-6 font-medium text-gray-200">
								Email Verification
							</h3>
							<p className="mt-1 text-sm text-gray-400">
								We have sent a verification code to the email you have provided.
								Please check "{email}" and enter the code that was sent below to
								continue.
							</p>
						</div>
						<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
							<div className="sm:col-span-4">
								<div className="mt-1">
									<input
										id="code"
										name="code"
										type="text"
										placeholder="Verification Code"
										className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={code}
										maxLength={4}
										onChange={onChangeCode}
									/>
								</div>
							</div>
							<div className="col-span-6 sm:col-span-2">
								<div className="grid grid-cols-4">
									<button
										type="button"
										onClick={(e) => resendCode(e)}
										className="flex flex-row items-center text-gray-200 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
									>
										{rloading ? (
											<SpinnerLottie isOpen={rloading} />
										) : (
											<>
												<RefreshIcon
													className="h-6 w-6 mr-1"
													aria-hidden="true"
												/>
												Resend Code
											</>
										)}
									</button>
								</div>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<div className="grid grid-cols-4">
									<button
										type="button"
										onClick={(e) => {
											emptySignup();
											backClick(e);
										}}
										className="flex flex-row items-center text-gray-200 border-gray-200 border-2 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
									>
										<ArrowSmLeftIcon
											className="h-6 w-6 mr-1"
											aria-hidden="true"
										/>
										Previous
									</button>
								</div>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<div className="grid grid-cols-4">
									<button
										type="button"
										onClick={(e) => validateCode(e)}
										className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
									>
										{loading ? (
											<SpinnerLottie isOpen={loading} />
										) : (
											<>
												Complete
												<ArrowSmRightIcon
													className="h-6 w-6 mr-1"
													aria-hidden="true"
												/>
											</>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	);
}
