import {PencilIcon} from '@heroicons/react/outline';
export default function PencilMark({onClick}) {
	return (
		<>
			<PencilIcon
				className="hidden group-hover:flex h-4 w-4 mr-2 mx-auto self-center cursor-pointer"
				aria-hidden="true"
				onClick={onClick}
			/>
		</>
	);
}
