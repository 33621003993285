import ReactTooltip from 'react-tooltip';
import popLogo from '../../../../assets/brand/pop_white_logo.svg'
const checks = [{Icon: "", popText: "", position: 0 }, { popText: "", position: 0 }];

export default function AmenitiesIcons(props) {
  return (
    <div className="group flex flex-row pl-2">
      <ReactTooltip textColor="" backgroundColor=""/>
      <div style={{backgroundColor: "#FFD45F"}} className={`transform flex items-center justify-center mr-2 duration-500 ease-in-out h-6 w-6 rounded-full -ml-2 border-opacity-10 hover:border-2 translate-x-0 md:translate-x-0 bg-yellow-200`} data-tip={"Pop Certified"}>
        <img src={popLogo} alt="" srcset="" className='h-3 w-3'/>
      </div>
      {/* <div className={`transform duration-500 ease-in-out h-6 w-6 rounded-full -ml-2 border-opacity-10 hover:border-2 border-gray-700 translate-x-4 md:translate-x-0 ${props.responsive ? "md:group-hover:translate-x-4" : null} bg-gray-200 ${props.responsive ? "hover:bg-green-500" :null}`}  data-tip={`${props.responsive ? "Hello World" : ""}`}/>
      <div className={`transform duration-500 ease-in-out h-6 w-6 rounded-full -ml-2 border-opacity-10 hover:border-2 border-gray-700 translate-x-8 md:translate-x-0 ${props.responsive ? "md:group-hover:translate-x-8" : null} bg-gray-200 ${props.responsive ? "hover:bg-yellow-500" :null}`}  data-tip={`${props.responsive ? "Hello World" : ""}`}/>
      <div className={`transform duration-500 ease-in-out h-6 w-6 rounded-full -ml-2 border-opacity-10 hover:border-2 border-gray-700 translate-x-12 md:translate-x-0 ${props.responsive ? "md:group-hover:translate-x-12" : null} bg-gray-200 ${props.responsive ? "hover:bg-red-500" :null}`}  data-tip={`${props.responsive ? "Hello World" : ""}`}/> */}
    </div>
  );
}
