import {Fragment, useState, useMemo} from 'react';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import HeaderBox from './components/subHeader';
import {
	proposalsConstant,
	activeContracts,
	myproposalsConstant,
} from '../../../constants';

import ActiveContracts from './components/activeContracts';
import MyProposals from './components/myProposals';
import Loader from '../CommonModules/loader';
import {useWarehouseStore} from "../../../store/index";
import {useShipperStore} from "../../../store/shipperStore";

export default function Proposals() {
	const {loading} = useShipperStore();
	const {proposals, listContracts, listProposals} = useWarehouseStore();
	const [ctHeader, setCtHeader] = useState(activeContracts);
	const headerctHeader = (e, val) => {
		e.preventDefault();
		setCtHeader(val);
	};
	useMemo(() => {
		if (ctHeader.toLowerCase() === activeContracts.toLowerCase()) {
			listContracts(true);
		} else if (ctHeader.toLowerCase() === myproposalsConstant.toLowerCase()) {
			listProposals(true);
		}
	}, [ctHeader]);

	return (
		<Fragment>
			<Loader isLoading={loading} />
			<header className="shadow-sm bg-gray-200">
				<HeaderBox ctHeader={ctHeader} headerctHeader={headerctHeader} />
			</header>
			<TableWrapperHoc>
				{ctHeader.toLowerCase() === activeContracts.toLowerCase() ? (
					<ActiveContracts />
				) : ctHeader.toLowerCase().includes(proposalsConstant.toLowerCase()) ? (
					<MyProposals list={proposals} />
				) : null}
			</TableWrapperHoc>
		</Fragment>
	);
}
