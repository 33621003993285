import * as SOWEnum from './sow.enum';

export const useCasesConst = [
	{
		label: SOWEnum.UseCasesLabel.get(SOWEnum.UseCases.Overflow),
		value: SOWEnum.UseCases.Overflow,
	},
	{
		label: SOWEnum.UseCasesLabel.get(SOWEnum.UseCases.Project),
		value: SOWEnum.UseCases.Project,
	},
	{
		label: SOWEnum.UseCasesLabel.get(SOWEnum.UseCases.NewSupplier),
		value: SOWEnum.UseCases.NewSupplier,
	},
	{
		label: SOWEnum.UseCasesLabel.get(SOWEnum.UseCases.StockPositioning),
		value: SOWEnum.UseCases.StockPositioning,
	},
];
export const projectStartDateConst = [
	{
		label: SOWEnum.ProjectStartDateLabel.get(
			SOWEnum.ProjectStartDate.One_ThreeMonths
		),
		value: SOWEnum.ProjectStartDate.One_ThreeMonths,
	},
	{
		label: SOWEnum.ProjectStartDateLabel.get(
			SOWEnum.ProjectStartDate.Three_SixMonths
		),
		value: SOWEnum.ProjectStartDate.Three_SixMonths,
	},
	{
		label: SOWEnum.ProjectStartDateLabel.get(
			SOWEnum.ProjectStartDate.Six_NineMonths
		),
		value: SOWEnum.ProjectStartDate.Six_NineMonths,
	},
	{
		label: SOWEnum.ProjectStartDateLabel.get(
			SOWEnum.ProjectStartDate.Nine_TwelveMonths
		),
		value: SOWEnum.ProjectStartDate.Nine_TwelveMonths,
	},
	{
		label: SOWEnum.ProjectStartDateLabel.get(
			SOWEnum.ProjectStartDate.TwelvePlusMonths
		),
		value: SOWEnum.ProjectStartDate.TwelvePlusMonths,
	},
];
export const projectTermTypeConst = [
	{
		label: SOWEnum.ProjectTermLabel.get(SOWEnum.ProjectTerm.Recurring),
		value: SOWEnum.ProjectTerm.Recurring,
	},
	{
		label: SOWEnum.ProjectTermLabel.get(SOWEnum.ProjectTerm.Nonrecurring),
		value: SOWEnum.ProjectTerm.Nonrecurring,
	},
];
export const projectTermDurationTypeConst = [
	{
		label: SOWEnum.ProjectDurationTermLabel.get(
			SOWEnum.ProjectDurationTerm.Month
		),
		value: SOWEnum.ProjectDurationTerm.Month,
	},
	{
		label: SOWEnum.ProjectDurationTermLabel.get(
			SOWEnum.ProjectDurationTerm.Year
		),
		value: SOWEnum.ProjectDurationTerm.Year,
	},
];
export const uomConst = [
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Pallet),
		value: SOWEnum.UoM.Pallet,
	},
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Crate),
		value: SOWEnum.UoM.Crate,
	},
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Box),
		value: SOWEnum.UoM.Box,
	},
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Supersack),
		value: SOWEnum.UoM.Supersack,
	},
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Gaylord),
		value: SOWEnum.UoM.Gaylord,
	},
	{
		label: SOWEnum.UoMLabel.get(SOWEnum.UoM.Other),
		value: SOWEnum.UoM.Other,
	},
];
export const dimensionConst = [
	{
		label: SOWEnum.DimensionLabel.get(SOWEnum.Dimension.Inches),
		value: SOWEnum.Dimension.Inches,
	},
	{
		label: SOWEnum.DimensionLabel.get(SOWEnum.Dimension.Feet),
		value: SOWEnum.Dimension.Feet,
	},
];
export const weightConst = [
	{
		label: SOWEnum.WeightLabel.get(SOWEnum.Weight.Lbs),
		value: SOWEnum.Weight.Lbs,
	},
	{
		label: SOWEnum.WeightLabel.get(SOWEnum.Weight.Kg),
		value: SOWEnum.Weight.Kg,
	},
];
export const quantityConst = [
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Pallet),
		value: SOWEnum.Quantity.Pallet,
	},
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Crate),
		value: SOWEnum.Quantity.Crate,
	},
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Box),
		value: SOWEnum.Quantity.Box,
	},
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Supersack),
		value: SOWEnum.Quantity.Supersack,
	},
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Gaylord),
		value: SOWEnum.Quantity.Gaylord,
	},
	{
		label: SOWEnum.QuantityLabel.get(SOWEnum.Quantity.Other),
		value: SOWEnum.Quantity.Other,
	},
];
export const stackableConst = [
	{
		label: SOWEnum.StackableLabel.get(SOWEnum.Stackable.Double),
		value: SOWEnum.Stackable.Double,
	},
	{
		label: SOWEnum.StackableLabel.get(SOWEnum.Stackable.Triple),
		value: SOWEnum.Stackable.Triple,
	},
	{
		label: SOWEnum.StackableLabel.get(SOWEnum.Stackable.Quad),
		value: SOWEnum.Stackable.Quad,
	},
];
export const rackableConst = [
	{
		label: SOWEnum.RackableLabel.get(SOWEnum.Rackable.Single),
		value: SOWEnum.Rackable.Single,
	},
	{
		label: SOWEnum.RackableLabel.get(SOWEnum.Rackable.Double),
		value: SOWEnum.Rackable.Double,
	},
	{
		label: SOWEnum.RackableLabel.get(SOWEnum.Rackable.Triple),
		value: SOWEnum.Rackable.Triple,
	},
	{
		label: SOWEnum.RackableLabel.get(SOWEnum.Rackable.Quad),
		value: SOWEnum.Rackable.Quad,
	},
	{
		label: SOWEnum.RackableLabel.get(SOWEnum.Rackable.More),
		value: SOWEnum.Rackable.More,
	},
];
export const volumeConst = [
	{
		label: SOWEnum.InBoundVolumeLabel.get(SOWEnum.InBoundVolume.Daily),
		value: SOWEnum.InBoundVolume.Daily,
	},
	{
		label: SOWEnum.InBoundVolumeLabel.get(SOWEnum.InBoundVolume.Weekly),
		value: SOWEnum.InBoundVolume.Weekly,
	},
	{
		label: SOWEnum.InBoundVolumeLabel.get(SOWEnum.InBoundVolume.Monthly),
		value: SOWEnum.InBoundVolume.Monthly,
	},
	{
		label: SOWEnum.InBoundVolumeLabel.get(SOWEnum.InBoundVolume.Annually),
		value: SOWEnum.InBoundVolume.Annually,
	},
];

export const inboundModeConst = [
	{
		label: SOWEnum.InBoundModeLabel.get(SOWEnum.InboundModesStatus.container),
		value: SOWEnum.InboundModesStatus.container,
	},
	{
		label: SOWEnum.InBoundModeLabel.get(SOWEnum.InboundModesStatus.truckload),
		value: SOWEnum.InboundModesStatus.truckload,
	},
	{
		label: SOWEnum.InBoundModeLabel.get(
			SOWEnum.InboundModesStatus.lessthantruck
		),
		value: SOWEnum.InboundModesStatus.lessthantruck,
	},
	{
		label: SOWEnum.InBoundModeLabel.get(SOWEnum.InboundModesStatus.parcel),
		value: SOWEnum.InboundModesStatus.parcel,
	},
];
export const outboundModeConst = [
	{
		label: SOWEnum.OutBoundModeLabel.get(
			SOWEnum.OutboundModesStatus.truckload
		),
		value: SOWEnum.OutboundModesStatus.truckload,
	},
	{
		label: SOWEnum.OutBoundModeLabel.get(
			SOWEnum.OutboundModesStatus.lessthantruck
		),
		value: SOWEnum.OutboundModesStatus.lessthantruck,
	},
	{
		label: SOWEnum.OutBoundModeLabel.get(SOWEnum.OutboundModesStatus.parcel),
		value: SOWEnum.OutboundModesStatus.parcel,
	},
	{
		label: SOWEnum.OutBoundModeLabel.get(
			SOWEnum.OutboundModesStatus.localCourier
		),
		value: SOWEnum.OutboundModesStatus.localCourier,
	},
];
export const transportationAssistanceConst = [
	{
		label: SOWEnum.TransportationAssistancesLabel.get(
			SOWEnum.TransportationAssistancesStatus.yes
		),
		value: SOWEnum.TransportationAssistancesStatus.yes,
	},
	{
		label: SOWEnum.TransportationAssistancesLabel.get(
			SOWEnum.TransportationAssistancesStatus.no
		),
		value: SOWEnum.TransportationAssistancesStatus.no,
	},
	{
		label: SOWEnum.TransportationAssistancesLabel.get(
			SOWEnum.TransportationAssistancesStatus.maybe
		),
		value: SOWEnum.TransportationAssistancesStatus.maybe,
	},
];

