/* eslint-disable jsx-a11y/anchor-is-valid */
import {ChevronRightIcon, LockClosedIcon} from '@heroicons/react/solid';

const pages = [
	{name: 'Add Warehouse', current: false},
	{name: 'Finalize Payment', current: true},
];

export default function RestrictedHeader({selectedIndex = 0}) {
	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full grid-flow-row grid-row-12 grid md:grid-flow-col gap-4">
				<div className="flex flex-row justify-between w-full">
					<nav className="flex h-10" aria-label="Breadcrumb">
						<ol className="flex items-center space-x-4">
							<li>
								<div>
									<a
										className={`text-gray-400 hover:text-gray-500 ${
											selectedIndex === 0 ? 'text-gray-500' : ''
										}`}
									>
										<LockClosedIcon
											className="flex-shrink-0 h-5 w-5"
											aria-hidden="true"
										/>
										<span className="sr-only">Home</span>
									</a>
								</div>
							</li>
							{pages.map((page, i) => (
								<li key={page.name}>
									<div className="flex items-center">
										<ChevronRightIcon
											className="flex-shrink-0 h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										<a
											className={`ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 ${
												selectedIndex - 1 === i ? 'text-gray-700' : ''
											}`}
											aria-current={page.current ? 'page' : undefined}
										>
											{page.name}
										</a>
									</div>
								</li>
							))}
						</ol>
					</nav>
				</div>
			</div>
		</div>
	);
}
