import { API_BASE_URL } from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';

//GET_MY_SUBSCRIPTION
export const getMySubscriptionAction = () => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/subscription/getmysubscription`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

//HAS_PAYMENT_METHOD
export const hasPaymentMethodAction = () => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/method/haspaymentmethod`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const addUserPaymentAction = (requestObj) => {
    return new Promise((resolve, reject) => {
        RestClient
          .post(
            `${API_BASE_URL}/method/adduserpaymentmethod`,
            JSON.stringify(requestObj)
          )
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

// Action for getting subscriptions plan
export const getSubscriptionPlanAction = (history, type) => {
  return new Promise((resolve, reject) => {
    RestClient
      .get(`${API_BASE_URL}/subscription/getsubscription`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const subscribedPlanAction = (requestObj) => {
  return new Promise((resolve, reject) => {
    RestClient
      .post(
        `${API_BASE_URL}/subscription/subscribeplan`,
        JSON.stringify(requestObj)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
