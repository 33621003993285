import {ArrowSmRightIcon, ArrowSmLeftIcon} from '@heroicons/react/outline';

export default function WarehouseInfo({
	jsonList,
	handleCheckBoxChange,
	inboundServices,
	outboundServices,
	valueAddedServices,
	handleCheckBoxChangeValue,
	handleCheckBoxChangeout,
	validateInfo,
	validateBack,
	stepThirdFrom,
}) {
	return (
		<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
			<div className="sm:col-span-6">
				<label
					htmlFor="warehouseName"
					className="block text-sm font-medium text-gray-700"
				>
					Inbound Services Provided
				</label>
				<div className="mt-1">
					{jsonList &&
						jsonList.inboundServices.map((item, index) => (
							<div className="flex items-center h-5" key={index}>
								<label className="container">
									{item.label}
									<input
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										type="checkbox"
										value={item.value}
										name="inboundServices"
										checked={
											inboundServices.indexOf(item.value) >= 0 ? true : false
										}
										onChange={handleCheckBoxChange}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						))}
					{stepThirdFrom &&
						(!inboundServices || inboundServices.length === 0) && (
							<div className="block text-sm font-medium text-red-500">
								Please select at least one option
							</div>
						)}
				</div>
			</div>
			<div className="sm:col-span-6">
				<label
					htmlFor="warehouseName"
					className="block text-sm font-medium text-gray-700"
				>
					Outbound Services Provided
				</label>
				<div className="mt-1">
					{jsonList &&
						jsonList.outboundServices.map((item, index) => (
							<div className="flex items-center h-5" key={index}>
								<label className="container">
									{item.label}
									<input
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										type="checkbox"
										value={item.value}
										name="outboundServices"
										checked={
											outboundServices.indexOf(item.value) >= 0 ? 'true' : ''
										}
										onChange={handleCheckBoxChangeout}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						))}
					{stepThirdFrom &&
						(!outboundServices || outboundServices.length === 0) && (
							<div className="block text-sm font-medium text-red-500">
								Please select at least one option
							</div>
						)}
				</div>
			</div>
			<div className="sm:col-span-6">
				<label
					htmlFor="warehouseName"
					className="block text-sm font-medium text-gray-700"
				>
					Value Added Services
				</label>
				<div className="mt-1">
					{jsonList &&
						jsonList.valueAddedServices.map((item, index) => (
							<div className="flex items-center h-5" key={index}>
								<label className="container">
									{item.label}
									<input
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										type="checkbox"
										value={item.value}
										name="valueAddedServices"
										checked={
											valueAddedServices.indexOf(item.value) >= 0 ? 'true' : ''
										}
										onChange={handleCheckBoxChangeValue}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						))}
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateBack(e, 1)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						Previous
					</button>
				</div>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateInfo(e)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						Continue
						<ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
}
