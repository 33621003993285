import { Fragment } from "react";
import * as SOWEnum from "../../../../utils/sow.enum";
import { addCommas } from "../../../../utils";

export default function ScopeModal({ scopes }) {
  //
  return (
    <Fragment>
      <div className="border-b overflow-y-auto border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div class="bg-indigo-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">Overview</div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Scope Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.scopeName ? scopes.scopeName : "-"}</dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.location ? scopes.location : "-"}</dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Commodity</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.commodity || "-"}</dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Facility Certifications or Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.facilityCertificationsValues ? scopes.facilityCertificationsValues : "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Use Case</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.useCase ? SOWEnum.UseCasesLabel.get(scopes.useCase) : "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Projected Start Date </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.projectedStartDate ? SOWEnum.ProjectStartDateLabel.get(scopes.projectedStartDate) : "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Project Term</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.projectTerm ? addCommas(scopes.projectTerm) : ""}{" "}
              {scopes.projectTermDurationType ? SOWEnum.ProjectDurationTermLabel.get(scopes.projectTermDurationType) : ""} -{" "}
              {scopes.projectTermType ? SOWEnum.ProjectTermLabel.get(scopes.projectTermType) : ""}
            </dd>
          </div>
		  <div class="bg-indigo-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">Commodity Details</div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Product Unit of Measure (UoM)</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.productUoM ? SOWEnum.UoMLabel.get(scopes.productUoM) : "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Product Dimensions</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.productDimensions + " " + SOWEnum.DimensionLabel.get(scopes.productDimensionsType)}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Product Weight</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.productWeight + " " + SOWEnum.WeightLabel.get(scopes.productWeightType)}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Total SKU </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.totalSKU ? scopes.totalSKU : "-"}</dd>
          </div>
		  <div class="bg-indigo-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">Storage & Handling Requirements</div>
		  <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Quantity</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.quantity ? scopes.quantity + " " + SOWEnum.QuantityLabel.get(scopes.quantityType) : "-" }</dd>
          </div>
		  <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Stackable</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.stackable ? "Yes" : !scopes.stackable ? "No" : "-"}</dd>
          </div>
		  <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Rackable</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.rackable ? "Yes" : !scopes.rackable ? "No" : "-"}</dd>
          </div>
		  <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Approx Sq. Ft. Required</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.approxRequiredSqaureFootage ? scopes.approxRequiredSqaureFootage.toLocaleString() + " SqFt" : "-"}</dd>
          </div>
		  <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Inbound Mode</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{scopes.inboundMode ? SOWEnum.InBoundModeLabel.get(scopes.inboundMode) : "-"}</dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Average Inbound Qty </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.averageInboundQty && scopes.averageInboundQtyType && addCommas(scopes.averageInboundQty)}{" "}
              {scopes.averageInboundQtyType === SOWEnum.Quantity.Other && scopes.averageInboundQtyTypeOther
                ? scopes.averageInboundQtyTypeOther
                : SOWEnum.QuantityLabel.get(scopes.averageInboundQtyType)}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Average Inbound Volume </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.averageInboundVolume && scopes.averageInboundVolumeType && addCommas(scopes.averageInboundVolume)}{" "}
              {SOWEnum.InBoundVolumeLabel.get(scopes.averageInboundVolumeType) || "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Average Outbound Qty </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.averageOutboundQty}{" "}
              {scopes.averageOutboundQty &&
                scopes.averageOutboundQtyType &&
                scopes.averageOutboundQtyTypeOther &&
                addCommas(scopes.averageOutboundQty)}{" "}
              {scopes.averageOutboundQtyType === SOWEnum.Quantity.Other && scopes.averageOutboundQtyTypeOther
                ? scopes.averageOutboundQtyTypeOther
                : SOWEnum.QuantityLabel.get(scopes.averageOutboundQtyType)}{" "}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Average Outbound Volume </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.averageOutboundVolume && scopes.averageOutboundVolumeType && addCommas(scopes.averageOutboundVolume)}{" "}
              {SOWEnum.OutBoundVolumeLabel.get(scopes.averageOutboundVolumeType)}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Outbound Mode </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {scopes.outboundMode ? SOWEnum.OutBoundModeLabel.get(scopes.outboundMode) : "-"}
            </dd>
          </div>
          <div className="py-2 sm:py-2 hover:bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Transportation Assistance Needed</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {SOWEnum.TransportationAssistancesLabel.get(scopes.transportationAssistance)}
            </dd>
          </div>
        </dl>
      </div>
    </Fragment>
  );
}
