import axios from 'axios';
import { getAccessTokenFromLocalStorage } from './reduxMethods';

let config = {
    headers: { accept: 'application/json' }
};

class RestClient {
    /*************** POST Method ***********/
    static post(url, params = {}, contentTypeMultipart = null) {
        const token = getAccessTokenFromLocalStorage();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = contentTypeMultipart ? 'multipart/form-data' : 'application/json';
        return new Promise(function (fulfill, reject) {
            axios
                .post(url, params, config)
                .then(function (response) {
                    if (response && typeof (response.data) === 'string') {
                        fulfill({ status: response.status, data: response.data });
                    } else {
                        fulfill({ status: response.status, data: response.data.value });
                    }

                })
                .catch(function (error) {
                    if (error && error.response) {
                        fulfill({ status: error.response.status, data: error.response.data });
                    } else {
                        reject(error);
                    }
                });
        });
    }

    /*************** PUT Method ***********/
    static put(url, params = {}, contentTypeMultipart = null) {
        const token = getAccessTokenFromLocalStorage();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = contentTypeMultipart ? 'multipart/form-data' : 'application/json';
        return new Promise(function (fulfill, reject) {
            axios
                .put(url, params, config)
                .then(function (response) {
                    fulfill({ status: response.status, data: response.data });
                })
                .catch(function (error) {
                    if (error && error.response) {
                        fulfill(error.response.data);
                    } else {
                        reject(error);
                    }
                });
        });
    }
    /*************** GET Method ***********/
    static get(url, contentTypeMultipart = null) {
        const token = getAccessTokenFromLocalStorage();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = contentTypeMultipart ? 'multipart/form-data' : 'application/json';
        return new Promise(function (fulfill, reject) {
            axios
                .get(url, config)
                .then(function (response) {
                    fulfill({ status: response.status, data: response.data });
                })
                .catch(function (error) {
                    if (error && error.response) {
                        fulfill({ status: error.response.status, data: error.response.data });
                    } else {
                        reject(error);
                    }
                });
        });
    }
    /*************** DELETE Method ***********/
    static delete(url, contentTypeMultipart = null) {
        const token = getAccessTokenFromLocalStorage();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = contentTypeMultipart ? 'multipart/form-data' : 'application/json';
        return new Promise(function (fulfill, reject) {
            axios
                .delete(url, config)
                .then(function (response) {
                    fulfill({ status: response.status, data: response.data });
                })
                .catch(function (error) {
                    if (error && error.response) {
                        fulfill(error.response.data);
                    } else {
                        reject(error);
                    }
                });
        });
    }
}

export default RestClient;
