import {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {RadioGroup} from '@headlessui/react';
import WarehouseIcon from '../../../assets/svg/Warehouse.svg';
import ShipperIcon from '../../../assets/svg/Shipper.svg';
import {shipper} from '../../../constants';
const plans = [
	{
		name: 'Warehouse',
		discription:
			'Looking to fill my available warehouse space without the hidden fees and headaches.',
		price: 99,
		icon: WarehouseIcon,
	},
	{
		name: 'Shipper',
		discription:
			'Looking to find the right warehouse in the right market as fast as possible.',
		price: 99,
		icon: ShipperIcon,
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function SignUpPage() {
	const [selected, setSelected] = useState('');
	const history = useHistory();

	const selectOption = (value) => {
		if (value && value.name && value.name === shipper) {
			history.push('/shipper-signup');
		} else {
			history.push('/supplier-signup');
		}
	};
	return (
		<Fragment>
			<div className="flex items-center justify-center h-full w-full bg-indigo-900">
				<div className="flex flex-row justify-center">
					<div className="mx-auto w-full max-w-sm lg:w-96 flex">
						<div className="relative flex-1 bg-indigo-900">
							<div className="flex-1 bg-opacity-10 w-full h-full rounded-md">
								<div>
									<h3 className="text-lg leading-6 font-medium text-gray-200 mb-4">
										I am a ...
									</h3>
									``
								</div>
								<RadioGroup value={selected} onChange={selectOption}>
									<RadioGroup.Label className="sr-only">
										Server size
									</RadioGroup.Label>
									<div className="space-y-4">
										{plans.map((plan) => (
											<RadioGroup.Option
												key={plan.name}
												value={plan}
												className={({active}) =>
													classNames(
														active
															? 'ring-1 ring-offset-2 ring-indigo-500'
															: '',
														'relative block rounded-lg border border-gray-300 shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
													)
												}
											>
												{({checked}) => (
													<>
														<div className="flex items-center">
															<div className="mt-2 flex text-sm sm:mt-0 sm:block mr-4 sm:text-right">
																<div className="">
																	<img src={plan.icon} alt="" srcset="" />
																</div>
															</div>
															<div className="text-sm">
																<RadioGroup.Label
																	as="p"
																	className="font-large text-white"
																>
																	{plan.name}
																</RadioGroup.Label>
																<RadioGroup.Description
																	as="div"
																	className="text-gray-400"
																>
																	<p className="sm:inline">
																		{plan.discription}
																	</p>{' '}
																	<span
																		className="hidden sm:inline sm:mx-1"
																		aria-hidden="true"
																	>
																		&middot;
																	</span>{' '}
																	{/* <p className="sm:inline">{plan.price}</p> */}
																</RadioGroup.Description>
															</div>
														</div>
														<div
															className={classNames(
																checked
																	? 'border-indigo-500'
																	: 'border-transparent',
																'absolute -inset-px rounded-lg border-2 pointer-events-none'
															)}
															aria-hidden="true"
														/>
													</>
												)}
											</RadioGroup.Option>
										))}
									</div>
								</RadioGroup>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
