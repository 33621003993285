import create from 'zustand';
import {
  exploreShippersAction,
  getsowbyidShippersAction,
  getSupplierContracts,
  getSupplierIncomingProposals,
  getSupplierProposals, getSupplierWarehouse
} from "../actions";

export const useWarehouseStore = create((set) => ({
  loading: false,
  exploreShippers: [],
  singleShipperDetail: null,
  contracts: [],
  proposals: [],
  incomingProposals: [],
  warehouses: [],

  listExploreShippers: (signupObj) => {
    set({ loading: true, exploreShippers: [] });
    return new Promise((resolve, reject) => {
      exploreShippersAction(signupObj)
        .then((res) => {
          set({ loading: false, exploreShippers: res.data });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, exploreShippers: [] });
          reject(err);
        });
    });
  },

  getSOWbyID: (id) => {
    set({ loading: true, singleShipperDetail: null });
    return new Promise((resolve, reject) => {
      getsowbyidShippersAction(id)
        .then((res) => {
          set({ loading: false, singleShipperDetail: res.data.value });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, singleShipperDetail: null });
          reject(err);
        });
    });
  },

  listContracts: (type) => {
    set({ loading: true, contracts: [] });
    return new Promise((resolve, reject) => {
      getSupplierContracts(type)
        .then((res) => {
          set({ loading: false, contracts: res.data.value.contracts });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, contracts: [] });
          reject(err);
        });
    });
  },

  listProposals: (type) => {
    set({ loading: true, proposals: [] });
    return new Promise((resolve, reject) => {
      getSupplierProposals(type)
        .then((res) => {
          set({ loading: false, proposals: res.data.value.proposals });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, proposals: [] });
          reject(err);
        });
    });
  },

  listIncomingProposals: () => {
    set({ loading: true, incomingProposals: [] });
    return new Promise((resolve, reject) => {
      getSupplierIncomingProposals()
        .then((res) => {
          set({ loading: false, incomingProposals: res.data.value });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, incomingProposals: [] });
          reject(err);
        });
    });
  },

  listWarehouses: () => {
    set({ loading: true, warehouses: [] });
    return new Promise((resolve, reject) => {
      getSupplierWarehouse()
        .then((res) => {
          set({ loading: false, warehouses: res.data.value.warehouses });
          resolve(res);
        })
        .catch((err) => {
          set({ loading: false, warehouses: [] });
          reject(err);
        });
    });
  },
}));
