import {Fragment} from 'react';
import Lottie from 'react-lottie';
import loaderAnim from '../../../assets/anim/loader_white.json';

export default function SpinnerLottie({isOpen}) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loaderAnim,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<Fragment>
			{isOpen && (
				<Lottie
					isClickToPauseDisabled={true}
					height={26}
					width={140}
					options={defaultOptions}
				/>
			)}
		</Fragment>
	);
}
