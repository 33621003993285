import {ArrowSmLeftIcon} from '@heroicons/react/outline';
import {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';
import ConfirmationModal from '../../CommonModules/modals/confirmationModal';

export default function SubHeader() {
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const backHandler = (e) => {
		e.preventDefault();
		setIsOpen(true);
	};
	const closeModal = (val) => {
		setIsOpen(val);
	};
	const goBack = (val) => {
		history.goBack();
	};
	return (
		<Fragment>
			{isOpen && (
				<ConfirmationModal
					isOpen={isOpen}
					cancelBtn={closeModal}
					continueBtn={goBack}
				/>
			)}
			<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
				<div className="w-full md:w-1/12 grid md:grid-flow-col gap-5">
					<button
						type="button"
						onClick={backHandler}
						className="flex flex-row items-center text-gray-500 bg-gray-300 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 w-24"
					>
						<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						Back
					</button>
				</div>
			</div>
		</Fragment>
	);
}
