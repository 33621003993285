import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import TableWrapperHoc from "../CommonModules/tableWrapperHoc";
import Warehouse from "./components/wareHouse";
import SearchBox from "./components/warehouseSearch";
import { getSupplierWarehouse, getSupplierWarehouseList } from "../../../actions";
import Loader from "../CommonModules/loader";
import {useWarehouseStore} from "../../../store/index";

export default function MyWareHouse() {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { warehouses, loading, listWarehouses } = useWarehouseStore();
  // const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    listWarehouses();
  }, []);

  const [list, setList] = useState(warehouses ? warehouses : []);

  useEffect(() => {
    if (query === "") {
      setList(warehouses);
	  console.log(warehouses);
    } else {
      const timeOutId = setTimeout(() => {
        const data = findWarehouseMatch(query);
        setList(data);
      }, 500);
      return () => clearTimeout(timeOutId);
    }
    // console.log("%c" + query, "color: red; background-color: white; padding: 2px 4px; border-radius: 4px; font-size: 20px;");
  }, [query]);

  useMemo(() => {
    setList(warehouses);
  }, [warehouses]);

  useEffect(() => {
    setIsLoader(true);
    getSupplierWarehouseList()
      .then((resp) => {
        setList(resp);
        setIsLoader(false);
      })
      .catch((err) => {
        // setList([]);
        setIsLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findWarehouseMatch = (name) => {
    if (name !== "") {
      const matchRecords = warehouses ? warehouses.filter((v) => v.warehouseName.toLowerCase().includes(name.toLowerCase())) : warehouses;
      return matchRecords;
    } else {
      return warehouses;
    }
  };

  return (
    <Fragment>
      <header className="shadow-sm bg-gray-200">
        <SearchBox history={history} setQuery={setQuery} />
      </header>
      <TableWrapperHoc>
        <Warehouse list={list} loader={loading} />
      </TableWrapperHoc>
    </Fragment>
  );
}
