import { Fragment } from "react";

import Lottie from 'react-lottie';
import threeDots from '../../../../assets/anim/fluid_loader.json';

export default function Loader({ isLoading }) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: threeDots,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

  return (
    <Fragment>
      {isLoading ? (
        <div className="backdrop-filter backdrop-blur-md w-full h-full flex absolute items-center justify-center top-0 left-0 z-50">
          <span className="flex flex-col items-center select-none">
              <Lottie
                isClickToPauseDisabled={true}
                height={120}
                width={120}
                options={defaultOptions}
              />
			  <span className="text-gray-700">Loading</span>
          </span>
        </div>
      ) : null}
    </Fragment>
  );
}
