import moment from 'moment'
import { supplier, shipper } from '../constants'
export function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        // eslint-disable-next-line no-useless-concat
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export function isArrayLengthExist(array) {
    if (array && array.length > 0) {
        return true;
    } else {
        return false;
    }
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const findUserRoleRoute = (type, history) => {
    if (supplier.toLowerCase() === type.toLowerCase()) {
        history.push('/explore-shippers')
    } else if (shipper.toLowerCase() === type.toLowerCase()) {
        history.push('/explore-warehouses')
    } else {
        history.push('/login');
    }
}

export const findSignUpType = type => {
    if (type.toLowerCase() === '/shipper-signup'.toLowerCase()) {
        return 'shipper';
    } else {
        return 'supplier'
    }
}
export const dateModifier = (date) => {
    if (moment(date).isAfter(new Date())) {
        return `new ${moment(date).format('YYYY-MM-DD')}`;
    } else {
        return `${moment(date).format('YYYY-MM-DD')}`;
    }
};

export const filterByDate = (array) => {
    return array.sort(function (a, b) {
        var dateA = new Date(a.date), dateB = new Date(b.date)
        return dateA - dateB
    });
}

export const filterByDateCreate = (array) => {
    return array.sort(function (a, b) {
        var dateA = new Date(a.createdDate), dateB = new Date(b.createdDate)
        return dateA - dateB
    });
}

export const formatterSqFt = (number) => {
    number.prototype.format = function (n, x, s, c) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = this.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };
}