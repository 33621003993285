/* eslint-disable jsx-a11y/anchor-is-valid */
import {useSelector} from 'react-redux';
import {
	isArrayLengthExist,
	arrayGetValueFiltersListValues,
	filterByDateCreate,
} from '../../../../utils';
import * as SOWEnum from '../../../../utils/sow.enum';
import {getsowbyidShipper} from '../../../../actions';
import Modal from '../../CommonModules/modals';
import {Fragment, useState} from 'react';
import ScopeModal from '../../CommonModules/modals/scopeModal';
import Lottie from 'react-lottie';
import threeDots from '../../../../assets/anim/threedots_pulse.json';
import SubmitProposal from '../../Dashboard/components/submitProposal';
import {useAlert} from 'react-alert';
import XDate from '../../CommonModules/xDate';
import {useWarehouseStore} from "../../../../store/index";
import {toastAlert} from "../../CommonModules/alertTemplate";

export default function IncomingContracts() {
	const {incomingProposals, warehouses} = useWarehouseStore();
	const [isOpen, setIsOpen] = useState(false);
	const [scopes, setScopes] = useState(null);
	const [scopeId, setScopeId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [scopeLoc, setScopeLoc] = useState(null);
	const [showProposal, setShowProposal] = useState(false);
	const alert = useAlert();
	const handleScope = (id, name, loc, mainId) => {
		setScopes(null);
		setScopeId(id);
		getsowbyidShipper(id)
			.then((resp) => {
				if (resp === null) {
					toastAlert(
						{title: 'Error', discription: 'Scope has empty'},
						{type: 'error'}
					);
					setIsOpen(false);
					setScopes(null);
					setIsLoading(false);
					setScopeLoc(null);
					setScopeId(null);
				} else {
					setIsOpen(true);
					setScopes(resp);
					setIsLoading(true);
				}

				setScopeLoc(`${name}, - ${loc}`);
			})
			.catch((err) => {
				setIsOpen(false);
				setScopes(null);
				setIsLoading(false);
				setScopeLoc(null);
				setScopeId(null);
			});
	};
	const closeModal = (val) => {
		setIsOpen(val);
		setIsLoading(false);
		setScopeLoc(null);
		setScopeId(null);
		setShowProposal(false);
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: threeDots,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const changeChild = (val) => {
		if (!showProposal) {
			setShowProposal(true);
		} else {
			
//
		}
	};

	return (
		<Fragment>
			{isLoading && isOpen && (
				<Modal
					isOpen={isOpen}
					setOpen={closeModal}
					modalTitle={'Scope Information'}
					modalSubTitle={`${scopeLoc}`}
					confirm={true}
					changeChild={changeChild}
					scopeId={scopeId}
				>
					{!showProposal && scopes !== null ? (
						<ScopeModal scopes={scopes} />
					) : (
						<SubmitProposal
							list={warehouses}
							scopeId={scopeId}
							setShowProposal={setShowProposal}
							closeModal={closeModal}
						/>
					)}
				</Modal>
			)}
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							{' '}
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Shipper name
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Date
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Location
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Project Term
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Certification Required
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Use cases
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											<span className="sr-only">Details</span>
											Details
										</th>
									</tr>
								</thead>
								<tbody>
									{isArrayLengthExist(incomingProposals)
										? filterByDateCreate(incomingProposals)
												.reverse()
												.map((val, index) => (
													<tr
														key={index}
														className={
															index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
														}
													>
														<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
															{val.name || ''}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
															<XDate date={val.createdDate} />
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{val.location || ''}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{val.projectTerm
																? SOWEnum.ProjectTermLabel.get(val.projectTerm)
																: ''}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{val.certificationRequired
																? arrayGetValueFiltersListValues(
																		val.certificationRequired,
																		'facilityCertifications'
																  )
																: ''}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{val.useCase
																? SOWEnum.UseCasesLabel.get(val.useCase)
																: ''}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{!isOpen && scopeId === val.id ? (
																<Lottie
																	isClickToPauseDisabled={true}
																	height={24}
																	width={90}
																	options={defaultOptions}
																/>
															) : (
																<a
																	onClick={() => {
																		handleScope(
																			val.scopeId,
																			val.name,
																			val.location,
																			val.id
																		);
																	}}
																	className="text-white hover:text-gray-200  bg-indigo-900 cursor-pointer"
																>
																	{'View Scope'}
																</a>
															)}
														</td>
													</tr>
												))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
