import {Fragment} from 'react';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import AddScopeModule from './components/addScopeModule';
import Header from './components/subHeader';
import {useLocation} from 'react-router-dom';

export default function AddScope() {
	const location = useLocation();
	return (
		<Fragment>
			{location && location.pathname === '/add-scope' ? (
				<header className="shadow-sm bg-gray-200">
					<Header />
				</header>
			) : null}
			<TableWrapperHoc>
				<AddScopeModule />
			</TableWrapperHoc>
		</Fragment>
	);
}
