import NumberFormat from "react-number-format";
import { ArrowSmRightIcon, ArrowSmLeftIcon } from "@heroicons/react/outline";
import { MultiSelect } from "react-multi-select-component";
export default function Storage(props) {
  const { handleChange, state, validateBack, jsonList, storageInfastructure, securityComponents, handleDropdownChangeStorage, handleDropdownChangeSecurity, totalSqaureFootage, setTotalSqaureFootage, totalSqaureFootageFormattted, setTotalSqaureFootageFormattted, availableSqaureFootage, setAvailableSqaureFootage, availableSqaureFootageFormattted, setAvailableSqaureFootageFormattted, validateStorage, availableSoonSqaureFootageFormattted, setAvailableSoonSqaureFootageFormattted, setAvailableSoonSqaureFootage, stepSecondFrom } = props;
  return (
    <div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div className="col-span-3 md:col-span-3">
        <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
          Total Square Footage
        </label>
        <div className="mt-1">
          <NumberFormat
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            autoFocus
            value={totalSqaureFootageFormattted}
            thousandSeparator={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setTotalSqaureFootageFormattted(formattedValue);
              setTotalSqaureFootage(value);
            }}
          />
          {stepSecondFrom && !totalSqaureFootage && <div className="block text-sm font-medium text-red-500">Total Square Footage is required</div>}
          {stepSecondFrom && totalSqaureFootage && parseFloat(totalSqaureFootage) <= 0 && <div className="block text-sm font-medium text-red-500">Total Square Footage should be greater than 0</div>}
        </div>
      </div>

      <div className="col-span-3">
        <label htmlFor="rackable" className="block text-sm font-medium text-gray-700">
          Available Square Footage
        </label>
        <div className="mt-1">
          <NumberFormat
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Approx Sq. Ft. Required"
            value={availableSqaureFootageFormattted}
            thousandSeparator={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setAvailableSqaureFootageFormattted(formattedValue);
              setAvailableSqaureFootage(value);
            }}
          />
          {stepSecondFrom && !availableSqaureFootage && <div className="block text-sm font-medium text-red-500">Available Square Footage is required</div>}
          {stepSecondFrom && totalSqaureFootage && availableSqaureFootage && parseFloat(availableSqaureFootage) > parseFloat(totalSqaureFootage) && <div className="block text-sm font-medium text-red-500">Available Sqaure Footage should not be greater than Total Sqaure Footage</div>}
          {stepSecondFrom && availableSqaureFootage && parseFloat(availableSqaureFootage) <= 0 && <div className="block text-sm font-medium text-red-500">Available Sqaure Footage should be greater than 0</div>}
        </div>
      </div>
      <div className="col-span-3">
        <label htmlFor="rackable" className="block text-sm font-medium text-gray-700">
          Available Soon Square Footage
        </label>
        <div className="mt-1">
          <NumberFormat
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Approx Sq. Ft. Required"
            value={availableSoonSqaureFootageFormattted}
            thousandSeparator={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setAvailableSoonSqaureFootageFormattted(formattedValue);
              setAvailableSoonSqaureFootage(value);
            }}
          />
        </div>
      </div>
      <div className="sm:col-span-3">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          Storage Infrastructure
        </label>
        <div className="mt-1">
          <MultiSelect
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
            options={jsonList && jsonList.storageInfrastructures}
            value={storageInfastructure}
            onChange={handleDropdownChangeStorage}
            labelledBy={"Storage Infrastructure"}
            overrideStrings={{
              selectSomeItems: "Storage Infrastructure",
            }}
            disableSearch={true}
            hasSelectAll={false}
          />
          {/* {stepSecondFrom && storageInfastructure.length < 1 && (
						<div className="block text-sm font-medium text-red-500">
							Storage Infrastructure is required
						</div>
					)} */}
        </div>
      </div>
      <div className="sm:col-span-3">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          Security Components
        </label>
        <div className="mt-1">
          <MultiSelect
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
            options={jsonList && jsonList.securityComponents}
            value={securityComponents}
            onChange={handleDropdownChangeSecurity}
            labelledBy={"Security Components"}
            overrideStrings={{
              selectSomeItems: "Security Components",
            }}
            disableSearch={true}
            hasSelectAll={false}
          />
          {/* {stepSecondFrom && securityComponents.length < 1 && (
						<div className="block text-sm font-medium text-red-500">
							Value Added Services is required
						</div>
					)} */}
        </div>
      </div>
      <div className="sm:col-span-3">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          # of Dock Doors
        </label>
        <div className="mt-1">
          <input type="number" name="numDockDoors" id="numDockDoors" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
          {/* {stepSecondFrom && securityComponents.length < 1 && (
						<div className="block text-sm font-medium text-red-500">
							Value Added Services is required
						</div>
					)} */}
        </div>
      </div>
      <div className="sm:col-span-3">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          Ceiling Clear Height (ft)
        </label>
        <div className="mt-1">
          <input type="number" name="numDockDoors" id="numDockDoors" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>
      <div className="sm:col-span-6">
        <label htmlFor="industriesServed" className="block text-sm font-medium text-gray-700">
          Value Added Services
        </label>
        <div className="mt-1">
          <input type="text" name="industriesServed" id="industriesServed" autoComplete="given-name" value={state.industriesServed} onChange={handleChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
          {/* {stepSecondFrom && !state.industriesServed && (
						<div className="block text-sm font-medium text-red-500">
							Value Added Services is required
						</div>
					)} */}
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3">
        <div className="grid grid-cols-4">
          <button type="button" onClick={(e) => validateBack(e, 0)} className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4">
            <ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
            Previous
          </button>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="grid grid-cols-4">
          <button type="button" onClick={(e) => validateStorage(e)} className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4">
            Continue
            <ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
