import { Fragment, useState, useMemo, useEffect } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import NumberFormat from "react-number-format";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import ReactGoogleMapLoader from "react-google-maps-loader";
import { scopeBy, typeBy, startDateConstant } from "../../../../constants";
import { filterWarehouseByScopeId, filterWarehouseByType, deleteScopeActionMethod, getAllSowsShipper } from "../../../../actions";
import { addCommas, arrayGetValueFilters, GOOGLE_API_KEY } from "../../../../utils";
import Modal from "./wrapperModal";
import DeleteScopes from "./deleteScopes";
import {useShipperStore} from "../../../../store/shipperStore";

export default function FilterHeader(props) {
  let { warehouseId } = useParams();
  let urlLocation = useLocation();
  const searchParams = new URLSearchParams(urlLocation.search);
  //Search by scope
  useEffect(() => {
    if (warehouseId) {
		setSelectedScope(warehouseId);
    }
  }, [warehouseId, urlLocation]);

  const { manageScope, closeScope, setManageScope } = props;
  const history = useHistory();
  const { allSows, listAllSowsShipper, filterWareHouseByScopeID, filterWareHouseByType } = useShipperStore();
  const [searchByType, setSearchByType] = useState(searchParams.get('searchBy') ?? scopeBy);
  const [selectedScope, setSelectedScope] = useState("");
  const [requiredSqaureFootageFormatted, setRequiredSqaureFootageFormatted] = useState(() => addCommas(searchParams.get('requiredSqaureFootage') ?? ""));
  const [requiredSqaureFootage, setRequiredSqaureFootage] = useState(searchParams.get('requiredSqaureFootage') ?? "");
  const valueFromLocalStorage = localStorage.getItem("filters");
  const [facilityList, setFacilityList] = useState(arrayGetValueFilters(searchParams.get('facilityCertifications') ?? '', "facilityCertifications"));
  // eslint-disable-next-line no-unused-vars
  const [jsonList, setJsonList] = useState(JSON.parse(valueFromLocalStorage));
  const [searchLocation, setSearchLocation] = useState("");
  const [location, setLocation] = useState(searchParams.get('location') ?? "");
  const [lat, setLat] = useState(searchParams.get('latitude') ? +searchParams.get('latitude') : 0);
  const [long, setLong] = useState(searchParams.get('longitude') ? +searchParams.get('longitude') : 0);
  const [isDeleting, setIsDeleting] = useState("");
  const hanldeChnageScope = (e) => {
    e.preventDefault();
    setSearchByType(e.target.value);
    setLat(0);
    setLong(0);
    setLocation("");
    setSearchLocation("");
    setFacilityList([]);
    setRequiredSqaureFootage("");
    setRequiredSqaureFootageFormatted("");
    history.push('/explore-warehouses');
    props.setCurrent(true);
  };

  const handleChangeScopeName = (e) => {
    e.preventDefault();
    setSelectedScope(e.target.value);
	  history.push(`/explore-warehouses/${e.target.value}`);
  };

  const handleDropdownChangeFacility = (e) => {
    setFacilityList(e);
  };
  const handleAddressSelect = (place) => {
    if (place && place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      setLocation(address);
      setSearchLocation("");
      setLat(latValue);
      setLong(lngValue);
    } else {
      setLat(0);
      setLong(0);
      setLocation(null);
      setSearchLocation("");
    }
  };
  const handleSearchFilter = (updateHistory) => {
    let facilityCertifications = [];
    if (facilityList && facilityList.length > 0) {
      facilityCertifications = facilityList.map((ele) => {
        return ele.value;
      });
    }
    const obj = {
      FacilityCertifications: facilityCertifications && facilityCertifications.length > 0 ? facilityCertifications.join(",") : null,
      Latitude: lat,
      Location: location === "" ? null : location,
      Longitude: long,
      RequiredSqaureFootage: requiredSqaureFootage === "" ? 0 : Number(requiredSqaureFootage),
      StartDate: startDateConstant,
    };

    if (updateHistory) {
      searchParams.set('searchBy', searchByType);
      if (location) {
        searchParams.set('location', location);
      } else {
        searchParams.delete('location');
      }

      if (facilityCertifications.length) {
        searchParams.set('facilityCertifications', facilityCertifications.join(','));
      } else {
        searchParams.delete('facilityCertifications');
      }

      if (requiredSqaureFootage) {
        searchParams.set('requiredSqaureFootage', requiredSqaureFootage);
      } else {
        searchParams.delete('requiredSqaureFootage');
      }

      if (lat) {
        searchParams.set('latitude', `${lat}`);
      } else {
        searchParams.delete('latitude');
      }

      if (long) {
        searchParams.set('longitude', `${long}`);
      } else {
        searchParams.delete('longitude');
      }

      history.replace(`${window.location.pathname}?${searchParams.toString()}`);
    }

    filterWareHouseByType(obj,history);
  };
  const clearFilterCall = () => {
    setLat(0);
    setLong(0);
    setLocation("");
    setSearchLocation("");
    setFacilityList([]);
    setRequiredSqaureFootage("");
    setRequiredSqaureFootageFormatted("");
    const obj = {
      FacilityCertifications: null,
      Latitude: 0,
      Location: null,
      Longitude: 0,
      RequiredSqaureFootage: 0,
      StartDate: startDateConstant,
    };
    filterWareHouseByType(obj, history);
  };
  useMemo(() => {
    if (searchByType.toLowerCase() === scopeBy.toLowerCase()) {
      filterWareHouseByScopeID(selectedScope, history);
    } else if (searchByType.toLowerCase() === typeBy.toLowerCase()) {
      handleSearchFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByType, selectedScope]);
  const clearFilter = (e) => {
    e.preventDefault();
    clearFilterCall();
  };
  const deleteScopeMethod = (e, id) => {
    e.preventDefault();
    if (id) {
      setIsDeleting(id);
      deleteScopeActionMethod(id)
        .then((resp) => {
          listAllSowsShipper(history);
          setIsDeleting("");
        })
        .catch((err) => {
          //
          setIsDeleting("");
        });
    }
  };

  return (
    <Fragment>
      {manageScope && (
        <Modal isOpen={manageScope} setOpen={closeScope} modalTitle={"Manage Scopes"}>
          <DeleteScopes scopes={allSows} deleteScopeMethod={deleteScopeMethod} isDeleting={isDeleting} />
        </Modal>
      )}
      <div className="col-span-1">
        <select className="p-2 border bg-gray-300 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10" name="projectedStartDate" value={searchByType} onChange={hanldeChnageScope}>
          {[
            { label: "Search By Scope", value: "scope" },
            { label: "Search By Type", value: "type" },
          ].map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}{" "}
            </option>
          ))}
        </select>
      </div>
      {searchByType.toLowerCase() === scopeBy.toLowerCase() ? (
        <Fragment>
          <div className="col-span-9">
            <select className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10" name="scopeName" value={selectedScope} onChange={handleChangeScopeName}>
              <option value={""}>{"Select Scope"}</option>
              {allSows.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.scopeName}{" "}
                </option>
              ))}
            </select>
          </div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/add-scope`);
            }}
            className="col-span-1  flex justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-border bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusCircleIcon className="h-6 w-6 mr-1" aria-hidden="true" />
            Add Scope
          </button>
          <button type="button" onClick={() => setManageScope(true)} className="col-span-1 flex justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-border bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <MinusCircleIcon className="h-6 w-6 mr-1" aria-hidden="true" />
            Manage Scope
          </button>
        </Fragment>
      ) : (
        <Fragment>
          <ReactGoogleMapLoader
            params={{
              key: GOOGLE_API_KEY,
              libraries: "places,geocode",
            }}
            render={(googleMaps) =>
              googleMaps && (
                <div className="col-span-5">
                  <ReactGooglePlacesSuggest
                    autocompletionRequest={{
                      input: searchLocation,
                    }}
                    onSelectSuggest={(data) => handleAddressSelect(data)}
                    googleMaps={googleMaps}
                    displayPoweredByGoogle={false}
                    className="hey"
                  >
                    <input
                      type="text"
                      value={location}
                      className="col-span-3 p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
                      placeholder="Location Search"
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setSearchLocation(e.target.value);
                      }}
                      onKeyDown={(e) => (e.key === "Enter" ? handleSearchFilter(true) : "")}
                    />
                  </ReactGooglePlacesSuggest>
                </div>
              )
            }
          />
          <NumberFormat
            className="col-span-2 p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-10"
            placeholder="SqFt Required"
            value={requiredSqaureFootageFormatted}
            thousandSeparator={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setRequiredSqaureFootageFormatted(formattedValue);
              setRequiredSqaureFootage(value);
            }}
          />
          <MultiSelect
            className="col-span-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10 w-full"
            options={jsonList && jsonList.facilityCertifications}
            value={facilityList}
            onChange={handleDropdownChangeFacility}
            labelledBy={"Facility Certifications"}
            overrideStrings={{
              selectSomeItems: "Facility Certifications",
            }}
            disableSearch={true}
            hasSelectAll={false}
          />
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleSearchFilter(true);
            }}
            className="col-span-1 flex justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-border bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Search
          </button>
          <button type="button" onClick={clearFilter} className="col-span-1 flex justify-center items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-border bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Clear
          </button>
        </Fragment>
      )}
    </Fragment>
  );
}
