import {useState} from 'react';
import {
	MailIcon,
	PhoneIcon,
	UserCircleIcon,
	CreditCardIcon,
	CalendarIcon,
	LockClosedIcon,
	LocationMarkerIcon,
} from '@heroicons/react/solid';
import CardInput from 'react-credit-card-input-simple';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import {
	GOOGLE_API_KEY,
	getZipcode,
	getCity,
	getCountry,
	getState,
	GetTokenDetails,
	getAccessTokenFromLocalStorage,
	getStreetAddress,
} from '../../../utils';
import Geocode from 'react-geocode';
import {useHistory} from 'react-router-dom';
import {addUserPaymentAction} from '../../../actions';
import {useAlert} from 'react-alert';
import SpinnerLottie from '../CommonModules/spinnerLottie';
import {usePaymentStore} from "../../../store/paymentStore";
Geocode.setApiKey(GOOGLE_API_KEY);

export default function AddCardMethod(props) {
	const history = useHistory();
	const alert = useAlert();
	const { addUserPayment } = usePaymentStore();
	const userDetails = GetTokenDetails(getAccessTokenFromLocalStorage());
	const [cardNumber, setCardNumber] = useState('');
	const [searchLocation, setSearchLocation] = useState('');
	const [billingAddress, setBillingAddress] = useState(null);
	const [zip, setZip] = useState('');
	const [country, setCountry] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [nameonCard, setNameonCard] = useState('');
	const [cvc, setCvc] = useState('');
	const [exdate, setExdate] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const handleAddressSelect = (place) => {
		if (place && place.formatted_address) {
			const address = place.formatted_address,
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			Geocode.fromLatLng(latValue, lngValue).then(
				(response) => {
					const addressArray = response.results[0].address_components;
					setCity(getCity(addressArray));
					setState(getState(addressArray));
					setCountry(getCountry(addressArray));
					// let streetAddress = getStreetAddress(addressArray);
					// 
//
					setZip(getZipcode(addressArray));
					setBillingAddress(address);
					setSearchLocation('');
				},
				(error) => {
					setCity('');
					setState('');
					setCountry('');
					setZip('');
					setBillingAddress(null);
					setSearchLocation('');
				}
			);
		} else {
			setCity('');
			setState('');
			setCountry('');
			setZip('');
			setBillingAddress(null);
			setSearchLocation('');
		}
	};
	const onChangeHandler = (e) => {
		setCardNumber(e.target.value);
	};
	const handleCvv = (e) => {
		let valu = e.target.value;

		if (!Number(valu)) {
			return false;
		} else {
			setCvc(valu);
		}
	};
	const handleExDate = (e) => {
		e.preventDefault();
		let value = e.target.value;
		if (value.length === 2 && e.which !== 8) {
			value += '/';
		}
		setExdate(value);
	};
	const keyPressEx = (e) => {
		const value = e.which || e.charcode || e.keycode || e.key;
		if (value === 8 && exdate.length === 3) {
			setExdate(exdate.replaceAll('/', ''));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (nameonCard === '') {
			setSubmitted(true);
		} else if (billingAddress === '') {
			setSubmitted(true);
		} else if (cardNumber === '') {
			setSubmitted(true);
		} else if (cvc === '') {
			setSubmitted(true);
		} else if (exdate === '') {
			setSubmitted(true);
		} else {
			setSubmitted(false);
			setIsOpen(true);
			const obj = {
				CardOwnerFirstName: nameonCard.split(' ')[0]
					? nameonCard.split(' ')[0]
					: '',
				CardOwnerLastName: nameonCard.split(' ')[1]
					? nameonCard.split(' ')[1]
					: '',
				EmailAddress: userDetails.sub,
				City: city,
				State: state,
				Country: country,
				Zipcode: zip,
				Address: billingAddress,
				CardNumber: cardNumber.replaceAll(' ', ''),
				CVV: Number(cvc),
				Month: Number(exdate.split('/')[0]),
				Year: Number(exdate.split('/')[1]),
			};
			addUserPayment(obj, history, userDetails.actort, alert);
			setTimeout(() => {
				setIsOpen(false);
			}, 5000);
		}
	};

	return (
		<div className="relative bg-white">
			<div className="absolute inset-0">
				<div className="absolute inset-y-0 left-0 w-1/2" />
			</div>
			<div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
				<div
					style={{background: 'rgb(255, 240, 219)', borderRadius: '1em'}}
					className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
				>
					<div className="max-w-lg mx-auto">
						<h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
							One Step Closer. 🚀
						</h2>
						<p className="mt-3 text-lg leading-6 text-gray-500">
							{`You have everything setup! Now there is one last step before you
							can instantly access our evergrowing network of ${
								userDetails.actort === 'Supplier' ? 'Shippers' : 'Warehouses'
							}`}
						</p>
						<p className="mt-3 text-lg leading-6 text-gray-500">
							You only pay <strong>$99</strong> to access our network.
						</p>
						<dl className="mt-8 text-base text-gray-500">
							<div>
								<dt className="sr-only">Address</dt>
								<dd>
									<p>2400 Old Milton Pkwy #66 Alpharetta, GA 30004</p>
								</dd>
							</div>
							<div className="mt-6">
								<dt className="sr-only">Phone number</dt>
								<dd className="flex">
									<PhoneIcon
										className="flex-shrink-0 h-6 w-6 text-gray-400"
										aria-hidden="true"
									/>
									<span className="ml-3">+1 (877) 885-2019</span>
								</dd>
							</div>
							<div className="mt-3">
								<dt className="sr-only">Email</dt>
								<dd className="flex">
									<MailIcon
										className="flex-shrink-0 h-6 w-6 text-gray-400"
										aria-hidden="true"
									/>
									<span className="ml-3">hello@popcapacity.com</span>
								</dd>
							</div>
						</dl>
						{/* <p className="mt-6 text-base text-gray-500">
							Looking for careers?{' '}
							<a href="#" className="font-medium text-gray-700 underline">
								View all job openings
							</a>
							.
						</p> */}
					</div>
				</div>
				<div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-7 lg:px-8 xl:pl-12">
					<div className="max-w-lg mx-auto lg:max-w-none">
						<form className="grid grid-cols-4 gap-y-6 gap-x-4">
							<div className="col-span-4 mt-1 relative rounded-md shadow-sm">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<CreditCardIcon
										className="h-5 w-5 text-indigo-700"
										aria-hidden="true"
									/>
								</div>
								<CardInput
									className="cinp flex items-center py-3 w-full pl-10 sm:text-sm border-gray-300 rounded-md"
									style={{
										height: '46px',
										paddingLeft: '2.7rem',
										paddingRight: '.7rem',
									}}
									value={cardNumber}
									onChange={onChangeHandler}
								/>
								{submitted && !cardNumber && (
									<div className="block text-sm font-medium text-red-200">
										card is required
									</div>
								)}
							</div>
							<div className="col-span-4 mt-1 relative rounded-md shadow-sm">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<UserCircleIcon
										className="h-5 w-5 text-indigo-700"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="nameonCard"
									id="email"
									className="py-3  focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
									placeholder="Name on card"
									value={nameonCard}
									onChange={(e) => setNameonCard(e.target.value)}
								/>
								{submitted && !nameonCard && (
									<div className="block text-sm font-medium text-red-200">
										Name on card is required
									</div>
								)}
							</div>
							<div className="col-span-2 mt-1 relative rounded-md shadow-sm">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<CalendarIcon
										className="h-5 w-5 text-indigo-700"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="exdate"
									maxLength={5}
									value={exdate}
									className="py-3  focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
									placeholder="Expiration date"
									onChange={handleExDate}
									onKeyDown={keyPressEx}
								/>
								{submitted && !exdate && (
									<div className="block text-sm font-medium text-red-200">
										Expiration date is required
									</div>
								)}
							</div>
							<div className="col-span-2 mt-1 relative rounded-md shadow-sm">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<LockClosedIcon
										className="h-5 w-5 text-indigo-700"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									pattern="^-?[0-9]\d*\.?\d*$"
									name="email"
									maxLength={4}
									className="py-3  focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
									placeholder="Security code"
									onChange={handleCvv}
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
										}
									}}
								/>
								{submitted && !cvc && (
									<div className="block text-sm font-medium text-red-200">
										Security code is required
									</div>
								)}
							</div>
							<div className="col-span-4 mt-1 relative rounded-md shadow-sm">
								<ReactGoogleMapLoader
									params={{
										key: GOOGLE_API_KEY,
										libraries: 'places,geocode',
									}}
									render={(googleMaps) =>
										googleMaps && (
											<ReactGooglePlacesSuggest
												autocompletionRequest={{input: searchLocation}}
												onSelectSuggest={handleAddressSelect}
												googleMaps={googleMaps}
												displayPoweredByGoogle={false}
											>
												{' '}
												<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
													<LocationMarkerIcon
														className="h-5 w-5 text-indigo-700"
														aria-hidden="true"
													/>
												</div>
												<input
													type="text"
													value={billingAddress}
													className="py-3  focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
													style={{
														height: '46px',
													}}
													placeholder="Billing Address"
													onChange={(e) => {
														setBillingAddress(e.target.value);
														setSearchLocation(e.target.value);
														// handleAddressSelect(null);
													}}
												/>
											</ReactGooglePlacesSuggest>
										)
									}
								/>
								{submitted && !billingAddress && (
									<div className="block text-sm font-medium text-red-200">
										Billing Address is required
									</div>
								)}
							</div>
							<button
								type="button"
								onClick={isOpen ? null : handleSubmit}
								className="flex items-center col-span-2 justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
							>
								{isOpen ? (
									<SpinnerLottie isOpen={isOpen} />
								) : (
									<>
										<LockClosedIcon className="w-4 mr-2" />
										Secure Pay $99
									</>
								)}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
