import { exploreWarehouseConstant, explorehipperConstant, myWarehouseConstant, proposalsConstant, contractsProposal } from '../constants';

export const routePath = (pathname) => {
    if (pathname === '/explore-shippers') {
        return explorehipperConstant;
    } else if (pathname === '/my-warehouses') {
        return myWarehouseConstant
    } else if (pathname === '/explore-warehouses' || pathname === 'warehouse') {
        return exploreWarehouseConstant
    } else if (pathname === '/contracts-and-proposals') {
        return contractsProposal
    } else if (pathname === '/proposals') {
        return proposalsConstant
    } else if (pathname.includes('warehouse')) {
        return exploreWarehouseConstant
    } else if (pathname.includes('settings')) {
        return pathname
    } else {
        return ''
    }
}