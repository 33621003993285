import create from 'zustand';
import { persist } from "zustand/middleware";
import {
  getDropdownDataAction,
  getDropdownDataAPI,
  loginAction,
  logoutAction,
  resenCodeAction,
  saveOneSignalToken,
  shipperRegisteration,
  supplierRegisteration,
  verifyAction
} from "../actions";
import { findUserRoleRoute, GetTokenDetails } from "../utils";
import {usePaymentStore} from "./paymentStore";
import {toastAlert} from "../components/Modules/CommonModules/alertTemplate";
import {useMixpanel} from "./useMixpanel";


export const useUserStore = create(
  persist(
    (set) => ({
      loading: false,
      signupData: null,
      dropdownData: {},
      rloading: false,

      verify: (email, code, redirectMethod, alert, history, type) => {
        set({ loading: true });
        return new Promise((resolve, reject) => {
          verifyAction(email, code)
            .then((resp) => {
              if (resp.status === 200) {
                localStorage.setItem('token', resp.data.value.access_token)
                getDropdownDataAPI(resp.data.value.access_token);
                usePaymentStore.getState().listSubscriptions(history);
                usePaymentStore.getState().hasPaymentMethod(history, type === 'shipper' ? 'Shipper' : 'Supplier');
                setTimeout(() => {
                  set({ loading: false });
                  redirectMethod();
                }, 2600);
                useMixpanel.getState().track("`User Verified: " + type === 'shipper' ? 'Shipper 📦 -> ` ' : 'Supplier 🏢 -> ` ' + email);
                useMixpanel.getState().identify(email)
              } else {
                set({ loading: false });
                if (resp.data && resp.data.value) {
                  useMixpanel.getState().track('Error Tracking:: Verify Code ' + resp.data.value);
                  toastAlert(
                    { title: 'Verification Error', discription: resp.data.value + " Please check your code and try again" },
                    { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                  toastAlert(
                    { title: 'Error', discription: resp.data.errors.Password[0] },
                    { type: 'error' })
                }
              }
              resolve(resp);
            })
            .catch((err) => {
              set({ loading: false });
              toastAlert(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
              reject(err);
            });
        });
      },

      getDropdownData: () => {
        set({ loading: true});
        return new Promise((resolve, reject) => {
          getDropdownDataAction()
            .then((resp) => {
              set({ loading: false });
              resolve(resp.data);
            })
            .catch((err) => {
              set({ loading: false });
              reject(err);
            });
        });
      },

      resendCode: (email, alert) => {
        set({ rloading: true});
        return new Promise((resolve, reject) => {
          resenCodeAction(email)
            .then((resp) => {
              if (resp.status === 200) {
                set({ rloading: false });
                toastAlert(
                  { title: 'Success', discription: resp.data.value },
                  { type: 'success' })
              } else {
                set({ rloading: false });
                toastAlert(
                  { title: 'Error', discription: resp.data.value },
                  { type: 'error' })
              }
              resolve(resp.data);
            })
            .catch((err) => {
              set({ rloading: false });
              toastAlert(
                { title: 'Error', discription: 'Something went wrong, please try again.' },
                { type: 'error' });
              reject(err);
            });
        });
      },

      login: (obj, history, alert, signalObj) => {
        set({ loading: true });
        return new Promise((resolve, reject) => {
          loginAction(obj)
            .then((resp) => {
              if (resp.status === 200) {
                const isFilterExists = localStorage.getItem('filters');
                if (resp.data?.access_token) {
                  localStorage.setItem('token', resp.data?.access_token)
                  const userDetail = GetTokenDetails(resp.data.access_token);
                  usePaymentStore.getState().listSubscriptions(history);
                  saveOneSignalToken(signalObj)
                  usePaymentStore.getState().hasPaymentMethod(history, userDetail.actort);
                  if (!isFilterExists) {
                    getDropdownDataAPI(resp.data.access_token);
                    setTimeout(() => {
                      set({ loading: false });
                      findUserRoleRoute(userDetail.actort, history);
                    }, 2500);
                  } else {
                    set({ loading: false });
                    findUserRoleRoute(userDetail.actort, history);
                  }
                } else {
                  console.log('1 Error Tracking:: Login ' + resp.data + resp.data.Active === false ? 'User did not activate email and tried to login' : 'Something went wrong')
                  toastAlert(
                    { title: 'Error', discription: resp.data.Active === false ? 'Please activate your email' : 'Something went wrong' },
                    { type: 'error' })
                }



              } else {
                set({ loading: false });
                useMixpanel.getState().track("Error Tracking:: Login", { "user": JSON.stringify(obj.username), "Error Description": resp.data.value })
                //mixpanel error tracking
                if (resp.data && resp.data.value) {
                  toastAlert(
                    { title: 'Error', discription: resp.data.value },
                    { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                  toastAlert(
                    { title: 'Error', discription: resp.data.errors.Password[0] },
                    { type: 'error' })
                }

              }
              resolve(resp.data);
            })
            .catch((err) => {
              set({ loading: false });
              toastAlert(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' })
              reject(err);
            });
        });
      },

      logout: (history) => logoutAction(history),

      emptySignup: () => {
        set({
          loading: false,
          signupData: null,
        });
      },

      shipperRegistration: (requestObj, alert) => {
        set({ loading: true, signupData: null });
        return new Promise((resolve, reject) => {
          shipperRegisteration(requestObj)
            .then((resp) => {
              if (resp.status === 200) {
                set({ loading: false, signupData: resp.data });
              } else {
                set({ loading: false, signupData: null });
                if (resp.data && resp.data.value) {
                  toastAlert(
                    { title: 'Error', discription: resp.data.value },
                    { type: 'error' })
                } else if (resp.data && resp.data.errors) {
                  toastAlert(
                    { title: 'Error', discription: 'Something went wrong,Please try again later' },
                    { type: 'error' })
                }
              }
              resolve(resp.data);
            })
            .catch((err) => {
              set({ loading: false, signupData: null });
              toastAlert(
                { title: 'Error', discription: 'Something went wrong,Please try again.' },
                { type: 'error' });
              reject(err);
            });
        });
      },

      supplierRegistration: (requestObj, alert) => {
        set({ loading: true, signupData: null });
        return new Promise((resolve, reject) => {
          supplierRegisteration(requestObj)
            .then((resp) => {
              if (resp.status === 200) {
                set({ loading: false, signupData: resp.data });
              } else {
                set({ loading: false, signupData: null });
                if (resp.data && resp.data.value) {
                  toastAlert({ title: "Error", discription: resp.data.value }, { type: "error" });
                } else if (resp.data && resp.data.errors) {
                  toastAlert({ title: "Error", discription: "Something went wrong,Please try again later" }, { type: "error" });
                }
              }
              resolve(resp.data);
            })
            .catch((err) => {
              set({ loading: false, signupData: null });
              toastAlert({ title: "Error", discription: "Something went wrong,Please try again." }, { type: "error" });
              reject(err);
            });
        });
      },
    }),
    {
      name: 'userStore',
      getStorage: () => localStorage,
    }
  )
);
