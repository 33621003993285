import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import { MultiSelect } from 'react-multi-select-component';
import {ArrowSmRightIcon} from '@heroicons/react/outline';

export default function OverView(props) {
	const {
		GOOGLE_API_KEY,
		searchLocation,
		handleAddressSelect,
		location,
		setLocation,
		setSearchLocation,
		jsonList,
		facilityList,
		handleDropdownChangeFacility,
		handleChange,
		useCasesConst,
		projectStartDateConst,
		projectTermDurationTypeConst,
		projectTermTypeConst,
		state,
		validateOverView,
		stepFirstFrom,
	} = props;

	return (
		<div className="mx-6 md:mx-0 md:ml-6 grid grid-cols-1 gap-y-6 gap-x-4 md:grid-cols-6">
			<div className="col-span-4">
				<label
					htmlFor="scopeName"
					className="block text-sm font-medium text-gray-700"
				>
					Scope Name
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="scopeName"
						id="scopeName"
						autoComplete="given-name"
						value={state.scopeName}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
					{stepFirstFrom && !state.scopeName && (
						<div className="block text-sm font-medium text-red-500">
							Scope name is required
						</div>
					)}
				</div>
			</div>

			<div className="col-span-6 sm:col-span-2">
				<label
					htmlFor="country"
					className="block text-sm font-medium text-gray-700"
				>
					Select Use Case
				</label>
				<select
					className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="useCase"
					value={state.useCase}
					onChange={handleChange}
				>
					<option value="">Select Use Case</option>
					{useCasesConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="col-span-6 sm:col-span-6">
				<label
					htmlFor="scopeName"
					className="block text-sm font-medium text-gray-700"
				>
					Required Facility Certifications
				</label>
				<div className="mt-1">
					<MultiSelect
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
						options={jsonList && jsonList.facilityCertifications}
						value={facilityList}
						onChange={handleDropdownChangeFacility}
						labelledBy={'Facility Certifications'}
						overrideStrings={{
							selectSomeItems: 'Facility Certifications',
						}}
						disableSearch={true}
						hasSelectAll={false}
					/>
					{stepFirstFrom && facilityList && facilityList.length === 0 && (
						<div className="block text-sm font-medium text-red-500">
							Facility Certifications is required
						</div>
					)}
				</div>
			</div>
			<div className="col-span-6 sm:col-span-6">
				<label
					htmlFor="last_name"
					className="block text-sm font-medium text-gray-700"
				>
					Preferred Storage Location
				</label>
				<div className="mt-1">
					<ReactGoogleMapLoader
						params={{
							key: GOOGLE_API_KEY,
							libraries: 'places,geocode',
						}}
						render={(googleMaps) =>
							googleMaps && (
								<ReactGooglePlacesSuggest
									autocompletionRequest={{
										input: searchLocation,
									}}
									onSelectSuggest={(data) => handleAddressSelect(data)}
									googleMaps={googleMaps}
									displayPoweredByGoogle={false}
								>
									<input
										type="text"
										value={location}
										className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
										placeholder="Location Search"
										onChange={(e) => {
											setLocation(e.target.value);
											setSearchLocation(e.target.value);
										}}
										onKeyDown={(e) => (e.key === 'Enter' ? null : '')}
									/>
								</ReactGooglePlacesSuggest>
							)
						}
					/>
					{stepFirstFrom && !location && (
						<div className="block text-sm font-medium text-red-500">
							Location is required
						</div>
					)}
				</div>
			</div>
			<div className="sm:col-span-4 col-span-6">
				<label
					htmlFor="scopeName"
					className="block text-sm font-medium text-gray-700"
				>
					Projected Start Date
				</label>
				<select
					className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="projectedStartDate"
					value={state.projectedStartDate}
					onChange={handleChange}
				>
					<option value="">Select Projected Start Date</option>
					{projectStartDateConst.map((item, i) => (
						<option key={i} value={item.value}>
							{item.label}{' '}
						</option>
					))}
				</select>
			</div>
			<div className="col-span-6">
				<div className="text-sm font-medium text-gray-700">Project Term</div>
				<div className="grid grid-cols-3 col-span-6 gap-6">
					<div className="grid-cols-3 col-span-1">
						<select
							className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							name="projectTerm"
							value={state.projectTerm}
							onChange={handleChange}
						>
							<option value="">Select</option>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => (
								<option key={i} value={item}>
									{item}{' '}
								</option>
							))}
						</select>
					</div>

					<div className="col-span-1">
						<select
							className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							name="projectTermDurationType"
							value={state.projectTermDurationType}
							onChange={handleChange}
						>
							<option value="">Select</option>
							{projectTermDurationTypeConst.map((item, i) => (
								<option key={i} value={item.value}>
									{item.label}{' '}
								</option>
							))}
						</select>
					</div>

					<div className="col-span-1">
						<select
							className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							name="projectTermType"
							value={state.projectTermType}
							onChange={handleChange}
						>
							<option value="">Select</option>
							{projectTermTypeConst.map((item, i) => (
								<option key={i} value={item.value}>
									{item.label}{' '}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="col-span-6">
				<div className="grid grid-cols-4 mb-4">
					<button
						type="button"
						onClick={(e) => validateOverView(e)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						Continue
						<ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
}
