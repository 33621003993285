import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import { MultiSelect } from 'react-multi-select-component';
import {ArrowSmRightIcon} from '@heroicons/react/outline';

export default function WarehouseDetailsForm({
	searchLocation,
	handleAddressSelect,
	setLocation,
	setSearchLocation,
	GOOGLE_API_KEY,
	location,
	jsonList,
	facilityList,
	handleDropdownChangeFacility,
	handleChange,
	state,
	city,
	zip,
	country,
	states,
	validateOverView,
	stepFirstFrom,
	setCity,
	setZip,
	setStates,
	setCountry,
	longitude,
}) {
	return (
		<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
			<div className="sm:col-span-3">
				<label
					htmlFor="warehouseName"
					className="block text-sm font-medium text-gray-700"
				>
					Warehouse Name
				</label>
				<div className="mt-1">
					<input
						type="text"
						name="warehouseName"
						id="warehouseName"
						autoComplete="given-name"
						value={state.warehouseName}
						onChange={handleChange}
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					/>
					{stepFirstFrom && !state.warehouseName && (
						<div className="block text-sm font-medium text-red-500">
							Warehouse name is required
						</div>
					)}
				</div>
			</div>
			<div className="sm:col-span-3">
				<label
					htmlFor="last_name"
					className="block text-sm font-medium text-gray-700"
				>
					Facility Certifications
				</label>
				<div className="mt-1">
					<MultiSelect
						className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
						options={jsonList && jsonList.facilityCertifications}
						value={facilityList}
						onChange={handleDropdownChangeFacility}
						labelledBy={'Facility Certifications'}
						overrideStrings={{
							selectSomeItems: 'Facility Certifications',
						}}
						disableSearch={true}
						hasSelectAll={false}
					/>
					{stepFirstFrom && facilityList.length < 1 && (
						<div className="block text-sm font-medium text-red-500">
							Facility Certifications is required
						</div>
					)}
				</div>
			</div>

			<div className="col-span-6">
				<label
					htmlFor="street_address"
					className="block text-sm font-medium text-gray-700"
				>
					Street address
				</label>
				<ReactGoogleMapLoader
					params={{
						key: GOOGLE_API_KEY,
						libraries: 'places,geocode',
					}}
					render={(googleMaps) =>
						googleMaps && (
							<ReactGooglePlacesSuggest
								autocompletionRequest={{
									input: searchLocation ? searchLocation : '',
								}}
								onSelectSuggest={(data) => handleAddressSelect(data)}
								googleMaps={googleMaps}
								displayPoweredByGoogle={false}
							>
								<input
									type="text"
									value={location}
									className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
									placeholder="Location Search"
									onChange={(e) => {
										setLocation(e.target.value);
										setSearchLocation(e.target.value);
									}}
									onKeyDown={(e) =>
										e.key === 'Enter'
											? handleAddressSelect(null)
											: handleAddressSelect(null)
									}
								/>
							</ReactGooglePlacesSuggest>
						)
					}
				/>
				{stepFirstFrom && !location && (
					<div className="block text-sm font-medium text-red-500">
						Street Address is required
					</div>
				)}
				{stepFirstFrom && location && longitude === null && (
					<div className="block text-sm font-medium text-red-500">
						Please Select Address from options
					</div>
				)}
			</div>

			<div className="col-span-6 sm:col-span-6 lg:col-span-2">
				<label
					htmlFor="city"
					className="block text-sm font-medium text-gray-700"
				>
					City
				</label>
				<input
					type="text"
					name="city"
					id="city"
					value={city}
					onChange={(e) => setCity(e.target.value)}
					className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
				/>
			</div>

			<div className="col-span-6 sm:col-span-3 lg:col-span-2">
				<label
					htmlFor="state"
					className="block text-sm font-medium text-gray-700"
				>
					State / Province
				</label>
				<input
					type="text"
					name="state"
					id="state"
					value={states}
					onChange={(e) => setStates(e.target.value)}
					className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
				/>
			</div>

			<div className="col-span-6 sm:col-span-3 lg:col-span-2">
				<label
					htmlFor="postal_code"
					className="block text-sm font-medium text-gray-700"
				>
					ZIP / Postal
				</label>
				<input
					type="text"
					name="postal_code"
					id="postal_code"
					value={zip}
					onChange={(e) => setZip(e.target.value)}
					autoComplete="postal-code"
					className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
				/>
			</div>
			<div className="col-span-6 sm:col-span-3">
				<label
					htmlFor="country"
					className="block text-sm font-medium text-gray-700"
				>
					Country
				</label>
				<input
					type="text"
					name="country"
					autoComplete="given-name"
					value={country}
					onChange={(e) => setCountry(e.target.value)}
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
				/>
			</div>
			<div className="col-span-6">
				<div className="grid grid-cols-4">
					<button
						type="button"
						onClick={(e) => validateOverView(e)}
						className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
					>
						Continue
						<ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
}
