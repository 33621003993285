export enum UseCases {
    Overflow = 1,
    Project = 2,
    NewSupplier = 3,
    StockPositioning = 4
}

export const UseCasesLabel = new Map<number, string>([
    [UseCases.Overflow, 'Overflow'],
    [UseCases.Project, 'Project'],
    [UseCases.NewSupplier, 'New Supplier'],
    [UseCases.StockPositioning, 'Stock Positioning']
]);

export enum ProjectStartDate {
    One_ThreeMonths = 1,
    Three_SixMonths = 2,
    Six_NineMonths = 3,
    Nine_TwelveMonths = 4,
    TwelvePlusMonths = 5,
}

export const ProjectStartDateLabel = new Map<number, string>([
    [ProjectStartDate.One_ThreeMonths, '1-3 months'],
    [ProjectStartDate.Three_SixMonths, '3-6 months'],
    [ProjectStartDate.Six_NineMonths, '6-9 months'],
    [ProjectStartDate.Nine_TwelveMonths, '9-12 months'],
    [ProjectStartDate.TwelvePlusMonths, '12+ months']
]);

export enum ProjectTerm {
    Recurring = 1,
    Nonrecurring = 2
}

export const ProjectTermLabel = new Map<number, string>([
    [ProjectTerm.Recurring, 'Recurring'],
    [ProjectTerm.Nonrecurring, 'Non-recurring'],
]);

export enum ProjectDurationTerm {
    Month = 1,
    Year = 2
}

export const ProjectDurationTermLabel = new Map<number, string>([
    [ProjectDurationTerm.Month, 'Month'],
    [ProjectDurationTerm.Year, 'Year']
]);

export enum UoM {
    Pallet = 1,
    Crate = 2,
    Box = 3,
    Supersack = 4,
    Gaylord = 5,
    Other = 6
}

export const UoMLabel = new Map<number, string>([
    [UoM.Pallet, 'Pallet'],
    [UoM.Crate, 'Crate'],
    [UoM.Box, 'Box'],
    [UoM.Supersack, 'Supersack'],
    [UoM.Gaylord, 'Gaylord'],
    [UoM.Other, 'Other']
]);

export enum Dimension {
    Inches = 1,
    Feet = 2
}

export const DimensionLabel = new Map<number, string>([
    [Dimension.Inches, 'inches'],
    [Dimension.Feet, 'feet']
]);

export enum Weight {
    Lbs = 1,
    Kg = 2
}

export const WeightLabel = new Map<number, string>([
    [Weight.Lbs, 'lbs'],
    [Weight.Kg, 'kg']
]);

export enum Quantity {
    Pallet = 1,
    Crate = 2,
    Box = 3,
    Supersack = 4,
    Gaylord = 5,
    Other = 6
}

export const QuantityLabel = new Map<number, string>([
    [Quantity.Pallet, 'Pallets'],
    [Quantity.Crate, 'Crates'],
    [Quantity.Box, 'Boxes'],
    [Quantity.Supersack, 'Supersacks'],
    [Quantity.Gaylord, 'Gaylords'],
    [Quantity.Other, 'Other']
]);

export enum Stackable {
    Double = 1,
    Triple = 2,
    Quad = 3
}

export const StackableLabel = new Map<number, string>([
    [Stackable.Double, 'Double'],
    [Stackable.Triple, 'Triple'],
    [Stackable.Quad, 'Quad']
]);


export enum Rackable {
    Single = 1,
    Double = 2,
    Triple = 3,
    Quad = 4,
    More = 5
}

export const RackableLabel = new Map<number, string>([
    [Rackable.Single, 'Single'],
    [Rackable.Double, 'Double'],
    [Rackable.Triple, 'Triple'],
    [Rackable.Quad, 'Quad'],
    [Rackable.More, 'More']
]);

export enum InBoundVolume {
    Daily = 1,   
    Monthly = 2,
    Annually = 3,
    Weekly = 4
}

export const InBoundVolumeLabel = new Map<number, string>([
    [InBoundVolume.Daily, 'Daily'],
    [InBoundVolume.Weekly, 'Weekly'],
    [InBoundVolume.Monthly, 'Monthly'],
    [InBoundVolume.Annually, 'Annually']
]);

export enum OutBoundVolume {
    Daily = 1,
    Monthly = 2,
    Annually = 3,
    Weekly = 4
}

export const OutBoundVolumeLabel = new Map<number, string>([
    [OutBoundVolume.Daily, 'Daily'],
    [OutBoundVolume.Monthly, 'Monthly'],
    [OutBoundVolume.Annually, 'Annually'],
    [OutBoundVolume.Weekly, 'Weekly'],
]);

export enum SOWStatus {
    Pending = 0,
    Accepted = 1,
    ClientPassed = 2
}

export const SOWStatusLabel = new Map<number, string>([
    [SOWStatus.Pending, 'Pending'],
    [SOWStatus.Accepted, 'Accepted'],
    [SOWStatus.ClientPassed, 'Client Passed']
]);

export enum InboundModesStatus {
    container = 1,
    truckload = 2,
    lessthantruck = 3,
    parcel = 4
}
export const InBoundModeLabel = new Map<number, string>([
    [InboundModesStatus.container, 'Container'],
    [InboundModesStatus.truckload, 'Truckload'],
    [InboundModesStatus.lessthantruck, 'Less-than-Truckload'],
    [InboundModesStatus.parcel, 'Parcel'],
]);

export enum OutboundModesStatus {
    truckload = 1,
    lessthantruck = 2,
    parcel = 3,
    localCourier = 4
}

export const OutBoundModeLabel = new Map<number, string>([
    [OutboundModesStatus.truckload, 'Truckload'],
    [OutboundModesStatus.lessthantruck, 'Less-than-Truckload'],
    [OutboundModesStatus.parcel, 'Parcel'],
    [OutboundModesStatus.localCourier, 'Local Courier'],
]);

export enum TotalSkuStatus {
    lbs = 1,
    kg = 2
}
export const TotalSkuLabel = new Map<number, string>([
    [TotalSkuStatus.lbs, 'lbs'],
    [TotalSkuStatus.kg, 'kg'],
]);

export enum TransportationAssistancesStatus {
    yes = 1,
    no = 2,
    maybe = 3
}
export const TransportationAssistancesLabel = new Map<number, string>([
    [TransportationAssistancesStatus.yes, 'Yes'],
    [TransportationAssistancesStatus.no, 'No'],
    [TransportationAssistancesStatus.maybe, 'Maybe'],
]);