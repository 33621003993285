import React, {Fragment, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {CommonHeader} from '../Modules/CommonModules/commonHeaderHoc';
import {getAccessTokenFromLocalStorage, isUserAuthenticated} from '../../utils';

function LayoutSection(props) {
	const {children} = props;
	const isAuthUser = isUserAuthenticated(getAccessTokenFromLocalStorage());
	const [isLoggedIn, setIsLoggedIn] = useState(isAuthUser);
	const location = useLocation();
	const {pathname} = location;

	useMemo(() => {
		if (isAuthUser) {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthUser, pathname]);
	return (
		<Fragment>
			<div className="flex flex-col absolute h-full w-full overflow-hidden">
				{isLoggedIn ? <CommonHeader /> : null}
				{children}
			</div>
		</Fragment>
	);
}
export const Layout = React.memo(LayoutSection);
