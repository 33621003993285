import {useState} from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import {useHistory} from 'react-router-dom';
import Slider from './slider';
import {getAccessTokenFromLocalStorage, GetTokenDetails} from '../../../utils';
import AmenitiesIcons from '../shipperDashboard/components/amenities';
import NoImages from '../../../assets/image_sample/NoPictures.png';
import {TrashIcon} from '@heroicons/react/outline';
import DeleteWareHouse from '../CommonModules/modals/deleteWareHosue';
import {deletWarehouseMethod, getSupplierWarehouse} from '../../../actions';
import {useWarehouseStore} from "../../../store/index";

export default function WarehouseCard(props) {
	const [isChecked, setIsChecked] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const {listWarehouses} = useWarehouseStore();
	const [isDelete, setIsDelete] = useState(false);
	const history = useHistory();
	const {warehouseName, capacityPercentage, images, id, lastUpdated} = props;
	const userDetails = GetTokenDetails(getAccessTokenFromLocalStorage());
	const imageSlider = (images) => {
		if (images && images.length > 1) {
			return <Slider images={images} />;
		} else if (images && images.length === 1) {
			return (
				<img
					className="rounded-xl h-56"
					src={`${images[0].imageURL}&amp;auto=format&amp;fit=crop&amp;w=512&amp;q=80`}
					alt=""
					style={{objectFit: 'cover'}}
					x-state-description='undefined: "", undefined: "group-hover:opacity-75"'
				/>
			);
		} else {
			return (
				<img
					className="rounded-xl h-56"
					src={NoImages}
					alt=""
					style={{objectFit: 'cover'}}
					x-state-description='undefined: "", undefined: "group-hover:opacity-75"'
				/>
			);
		}
	};
	const handleWareHouse = (e, warehouseId) => {
		e.preventDefault();
		history.push(`/warehouse/${warehouseId}`);
	};
	const handleChangeCheck = (e) => {
		if (isChecked) {
			setIsChecked(false);
		} else {
			setIsChecked(true);
		}
	};
	const deleteWareHouse = () => {
		setIsOpen(true);
	};
	const closeModal = () => {
		setIsOpen(false);
	};
	const deletewarehouseId = () => {
		setIsDelete(true);
		deletWarehouseMethod(id)
			.then((resp) => {
				listWarehouses();
				setTimeout(() => {
					setIsDelete(false);
					closeModal();
				}, 2000);
			})
			.catch((err) => {
				
//
				setIsDelete(false);
				closeModal();
			});
	};
	return (
		<>
			{isOpen && (
				<DeleteWareHouse
					isOpen={isOpen}
					cancelBtn={closeModal}
					continueBtn={deletewarehouseId}
					isDelete={isDelete}
				/>
			)}
			<div className="flex max-w-sm max-h-sm bg-gray-100 shadow-lg hover:shadow-xl rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
				<div className="flex flex-col justify-between flex-1 p-3">
					<div className="flex justify-between">
						{userDetails && userDetails.actort === 'Shipper' ? (
							<div className="flex text-gray-500">
								
								{props.photospheres.length > 0 ? (<AmenitiesIcons responsive={true} />) : null}
								{`${
									props.miles && props.miles > -1 ? `${props.miles} miles` : ''
								}`}
							</div>
						) : (
							<div className="flex">
								<input
									id="candidates"
									name="candidates"
									type="checkbox"
									checked={isChecked}
									onChange={handleChangeCheck}
									className="h-6 w-6 text-indigo-600 border-gray-300 rounded-lg"
								/>
							</div>
						)}
						<div className="flex text-sm mb-4 justify-end text-gray-500">
							{`${lastUpdated}`}
							{isChecked && (
								<TrashIcon
									onClick={deleteWareHouse}
									className="h-6 w-6"
									aria-hidden="true"
								/>
							)}
						</div>
					</div>
					<div
						className="flex pl-2 rounded-xl h-8 bg-gray-200 mb-2 items-center text-gray-500"
						onClick={(e) => handleWareHouse(e, id)}
						style={{fontFamily: 'ocr-a-bt, sans-serif'}}
					>
						{(userDetails && userDetails.actort === 'Shipper'
							? `POP ID: ${id.split('-')[0]}`
							: `${warehouseName}`) || ''}
					</div>
					{imageSlider(images)}
					<div className="mt-2" onClick={(e) => handleWareHouse(e, id)}>
						<div className="border border-gray-200 mb-2"></div>
						<div className="flex flex-row items-center justify-between">
							<div className="w-5/12 rounded-md h-2">
								<ProgressBar
									height={'10px'}
									isLabelVisible={false}
									completed={Number(capacityPercentage)}
									bgColor={'#10B981'}
									baseBgColor={'#F87171'}
								/>
							</div>
							<span className="text-sm text-gray-500">
								Available Capacity
								<span className="font-semibold text-gray-500">
									{capacityPercentage}%
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
