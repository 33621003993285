import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { shipper, supplier } from '../../../../constants'
import { GetTokenDetails } from '../../../../utils';
const supplierSignalRUrl = process.env.REACT_APP_SUPPLIER_CONNECTION_HUB_URL;
const shipperSignalRUrl = process.env.REACT_APP_SHIPPER_CONNECTION_HUB_URL;
var connectionObj = null;

export function createConnection(token) {
    if (!connectionObj) {
        let connectionStr = "";
        const userData = GetTokenDetails(token);
        if (userData.actort === shipper) {
            connectionStr = shipperSignalRUrl;
        } else if (userData.actort === supplier) {
            connectionStr = supplierSignalRUrl;
        }
        connectionObj = new HubConnectionBuilder().withUrl(connectionStr, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Debug) // TODO: Change for prod
            .build();
        connectionObj.serverTimeoutInMilliseconds = 1000 * 60 * 15 + (1000 * 15);
        connectionObj.start()
            .then(() => {})
            .catch(err => console.error('SignalR Connection Error: ', err));
        return connectionObj;
    } else {
        return connectionObj;
    }
}

export function getConnection(token) {
    if (connectionObj) {
        return connectionObj;
    } else {
        return createConnection(token);
    }
}
