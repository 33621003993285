/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline';
import {useHistory, useLocation} from 'react-router-dom';
import popLogo from '../../../assets/brand/poplogo.svg';
import {
	GetTokenDetails,
	getAccessTokenFromLocalStorage,
	classNames,
	routePath,
} from '../../../utils';
import WareHouseNav from '../Dashboard/wareHouseNav';
import {
	navigationHeaderList,
	profileList,
	explorehipperConstant,
	contractsProposal,
	myWarehouseConstant,
	signout,
	navigationHeaderListShipper,
	exploreWarehouseConstant,
	proposalsConstant,
} from '../../../constants';
import {logoutAction} from '../../../actions';

import NotificationList from './notificationList';
import {getConnection} from '../Dashboard/signalR';

export default function CommonHeaderHoc(props) {
	const history = useHistory();
	const location = useLocation();
	const {pathname} = location;
	const [userData, setUserData] = useState(null);
	const [headerType, setHeaderType] = useState(routePath(pathname));
	const [count, setCount] = useState(0);
	useMemo(() => {
		const token = getAccessTokenFromLocalStorage();
		if (token) {
			const user = GetTokenDetails(token);
			setUserData(user);
			// handleNotification(token);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function IconThree() {
		return (
			<svg
				width="48"
				height="48"
				viewBox="0 0 48 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="48" height="48" rx="8" fill="#FFEDD5" />
				<rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
				<rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
				<rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
				<rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
				<rect x="29" y="16" width="2" height="20" fill="#FB923C" />
				<rect x="33" y="12" width="2" height="24" fill="#FB923C" />
			</svg>
		);
	}

	useEffect(() => {
		setHeaderType(routePath(pathname));
	}, [history, pathname]);

	const headerSet = (e, val) => {
		e.preventDefault();
		setHeaderType(val);
		if (val === explorehipperConstant) {
			history.push('/explore-shippers');
		} else if (val === contractsProposal) {
			history.push('/contracts-and-proposals');
		} else if (val === myWarehouseConstant) {
			history.push('/my-warehouses');
		} else if (val === exploreWarehouseConstant) {
			history.push('/explore-warehouses');
		} else if (val === proposalsConstant) {
			history.push('/proposals');
		} else if (val === proposalsConstant) {
			history.push('/proposals');
		}
	};
	const handleClick = (e, type) => {
		e.preventDefault();
		if (type.toLowerCase() === signout.toLowerCase()) {
			logoutAction(history);
		} else {
			history.push(`/${type.toLowerCase().replace(' ', '-')}`);
		}
	};

	const setCountFunc = (v) => {
		console.log(v, '_____v_____');
		setCount(v);
	};

	const initials = (name) => {
		if (name) {
			let split = name.split(' ');
			if (split.length > 1) {
				const first = split[0][0] ? split[0][0].toUpperCase() : 'X';
				const last = split[1][0] ? split[1][0].toUpperCase() : 'X';
				return `${first}${last}`;
			} else {
				const first = split[0][0].toUpperCase();
				return `${first}`;
			}
		}
	};

	return (
		<Disclosure as="nav" className="bg-indigo-800">
			{({open}) => (
				<>
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex items-center justify-between h-14">
							<div className="flex items-center">
								<div
									className="flex-shrink-0 w-40"
									onClick={() => {
										userData && userData.actort === 'Supplier'
											? history.push('/explore-shippers')
											: history.push('/explore-warehouses');
									}}
								>
									<img src={popLogo} alt="Workflow" />
								</div>
								<div className="hidden md:block">
									{location &&
									location.pathname === '/add-warehouse' ? null : (location &&
											location.pathname === '/settings') ||
									  location.pathname === '/your-profile' ? null : (
										<WareHouseNav
											navigation={
												userData && userData.actort === 'Supplier'
													? navigationHeaderList
													: navigationHeaderListShipper
											}
											headerType={routePath(pathname) === '' ? '' : headerType}
											headerSet={headerSet}
										/>
									)}
								</div>
							</div>
							<div className="hidden md:block">
								<div className="ml-4 flex items-center md:ml-6">
									<div className="relative rounded-full mr-4 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-pop-alt focus:ring-pop h-6 w-6">
										<span className="sr-only">View notifications</span>
										<NotificationList setCountFunc={setCountFunc}>
											<BellIcon className="h-6 w-6" aria-hidden="true" />
											{count > 0 && (
												<span className="absolute flex justify-center z-10 -top-2 left-4 items-center px-1 py-0.5 rounded-full text-sm font-medium bg-red-600 text-gray-50 w-6 h-6">
													{count > 0 ? count : null}
												</span>
											)}
										</NotificationList>
									</div>
									{/* Profile dropdown */}
									<Menu as="div" className="ml-3 relative">
										{({open}) => (
											<>
												<div>
													<Menu.Button className="pl-1 pr-2 max-w-xs border-pop-alt rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-pop-alt">
														<span className="sr-only">Open user menu</span>
														<div className="flex justify-center items-center h-8 w-8 rounded-full bg-yellow-400">
															<div>
																{userData && userData.nonce
																	? initials(userData.nonce)
																	: ''}
															</div>
														</div>
														<div className="flex flex-col items-start ml-4">
															<span className="text-sm font-medium text-white">
																{(userData && userData.nonce) || ''}
															</span>
															<span className="text-xs text-white opacity-75">
																<p>{(userData && userData.sub) || ''}</p>
															</span>
														</div>
													</Menu.Button>
												</div>
												<Transition
													show={open}
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items
														static
														className="origin-top-right absolute right-0 mt-4 w-full z-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
													>
														{profileList.map((item) => (
															<Menu.Item key={item}>
																{({active}) => (
																	<a
																		onClick={(e) => handleClick(e, item)}
																		className={classNames(
																			active ? 'bg-gray-100' : '',
																			'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
																		)}
																	>
																		{item}
																	</a>
																)}
															</Menu.Item>
														))}
													</Menu.Items>
												</Transition>
											</>
										)}
									</Menu>
								</div>
							</div>
							<div className="-mr-2 flex md:hidden">
								{/* Mobile menu button */}
								<Disclosure.Button className="bg-indigo-700 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<MenuIcon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="md:hidden">
						<div className="pt-4 pb-3 border-t border-indigo-700">
							<div className="flex items-center px-5">
								<div className="flex-shrink-0">
									<img
										className="h-10 w-10 rounded-full"
										src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
										alt=""
									/>
								</div>
								<div className="ml-3">
									<div className="text-base font-medium text-white">
										Tom Cook
									</div>
									<div className="text-sm font-medium text-indigo-300">
										tom@example.com
									</div>
								</div>
								<button className="ml-auto bg-indigo-600 flex-shrink-0 p-1 rounded-full text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-3 px-2 space-y-1">
								{profileList.map((item) => (
									<a
										key={item}
										href="#"
										className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
									>
										{item}
									</a>
								))}
							</div>
						</div>
						<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t-2 border-indigo-900 border-opacity-60 ">
							{navigationHeaderList.map((item, itemIdx) =>
								itemIdx === 0 ? (
									<Fragment key={item}>
										<a
											href="#"
											className="bg-indigo-800 text-white block px-3 py-2 rounded-md text-base font-medium"
										>
											{item}
										</a>
									</Fragment>
								) : (
									<a
										key={item}
										href="#"
										className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
									>
										{item}
									</a>
								)
							)}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
export const CommonHeader = React.memo(CommonHeaderHoc);
