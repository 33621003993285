import {useState, useEffect} from 'react';
import {Transition} from '@headlessui/react';
import {ArrowCircleLeftIcon} from '@heroicons/react/outline';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import * as SOWEnum from '../../../../utils/sow.enum';
import {
	addCommas,
	GOOGLE_API_KEY,
	arrayGetValueFilters,
	useCasesConst,
	projectStartDateConst,
	projectTermTypeConst,
	projectTermDurationTypeConst,
	inboundModeConst,
	outboundModeConst,
	transportationAssistanceConst,
	getState,
	getCity,
	getCountry,
	getStreetAddress,
	getZipcode,
	addressFinder,
	volumeConst,
	quantityConst,
} from '../../../../utils';
import {updateSow} from '../../../../actions';
import NumberFormat from 'react-number-format';
import Geocode from 'react-geocode';
import {MultiSelect} from 'react-multi-select-component';
import XCancel from '../../CommonModules/cancelIcon';
import CheckMark from '../../CommonModules/checkMark';
import PencilMark from '../../CommonModules/pencilIcon';
import {useHistory} from 'react-router-dom';

Geocode.setApiKey(GOOGLE_API_KEY);

export default function ScopeDetails(props) {
	const {records, show, goBack, showScopeDetails} = props;
	const history = useHistory();
	const [searchLocation, setSearchLocation] = useState('');
	const [location, setLocation] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const [city, setCity] = useState('');
	const [statesfield, setStatesfield] = useState('');
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');
	const [visibleLoc, setVisibleLoc] = useState('');
	const valueFromLocalStorage = localStorage.getItem('filters');
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters('', 'facilityCertifications')
	);
	const [productWeightFormatted, setProductWeightFormatted] = useState('');
	const [productWeight, setProductWeight] = useState('');

	const [averageOutboundVolumeFormatted, setAverageOutboundVolumeFormatted] =
		useState('');
	const [averageOutboundVolume, setAverageOutboundVolume] = useState('');

	const [averageInboundVolumeFormatted, setAverageInboundVolumeFormatted] =
		useState('');
	const [averageInboundVolume, setAverageInboundVolume] = useState('');

	const [quantityFormatted, setQuantityFormatted] = useState('');
	const [quantity, setQuantity] = useState('');
	const [
		approxRequiredSqaureFootageFormatted,
		setApproxRequiredSqaureFootageFormatted,
	] = useState('');
	const [approxRequiredSqaureFootage, setApproxRequiredSqaureFootage] =
		useState('');

	const [averageInboundQtyFormatted, setAverageInboundQtyFormatted] =
		useState('');
	const [averageInboundQty, setAverageInboundQty] = useState('');

	const [averageOutboundQtyFormatted, setAverageOutboundQtyFormatted] =
		useState('');
	const [averageOutboundQty, setAverageOutboundQty] = useState('');
	const [final, setFinal] = useState(false);
	const [states, setStates] = useState({
		scopeName: '',
		projectedStartDate: '',
		projectTerm: '',
		projectTermDurationType: '',
		projectTermType: '',
		useCase: '',
		commodity: '',
		productUoM: '',
		totalSKU: 0,
		productDimensions: '',
		productDimensions2: '',
		productDimensionsType: '',
		productWeightType: '',
		transportationAssistance: '',
		outboundMode: '',
		inboundMode: '',
		averageOutboundVolumeType: '',
		averageOutboundQtyType: '',
		quantityType: '',
		rackable: '',
		stackableType: '',
		stackable: '',
		rackableType: '',
		averageInboundQtyType: '',
		averageInboundVolumeType: '',
	});

	// eslint-disable-next-line no-unused-vars
	const jsonList = JSON.parse(valueFromLocalStorage);

	const [edit, setEdit] = useState({
		level: '0',
		type: 'commodity',
	});
	const [editCity, setEditCity] = useState({
		level: '0',
		type: 'city',
	});
	const [editapr, setEditapr] = useState({
		level: '0',
		type: 'apr',
	});
	const [editaiq, setEditaiq] = useState({
		level: '0',
		type: 'aiq',
	});
	const [editaiv, setEditaiv] = useState({
		level: '0',
		type: 'aiv',
	});
	const [editaov, setEditaov] = useState({
		level: '0',
		type: 'aov',
	});
	const [editaoq, setEditaoq] = useState({
		level: '0',
		type: 'aoq',
	});
	const [editsku, setEditsku] = useState({
		level: '0',
		type: 'sku',
	});
	const [editfcs, setEditfcs] = useState({
		level: '0',
		type: 'fcs',
	});
	const [edituca, setEdituca] = useState({
		level: '0',
		type: 'uca',
	});
	const [editpsd, setEditpsd] = useState({
		level: '0',
		type: 'psd',
	});
	const [editpt, setEditpt] = useState({
		level: '0',
		type: 'pt',
	});
	const [editim, setEditim] = useState({
		level: '0',
		type: 'im',
	});
	const [editom, setEditom] = useState({
		level: '0',
		type: 'om',
	});
	const [editta, setEditta] = useState({
		level: '0',
		type: 'ta',
	});
	const handleChange = (e) => {
		let value = e.target.value;
		let name = e.target.name;

		setStates((prevalue) => {
			return {
				...prevalue, // Spread Operator
				[name]: value,
			};
		});
	};
	useEffect(() => {
		if (records && records.scopeName !== null) {
			setStates((prevalue) => {
				return {
					...prevalue, // Spread Operator
					scopeName: records.scopeName,
					projectedStartDate: records.projectedStartDate,
					projectTerm: records.projectTerm,
					projectTermDurationType: records.projectTermDurationType,
					projectTermType: records.projectTermType,
					useCase: records.useCase,
					commodity: records.commodity,
					productUoM: records.productUoM,
					totalSKU: records.totalSKU,
					productDimensions: records.productDimensions,
					productDimensions2: records?.productDimensions2 || '',
					productDimensionsType: records?.productDimensionsType || '',
					productWeightType: records?.productWeightType,
					transportationAssistance: records.transportationAssistance,
					outboundMode: records.outboundMode,
					inboundMode: records.inboundMode,
					averageOutboundVolumeType: records.averageOutboundVolumeType,
					averageOutboundQtyType: records.averageOutboundQtyType,
					quantityType: records.quantityType,
					rackable: records.rackable,
					stackableType: records.stackableType,
					stackable: records.stackable === true ? 'true' : 'false',
					rackableType: records.rackableType,
					averageInboundQtyType: records.averageInboundQtyType,
					averageInboundVolumeType: records.averageInboundVolumeType,
				};
			});
			setApproxRequiredSqaureFootage(
				records?.approxRequiredSqaureFootage || ''
			);
			setFacilityList(records?.facilityCertifications || '');
			setAverageInboundQty(records?.averageInboundQty || '');
			setAverageInboundVolume(records?.averageInboundVolume || '');
			setAverageOutboundQty(records?.averageOutboundQty || '');
			setAverageOutboundVolume(records?.averageOutboundVolume || '');
			setProductWeight(records?.productWeight || '');
			setQuantity(records?.quantity || '');
			addressFinder(records.latitude, records.longitude)
				.then((response) => {
					const addressArray = response.results[0].address_components;
					setLatitude(records.latitude);
					setLongitude(records.longitude);
					setCity(getCity(addressArray));
					setStatesfield(getState(addressArray));
					setCountry(getCountry(addressArray));
					const streetAddress = getStreetAddress(addressArray);
					setZip(getZipcode(addressArray));
					setVisibleLoc(streetAddress ? streetAddress : 'address');
					setLocation(records.city);
					setSearchLocation('');
				})
				.catch((err) => {
					setCity('');
					setStatesfield('');
					setCountry('');
					setZip('');
					setLocation(null);
					setSearchLocation('');
					setVisibleLoc('');
					setLatitude(null);
					setLongitude(null);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [records && records.id]);
	const editField = (val, data, trigger = false) => {
		if (val === '1' && data === 'commodity') {
			setStates((prevalue) => {
				return {
					...prevalue, // Spread Operator
					commodity: records.commodity,
				};
			});
			setEdit({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'commodity') {
			setEdit({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'city') {
			setLocation(records.city);
			setEditCity({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'city') {
			setEditCity({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'apr') {
			setApproxRequiredSqaureFootage(records.approxRequiredSqaureFootage);
			setEditapr({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'apr') {
			setEditapr({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'aiq') {
			setEditaiq({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'aiq') {
			setEditaiq({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'aiv') {
			setEditaiv({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'aiv') {
			setEditaiv({
				level: val,
				type: data,
			});
		} else if (val === '1' && data === 'aov') {
			setEditaov({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'aov') {
			setEditaov({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'aoq') {
			setEditaoq({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'aoq') {
			setEditaoq({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'sku') {
			setStates({
				...records,
				totalSKU: records.totalSKU,
			});
			setEditsku({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'sku') {
			setEditsku({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'fcs') {
			setEditfcs({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'fcs') {
			setEditfcs({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'uca') {
			setEdituca({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'uca') {
			setEdituca({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'psd') {
			setEditpsd({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'psd') {
			setEditpsd({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'pt') {
			setEditpt({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'pt') {
			setEditpt({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'im') {
			setEditim({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'im') {
			setEditim({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'om') {
			setEditom({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'om') {
			setEditom({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		} else if (val === '1' && data === 'ta') {
			setEditta({
				level: val,
				type: data,
			});
		} else if (val === '0' && data === 'ta') {
			setEditta({
				level: val,
				type: data,
			});
			if (trigger) {
				validateStorage();
			}
		}
	};

	const handleAddressSelect = (place) => {
		if (place && place.formatted_address) {
			const address = place.formatted_address,
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			Geocode.fromLatLng(latValue, lngValue).then(
				(response) => {
					const addressArray = response.results[0].address_components;
					setLatitude(latValue);
					setLongitude(lngValue);
					setCity(getCity(addressArray));
					setStatesfield(getState(addressArray));
					setCountry(getCountry(addressArray));
					const streetAddress = getStreetAddress(addressArray);
					setZip(getZipcode(addressArray));
					setVisibleLoc(streetAddress ? streetAddress : address);
					setLocation(address);
					setSearchLocation('');
				},
				(error) => {
					console.error(error);
				}
			);
		} else {
			setCity('');
			setStatesfield('');
			setCountry('');
			setZip('');
			setLocation(null);
			setSearchLocation('');
			setVisibleLoc('');
			setLatitude(null);
			setLongitude(null);
		}
	};

	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};

	const validateStorage = () => {
		const {
			useCase,
			productDimensions,
			productDimensionsType,
			productUoM,
			projectTerm,
			productWeightType,
			projectTermDurationType,
			projectTermType,
			projectedStartDate,
			averageInboundQtyType,
			averageInboundVolumeType,
			averageOutboundQtyType,
			averageOutboundVolumeType,
			rackable,
			rackableType,
			stackable,
			stackableType,
			totalSKU,
			transportationAssistance,
			inboundMode,
			outboundMode,
			commodity,
			quantityType,
			productDimensions2,
		} = states;
		if (
			records.approxRequiredSqaureFootage &&
			parseFloat(records.approxRequiredSqaureFootage) > 0
		) {
			setFinal(true);
			const requestData = {
				ScopeName: records.scopeName,
				Location: location,
				Latitude: latitude,
				Longitude: longitude,
				FacilityCertifications:
					typeof facilityList === 'string'
						? facilityList
						: facilityList
								.map((ele) => {
									return ele.value;
								})
								.join(','),
				City: city,
				State: statesfield,
				Zipcode: zip,
				Country: country,
				UseCase: useCase ? parseInt(useCase) : null,
				ProjectedStartDate: projectedStartDate
					? parseInt(projectedStartDate)
					: null,
				ProjectTerm: projectTerm ? parseFloat(projectTerm) : null,
				ProjectTermType: projectTermType ? parseInt(projectTermType) : null,
				ProjectTermDurationType: projectTermDurationType
					? parseInt(projectTermDurationType)
					: null,
				Commodity: commodity,
				ProductUoM: productUoM ? parseInt(productUoM) : null,
				ProductUoMOther: null,
				ProductDimensions: `${productDimensions}X${productDimensions2}`,
				ProductDimensionsType: productDimensionsType
					? parseInt(productDimensionsType)
					: null,
				ProductWeight: productWeight ? parseFloat(productWeight) : null,
				ProductWeightType: productWeightType
					? parseInt(productWeightType)
					: null,
				Quantity: quantity ? parseFloat(quantity) : null,
				QuantityType: quantityType ? parseInt(quantityType) : null,
				QuantityTypeOther: null,
				Stackable: stackable ? (stackable === 'true' ? true : false) : null,
				StackableType: stackableType ? parseInt(stackableType) : null,
				Rackable: rackable ? (rackable === 'true' ? true : false) : null,
				RackableType: rackableType ? parseInt(rackableType) : null,
				ApproxRequiredSqaureFootage: parseFloat(approxRequiredSqaureFootage),
				AverageInboundQty: averageInboundQty
					? parseFloat(averageInboundQty)
					: null,
				AverageInboundQtyType: averageInboundQtyType
					? parseInt(averageInboundQtyType)
					: null,
				AverageInboundQtyTypeOther: null,
				AverageInboundVolume: averageInboundVolume
					? parseFloat(averageInboundVolume)
					: null,
				AverageInboundVolumeType: averageInboundVolumeType
					? parseInt(averageInboundVolumeType)
					: null,
				AverageOutboundQty: averageOutboundQty
					? parseFloat(averageOutboundQty)
					: null,
				AverageOutboundQtyType: averageOutboundQtyType
					? parseInt(averageOutboundQtyType)
					: null,
				AverageOutboundQtyTypeOther: null,
				AverageOutboundVolume: averageOutboundVolume
					? parseFloat(averageOutboundVolume)
					: null,
				AverageOutboundVolumeType: averageOutboundVolumeType
					? parseInt(averageOutboundVolumeType)
					: null,
				TotalSKU: totalSKU ? parseInt(totalSKU) : 0,
				InboundMode: inboundMode ? parseInt(inboundMode) : null,
				OutboundMode: outboundMode ? parseInt(outboundMode) : null,
				TransportationAssistance: transportationAssistance
					? parseInt(transportationAssistance)
					: null,
				AvailableSoonSqaureFootage: null,
			};
			updateSow(records.id, requestData)
				.then((resp) => {
					console.log(resp);
					showScopeDetails(records.id);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			console.log('error');
		}
	};
	return (
		<Transition
			show={show}
			enter="transition-opacity duration-500"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-150"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
			appear={true}
		>
			{records && (
				<div className="overflow-y-auto border-gray-200 p-5 h-full">
					<div className="text-lg font-medium text-gray-500 flex flex-row items-center">
						<ArrowCircleLeftIcon
							className="h-6 w-6 mr-2 cursor-pointer"
							onClick={() => goBack()}
						/>
						<div className="flex align-middle">
							{states.scopeName || 'Scope Name'}
						</div>
					</div>
					<div className="px-8 pt-4">
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Commodity</span>
							<div className="flex flex-row">
								{edit.level === '0' && edit.type === 'commodity' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'commodity')} />
										<span className="text-md">{states.commodity || ''}</span>
									</div>
								)}

								{edit.level === '1' && edit.type === 'commodity' && (
									<div className="flex flex-row mt-1">
										<CheckMark
											onClick={() => editField('0', 'commodity', true)}
										/>
										<XCancel editField={() => editField('0', 'commodity')} />
										<input
											type="text"
											name="commodity"
											id="commodity"
											autoComplete="given-name"
											value={states.commodity}
											onChange={handleChange}
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">City</span>
							<div className="flex flex-row">
								{editCity.level === '0' && editCity.type === 'city' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'city')} />

										<span className="text-md">
											{records.city}, {records.state} {records.zipcode},{' '}
											{records.country === 'United States' ? 'USA' : ''}
										</span>
									</div>
								)}
								{editCity.level === '1' && editCity.type === 'city' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'city', true)} />
										<XCancel editField={() => editField('0', 'city')} />
										<ReactGoogleMapLoader
											params={{
												key: GOOGLE_API_KEY,
												libraries: 'places,geocode',
											}}
											render={(googleMaps) =>
												googleMaps && (
													<ReactGooglePlacesSuggest
														autocompletionRequest={{
															input: searchLocation,
														}}
														onSelectSuggest={(data) =>
															handleAddressSelect(data)
														}
														googleMaps={googleMaps}
														displayPoweredByGoogle={false}
													>
														<input
															type="text"
															value={location}
															className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
															placeholder="Location Search"
															onChange={(e) => {
																setLocation(e.target.value);
																setSearchLocation(e.target.value);
															}}
															onKeyDown={(e) => (e.key === 'Enter' ? null : '')}
														/>
													</ReactGooglePlacesSuggest>
												)
											}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Approximate SqFt Required*</span>
							<div className="flex flex-row">
								{editapr.level === '0' && editapr.type === 'apr' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'apr')} />
										<span className="text-md">
											{records.approxRequiredSqaureFootage.toLocaleString(
												'en-US'
											)}
										</span>
									</div>
								)}
								{editapr.level === '1' && editapr.type === 'apr' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'apr', true)} />
										<XCancel editField={() => editField('0', 'apr')} />
										<NumberFormat
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											placeholder={approxRequiredSqaureFootageFormatted.toString()}
											value={states.approxRequiredSqaureFootage}
											thousandSeparator={true}
											name="approxRequiredSqaureFootageFormatted"
											onValueChange={(values) => {
												const {formattedValue, value} = values;
												setApproxRequiredSqaureFootageFormatted(formattedValue);
												setApproxRequiredSqaureFootage(value);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Average Inbound Quantity</span>
							<div className="flex flex-row">
								{editaiq.level === '0' && editaiq.type === 'aiq' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'aiq')} />
										<span className="text-md">
											{records.averageInboundQty &&
												records.averageInboundQtyType &&
												addCommas(records.averageInboundQty)}{' '}
											{records.averageInboundQtyType ===
												SOWEnum.Quantity.Other &&
											records.averageInboundQtyTypeOther
												? records.averageInboundQtyTypeOther
												: SOWEnum.QuantityLabel.get(
														records.averageInboundQtyType
												  )}{' '}
											{SOWEnum.InBoundVolumeLabel.get(
												records.averageInboundVolumeType
											)}
										</span>
									</div>
								)}
								{editaiq.level === '1' && editaiq.type === 'aiq' && (
									<>
										<CheckMark onClick={() => editField('0', 'aiq', true)} />
										<XCancel editField={() => editField('0', 'aiq')} />
									</>
								)}
								{editaiq.level === '1' && editaiq.type === 'aiq' && (
									<div className="flex flex-row mt-1">
										<NumberFormat
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={
												averageInboundQtyFormatted === ''
													? averageInboundQty.toString()
													: averageInboundQty.toString()
											}
											thousandSeparator={true}
											onValueChange={(values) => {
												const {formattedValue, value} = values;
												setAverageInboundQtyFormatted(formattedValue);
												setAverageInboundQty(value);
											}}
										/>

										<select
											className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400  rounded-md`}
											name="averageInboundQtyType"
											value={states.averageInboundQtyType}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select</option>
											{quantityConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
										<select
											className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400  rounded-md`}
											name="averageInboundVolumeType"
											value={states.averageInboundVolumeType}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select</option>
											{volumeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Average Inbound Volume</span>
							<div className="flex flex-row">
								{editaiv.level === '0' && editaiv.type === 'aiv' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'aiv')} />
										<span className="text-md">
											{records.averageInboundVolume &&
												records.averageInboundVolumeType &&
												addCommas(records.averageInboundVolume)}{' '}
											{SOWEnum.InBoundModeLabel.get(
												records.averageInboundVolumeType
											) + 's'}{' '}
											{SOWEnum.InBoundVolumeLabel.get(
												records.averageInboundVolumeType
											) || '-'}
										</span>
									</div>
								)}
								{editaiv.level === '1' && editaiv.type === 'aiv' && (
									<>
										<CheckMark onClick={() => editField('0', 'aiv', true)} />
										<XCancel editField={() => editField('0', 'aiv')} />
									</>
								)}
								{editaiv.level === '1' && editaiv.type === 'aiv' && (
									<div className="flex flex-row mt-1">
										<NumberFormat
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={averageInboundVolume.toString()}
											thousandSeparator={true}
											onValueChange={(values) => {
												const {formattedValue, value} = values;
												setAverageInboundVolumeFormatted(formattedValue);
												setAverageInboundVolume(value);
											}}
										/>
										{/* <select
											className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400  rounded-md`}
											name="averageInboundQtyType"
											value={states.averageInboundQtyType}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select</option>
											{inboundModeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
										<select
											className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-gray-400  rounded-md`}
											name="averageInboundVolumeType"
											value={states.averageInboundVolumeType}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select</option>
											{volumeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select> */}
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Average Outbound Volume</span>
							<div className="flex flex-row">
								{editaov.level === '0' && editaov.type === 'aov' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'aov')} />
										<span className="text-md">
											{records.averageInboundVolume &&
												records.averageInboundVolumeType &&
												addCommas(records.averageInboundVolume)}{' '}
											{SOWEnum.OutBoundModeLabel.get(
												records.averageOutboundVolumeType
											) + ' '}
											{SOWEnum.InBoundVolumeLabel.get(
												records.averageOutboundVolumeType
											) || '-'}
										</span>
									</div>
								)}
								{editaov.level === '1' && editaov.type === 'aov' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'aov', true)} />
										<XCancel editField={() => editField('0', 'aov')} />
										<NumberFormat
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={averageOutboundVolumeFormatted}
											thousandSeparator={true}
											onValueChange={(values) => {
												const {formattedValue, value} = values;
												setAverageOutboundVolumeFormatted(formattedValue);
												setAverageOutboundVolume(value);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Average Outbound Quantity</span>
							<div className="flex flex-row">
								{editaoq.level === '0' && editaoq.type === 'aoq' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'aoq')} />
										<span className="text-md">
											{records.averageOutboundQty}{' '}
											{records.averageOutboundQty &&
												records.averageOutboundQtyType &&
												records.averageOutboundQtyTypeOther &&
												addCommas(records.averageOutboundQty)}{' '}
											{records.averageOutboundQtyType ===
												SOWEnum.Quantity.Other &&
											records.averageOutboundQtyTypeOther
												? records.averageOutboundQtyTypeOther
												: SOWEnum.QuantityLabel.get(
														records.averageOutboundQtyType
												  )}{' '}
											{SOWEnum.OutBoundVolumeLabel.get(
												records.averageOutboundVolume
											)}
										</span>
									</div>
								)}
								{editaoq.level === '1' && editaoq.type === 'aoq' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'aoq', true)} />
										<XCancel editField={() => editField('0', 'aoq')} />
										<NumberFormat
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={averageOutboundQtyFormatted}
											thousandSeparator={true}
											onValueChange={(values) => {
												const {formattedValue, value} = values;
												setAverageOutboundQtyFormatted(formattedValue);
												setAverageOutboundQty(value);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Total SKU</span>
							<div className="flex flex-row">
								{editsku.level === '0' && editsku.type === 'sku' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'sku')} />
										<span className="text-md">
											{records.totalSKU ? records.totalSKU : '-'}
										</span>
									</div>
								)}
								{editsku.level === '1' && editsku.type === 'sku' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'sku', true)} />
										<XCancel editField={() => editField('0', 'sku')} />
										<input
											type="text"
											name="totalSKU"
											id="totalSKU"
											autoComplete="given-name"
											value={states.totalSKU}
											onChange={handleChange}
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Facility Certifications</span>
							<div className="flex flex-row">
								{editfcs.level === '0' && editfcs.type === 'fcs' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'fcs')} />
										<span className="text-md">
											{records.facilityCertificationsValues
												? records.facilityCertificationsValues
												: '-'}
										</span>
									</div>
								)}
								{editfcs.level === '1' && editfcs.type === 'fcs' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'fcs', true)} />
										<XCancel editField={() => editField('0', 'fcs')} />
										<MultiSelect
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
											options={jsonList && jsonList.facilityCertifications}
											value={
												typeof facilityList === 'string'
													? arrayGetValueFilters(
															facilityList,
															'facilityCertifications'
													  )
													: facilityList
											}
											onChange={handleDropdownChangeFacility}
											labelledBy={'Facility Certifications'}
											overrideStrings={{
												selectSomeItems: 'Facility Certifications',
											}}
											disableSearch={true}
											hasSelectAll={false}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Use Case</span>
							<div className="flex flex-row">
								{edituca.level === '0' && edituca.type === 'uca' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'uca')} />
										<span className="text-md">
											{records.useCase
												? SOWEnum.UseCasesLabel.get(records.useCase)
												: '-'}
										</span>
									</div>
								)}
								{edituca.level === '1' && edituca.type === 'uca' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'uca', true)} />
										<XCancel editField={() => editField('0', 'uca')} />
										<select
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											name="useCase"
											value={states.useCase}
											onChange={handleChange}
										>
											<option value="">Select Use Case</option>
											{useCasesConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>

						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Projected Start Date</span>
							<div className="flex flex-row">
								{editpsd.level === '0' && editpsd.type === 'psd' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'psd')} />
										<span className="text-md">
											{records.projectedStartDate
												? SOWEnum.ProjectStartDateLabel.get(
														records.projectedStartDate
												  )
												: '-'}
										</span>
									</div>
								)}
								{editpsd.level === '1' && editpsd.type === 'psd' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'psd', true)} />
										<XCancel editField={() => editField('0', 'psd')} />

										<select
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											name="projectedStartDate"
											value={states.projectedStartDate}
											onChange={handleChange}
										>
											<option value="">Select Projected Start Date</option>
											{projectStartDateConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Project Term </span>
							<div className="flex flex-row">
								{editpt.level === '0' && editpt.type === 'pt' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'pt')} />
										<span className="text-md">
											{records.projectTerm
												? addCommas(records.projectTerm)
												: ''}{' '}
											{records.projectTermDurationType
												? SOWEnum.ProjectDurationTermLabel.get(
														records.projectTermDurationType
												  )
												: ''}{' '}
											-{' '}
											{records.projectTermType
												? SOWEnum.ProjectTermLabel.get(records.projectTermType)
												: ''}
										</span>
									</div>
								)}
								{editpt.level === '1' && editpt.type === 'pt' && (
									<>
										<CheckMark onClick={() => editField('0', 'pt', true)} />
										<XCancel editField={() => editField('0', 'pt')} />
									</>
								)}
								{editpt.level === '1' && editpt.type === 'pt' && (
									<div className="flex flex-row mt-1">
										<select
											className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
											name="projectTerm"
											value={states.projectTerm}
											onChange={handleChange}
										>
											<option value="">Select</option>
											{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
												(item, i) => (
													<option key={i} value={item}>
														{item}{' '}
													</option>
												)
											)}
										</select>
										<select
											className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
											name="projectTermDurationType"
											value={states.projectTermDurationType}
											onChange={handleChange}
										>
											<option value="">Select</option>
											{projectTermDurationTypeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
										<select
											className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
											name="projectTermType"
											value={states.projectTermType}
											onChange={handleChange}
										>
											<option value="">Select</option>
											{projectTermTypeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Inbound Mode</span>
							<div className="flex flex-row">
								{editim.level === '0' && editim.type === 'im' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'im')} />
										<span className="text-md">
											{records.inboundMode
												? SOWEnum.InBoundModeLabel.get(records.inboundMode)
												: '-'}
										</span>
									</div>
								)}
								{editim.level === '1' && editim.type === 'im' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'im', true)} />
										<XCancel editField={() => editField('0', 'im')} />
										<select
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											name="inboundMode"
											value={states.inboundMode}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select InBound Mode</option>
											{inboundModeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Outbound Mode</span>
							<div className="flex flex-row">
								{editom.level === '0' && editom.type === 'om' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'om')} />
										<span className="text-md">
											{records.outboundMode
												? SOWEnum.OutBoundModeLabel.get(records.outboundMode)
												: '-'}
										</span>
									</div>
								)}
								{editom.level === '1' && editom.type === 'om' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'om', true)} />
										<XCancel editField={() => editField('0', 'om')} />
										<select
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											name="outboundMode"
											value={states.outboundMode}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Select OutBound Mode</option>
											{outboundModeConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
						<div className="group hover:bg-gray-100 flex flex-row justify-between mb-2 border-gray-500 border-b pb-2">
							<span className="text-gray-500">Transportation Assistance</span>
							<div className="flex flex-row">
								{editta.level === '0' && editta.type === 'ta' && (
									<div className="flex flex-row">
										<PencilMark onClick={() => editField('1', 'ta')} />
										<span className="text-md group-hover:text-slate-900">
											{records.transportationAssistance
												? SOWEnum.TransportationAssistancesLabel.get(
														records.transportationAssistance
												  )
												: '-'}
										</span>
									</div>
								)}
								{editta.level === '1' && editta.type === 'ta' && (
									<div className="flex flex-row mt-1">
										<CheckMark onClick={() => editField('0', 'ta', true)} />
										<XCancel editField={() => editField('0', 'ta')} />
										<select
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											name="transportationAssistance"
											value={states.transportationAssistance}
											onChange={(e) => {
												handleChange(e);
											}}
										>
											<option value="">Transportation Assistance</option>
											{transportationAssistanceConst.map((item, i) => (
												<option key={i} value={item.value}>
													{item.label}{' '}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</Transition>
	);
}
