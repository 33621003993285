// import {useHistory, useLocation} from 'react-router-dom';
import Breadcrumb from './breadCrumb';
import FilterHeader from './filterHeader';

export default function HeaderBox(props) {
	const {
		selectedIndex,
		subscribedUser,
		setCurrent,
		manageScope,
		closeScope,
		setManageScope,
	} = props;
	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full grid-flow-row grid-row-12 grid md:grid-flow-col gap-4">
				{subscribedUser === '400' ? (
					<div className="flex flex-row justify-between w-full">
						<Breadcrumb selectedIndex={selectedIndex} />
					</div>
				) : null}
				{subscribedUser === '200' && (
					<FilterHeader
						setCurrent={setCurrent}
						manageScope={manageScope}
						closeScope={closeScope}
						setManageScope={setManageScope}
					/>
				)}
			</div>
		</div>
	);
}
