import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../components/Modules/Dashboard/dashboard";
import AuthLogin from "../components/Modules/AuthPage";
import PrivateRoute from "./privateRoute";
import Contracts from "../components/Modules/Contracts";
import WareHouseList from "../components/Modules/myWareHouse";
import WarehouseDetail from "../components/Modules/warehouseDetail";
import AddWarehouse from "../components/Modules/addWarehouse";
import ShipperDashboard from "../components/Modules/shipperDashboard";
import Proposals from "../components/Modules/proposals";
import { Layout } from "../components/Layout";
import AddScope from "../components/Modules/addScope";
import SignUp from "../components/Modules/signUp";
import SignUpPage from "../components/Modules/signUpPage";
import Settings from "../components/Modules/settings";
import Profile from "../components/Modules/profile";
import Forgot from "../components/Modules/forgot";
import Reset from "../components/Modules/reset";
import EditScope from "../components/Modules/editScope";

function RoutesFunc() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path={"/"} component={AuthLogin} />
          <Route exact path={"/signup"} component={SignUpPage} />
          <Route exact path={"/shipper-signup"} component={SignUp} />
          <Route exact path={"/forgot-password"} component={Forgot} />
          <Route exact path={"/reset-password/:id/:token"} component={Reset} />
          <Route exact path={"/supplier-signup"} component={SignUp} />
          <PrivateRoute exact path={"/explore-shippers"} component={Dashboard} />
          <PrivateRoute exact path={"/contracts-and-proposals"} component={Contracts} />
          <PrivateRoute exact path={"/my-warehouses"} component={WareHouseList} />
          <PrivateRoute exact path={"/warehouse/:warehouseId"} component={WarehouseDetail} />
          <PrivateRoute exact path={"/add-warehouse"} component={AddWarehouse} />
          <PrivateRoute exact path={"/explore-warehouses"} component={ShipperDashboard} />
          <PrivateRoute exact path={"/explore-warehouses/:warehouseId"} component={ShipperDashboard} />
          <PrivateRoute exact path={"/settings"} component={Settings} />
          <PrivateRoute exact path={"/your-profile"} component={Profile} />
          <PrivateRoute exact path={"/proposals"} component={Proposals} />
          <PrivateRoute exact path={"/add-scope"} component={AddScope} />
          <PrivateRoute exact path={"/edit-scope/:id"} component={EditScope} />
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    </Router>
  );
}

export const Routes = React.memo(RoutesFunc);
