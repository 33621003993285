import { Fragment, useState } from "react";
import DirectorSteps from "./components/directorSteps";
import Register from "./components/register";
import Verification from "./components/verification";
import { useLocation } from "react-router-dom";
import { findSignUpType } from "../../../utils";
import { useAlert } from "react-alert";

export default function SignUp() {
  const location = useLocation();
  const alert = useAlert();
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const validateRegister = (emailId) => {
    setIndex(1);
    setEmail(emailId);
  };
  const backClick = (e) => {
    e.preventDefault();
    setIndex(0);
  };
  const { pathname } = location;
  return (
    <Fragment>
      <div className="bg-indigo-900 flex flex-col flex-1">
        <div className="flex flex-col items-center">
          <div className="md:container flex flex-col md:flex-row absolute h-full w-full mb-100">
            <div className="md:flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-10 xl:px-10">
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <DirectorSteps index={index} />
              </div>
            </div>
            {index === 0 ? <Register validateRegister={validateRegister} type={findSignUpType(pathname)} alert={alert} /> : <Verification backClick={backClick} email={email} type={findSignUpType(pathname)} alert={alert} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
