import { useEffect, useState } from "react";
import { ArrowSmRightIcon, ArrowSmLeftIcon } from "@heroicons/react/outline";
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';

import WmsCompanies from "../../../../utils/wmsLists.json";

export default function OptionSection({ state, handleRadioButtonChange, validateBack, validateOption, stepFourFrom, handleChange, wmsComp }) {
  const options = WmsCompanies;

  const [selected, setSelected] = useState([]);
  const [wmscompName, setWmscompName] = useState("");

  useEffect(() => {
    console.log("WMS Companies", wmscompName);
  }, [wmscompName]);

  return (
    <div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div className="col-span-6 md:col-span-6">
        <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
          Do you have a WMS?
        </label>
        <div className="mt-1">
          <label className="container">
            Yes
            <input className="ml-2" type="radio" name="wmsAvailable" value={true} onChange={handleRadioButtonChange} checked={state.wmsAvailable !== null && state.wmsAvailable ? true : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="form_check_radio">
          <label className="container">
            No
            <input className="ml-2" type="radio" name="wmsAvailable" value={false} onChange={handleRadioButtonChange} checked={state.wmsAvailable !== null ? (state.wmsAvailable ? false : true) : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        {state.wmsAvailable === true && (
          <div className="col-span-6 md:col-span-6 mt-6">
            <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
              Which WMS do you have?
            </label>
            {/* <MultiSelect className="shadow-sm mt-2 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10" options={options} value={selected} onChange={setSelected} hasSelectAll={false} labelledBy="Select" /> */}
            <Select isClearable className="ring-0" options={options} onChange={wmsComp} classNamePrefix="remove_boxSdow"/>
          </div>
        )}
        {stepFourFrom && state.wmsAvailable === null && <div className="block text-sm font-medium text-red-500">Please select at least one option</div>}
      </div>

      <div className="col-span-6 md:col-span-6">
        <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
          Does your WMS support Integrations?
        </label>
        <div className="mt-1">
          <label className="container">
            Yes
            <input className="ml-2" type="radio" name="wmsSupportIntrgration" value={true} onChange={handleRadioButtonChange} checked={state.wmsSupportIntrgration !== null && state.wmsSupportIntrgration ? true : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="form_check_radio">
          <label className="container">
            No
            <input className="ml-2" type="radio" name="wmsSupportIntrgration" value={false} onChange={handleRadioButtonChange} checked={state.wmsSupportIntrgration !== null ? (state.wmsSupportIntrgration ? false : true) : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        {stepFourFrom && state.wmsSupportIntrgration === null && <div className="block text-sm font-medium text-red-500">Please select at least one option</div>}
      </div>

      {/* {state.wmsSupportIntrgration === true && (
				<div className="col-span-6 md:col-span-6">
					<label
						htmlFor="productDimensions"
						className="block text-sm font-medium text-gray-700"
					>
						Describe any prebuilt intergrations?
					</label>
					<input
						className="shadow-sm mt-2 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
						type="text"
						name="prebuildIntegration"
						value={state.prebuildIntegration}
						onChange={handleChange}
						//   checked={state.wmsSupportIntrgration !== null && state.wmsSupportIntrgration ? true : false}
					/>
				</div>
			)} */}

      <div className="col-span-6 md:col-span-6">
        <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
          Can operate in alternate systems (WMS / OMS)?
        </label>
        <div className="mt-1">
          <label className="container">
            Yes
            <input className="ml-2" type="radio" name="alternateWMSOMSOperate" value={true} onChange={handleRadioButtonChange} className="ml-2" checked={state.alternateWMSOMSOperate !== null && state.alternateWMSOMSOperate ? true : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="form_check_radio">
          <label className="container">
            No
            <input className="ml-2" type="radio" name="alternateWMSOMSOperate" value={false} onChange={handleRadioButtonChange} checked={state.alternateWMSOMSOperate !== null ? (state.alternateWMSOMSOperate ? false : true) : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        {stepFourFrom && state.alternateWMSOMSOperate === null && <div className="block text-sm font-medium text-red-500">Please select at least one option</div>}
      </div>

      <div className="col-span-6 md:col-span-6">
        <label htmlFor="productDimensions" className="block text-sm font-medium text-gray-700">
          Are you familiar with eComm Fulfillment?
        </label>
        <div className="mt-1">
          <label className="container">
            Yes
            <input className="ml-2" type="radio" name="ecomFulfilmentFimiliar" value={true} onChange={handleRadioButtonChange} checked={state.ecomFulfilmentFimiliar !== null && state.ecomFulfilmentFimiliar ? true : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="form_check_radio">
          <label className="container">
            No
            <input className="ml-2" type="radio" name="ecomFulfilmentFimiliar" value={false} onChange={handleRadioButtonChange} checked={state.ecomFulfilmentFimiliar !== null ? (state.ecomFulfilmentFimiliar ? false : true) : false} />
            <span className="checkmark"></span>
          </label>
        </div>
        {stepFourFrom && state.ecomFulfilmentFimiliar === null && <div className="block text-sm font-medium text-red-500">Please select at least one option</div>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="grid grid-cols-4">
          <button type="button" onClick={(e) => validateBack(e, 2)} className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4">
            <ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
            Previous
          </button>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="grid grid-cols-4">
          <button type="button" onClick={(e) => validateOption(e)} className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4">
            Continue
            <ArrowSmRightIcon className="h-6 w-6 mr-1" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
