import {XCircleIcon} from '@heroicons/react/outline';
export default function XCancel({editField}) {
	return (
		<>
			<XCircleIcon
				className="h-8 w-8 mr-2 mx-auto self-center cursor-pointer"
				aria-hidden="true"
				onClick={editField}
			/>
		</>
	);
}
