export default function warehouseSearch({setQuery, history, deleteWareHouse}) {
	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full grid md:grid-flow-col space-x-96">
				{/* <input
					type="text"
					className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
					placeholder="Search My Warehouses"
				/> */}
				<input
					id="search"
					name="search"
					className="p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md h-10"
					placeholder="Search My Warehouses"
					type="search"
					onChange={(event) => setQuery(event.target.value)}
				/>
				<div className={'flex flex-row-reverse space-x-reverse'}>
					<button
						type="button"
						onClick={() => history.push('/add-warehouse')}
						className="flex flex-row items-center text-gray-500 bg-gray-300 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 mr-2"
					>
						Add
					</button>
				</div>
			</div>
		</div>
	);
}
