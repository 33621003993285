import {Fragment, useState} from 'react';
import {
	ArrowCircleLeftIcon,
	ArrowCircleRightIcon,
} from '@heroicons/react/outline';
import {Pannellum} from 'pannellum-react';
import ReactPannellum, {getConfig} from 'react-pannellum';

export default function Slider3DPreview({images, index = null}) {
	const config = {
		autoRotate: -2,
		autoLoad: true,
		loadingLabel: 'Loading...',
		showControls: false,
		uiText: {bylineLabel: ''},
	};
	const [showModal, setShowModal] = useState(false);
	const imagesLength = images.length;
	let imag = [...images];
	const [activeIndex, setActiveIndex] = useState(index ? index : 0);
	const nextTick = (e) => {
		e.preventDefault();
		if (activeIndex < imagesLength - 1) {
			imag = [...images];
			setActiveIndex(activeIndex + 1);
		}
	};
	const prevTick = (e) => {
		e.preventDefault();
		if (activeIndex !== 0) {
			imag = [...images];
			setActiveIndex(activeIndex - 1);
		}
	};

	return (
		<div className="relative group overflow-hidden rounded-xl h-full bg-gray-200">
			{/* <ArrowCircleLeftIcon
				className={`absolute my-auto h-10 w-10 bg-black rounded-full shadow-lg z-10 top-0 bottom-0 ${
					activeIndex === 0 && 'hidden'
				} left-4 bg-opacity-40 text-white`}
				aria-hidden="true"
				onClick={prevTick}
			/>
			<ArrowCircleRightIcon
				className={`absolute my-auto h-10 w-10 bg-black rounded-full shadow-lg z-10 top-0 bottom-0 ${
					activeIndex === imagesLength - 1 && 'hidden'
				} right-4 bg-opacity-40 text-white`}
				aria-hidden="true"
				onClick={nextTick}
			/> */}
			{imag.map((v, i) => {
				return (
					<Fragment key={i}>
						<div
							className={`absolute block h-full w-full ${
								activeIndex === i ? '' : 'hidden'
							}`}
						>
							<ReactPannellum
								style={{display: 'flex', width: '100%', height: '100%'}}
								id={i.toString()}
								sceneId="firstScene"
								imageSource={`${v.photoSpheresImage}`}
								config={config}
							/>
							{/* <Pannellum
								width="100%"
								height="100%"
								image={`${v.photoSpheresImage}`}
								pitch={10}
								yaw={180}
								hfov={110}
								autoLoad
								showZoomCtrl={false}
							>
								<Pannellum.Hotspot
									type="custom"
									pitch={12.41}
									yaw={117.76}
									handleClick={(evt, name) => 
//
									name="image info"
								/>
							</Pannellum> */}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
}
