import {Fragment} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {getAccessTokenFromLocalStorage, isUserAuthenticated} from '../utils';

const PrivateRoute = ({component, ...rest}) => {
	const isAuthUser = isUserAuthenticated(getAccessTokenFromLocalStorage());
	return (
		<Fragment>
			{isAuthUser ? (
				<Route exact {...rest} component={component} />
			) : (
				<Redirect to="/" />
			)}
		</Fragment>
	);
};
export default PrivateRoute;
