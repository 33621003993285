import {Fragment, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {getsowbyidShipper} from '../../../actions';

export default function EditScope() {
	const {id} = useParams();

	useMemo(() => {
		fetchDataScope(id);
	}, [id]);
	function fetchDataScope(scopeId) {
		getsowbyidShipper(scopeId)
			.then((resp) => {
				
//
			})
			.catch((err) => {
				console.error(err.message);
			});
	}
	return (
		<Fragment>
			<div>Edit Scope</div>
		</Fragment>
	);
}
