/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, Fragment} from 'react';
import {
	getSupplierWarehouse,
} from '../../../actions';
import ScopeTable from './components/scopeTable';
import {
	getAccessTokenFromLocalStorage,
	arrayGetValueFilters,
	GOOGLE_API_KEY,
} from '../../../utils';
import ExploreShipperHeader from './exploreShipperHeader';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import {startDateConstant} from '../../../constants';
import {getConnection} from './signalR';
import Loader from '../CommonModules/loader';
import RestrictedHeader from './components/restrictedHeader';
import OnBoarding from './components/onBoarding';
import {useHistory} from 'react-router-dom';
import {useWarehouseStore} from "../../../store/index";

export default function DashBoard() {
	const history = useHistory();
	const valueFromLocalStorage = localStorage.getItem('filters');
	const {exploreShippers, loading, warehouses, listExploreShippers, listContracts, listWarehouses} = useWarehouseStore();
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters('', 'facilityCertifications')
	);
	// eslint-disable-next-line no-unused-vars
	const [jsonList, setJsonList] = useState(JSON.parse(valueFromLocalStorage));
	const [requiredSqaureFootageFormatted, setRequiredSqaureFootageFormatted] =
		useState('');
	const [requiredSqaureFootage, setRequiredSqaureFootage] = useState('');
	const [searchLocation, setSearchLocation] = useState('');
	const [location, setLocation] = useState('');
	const [lat, setLat] = useState(0);
	const [long, setLong] = useState(0);
	const [isFilterApply, setIsFilterApply] = useState(false);

	useEffect(() => {
		const token = getAccessTokenFromLocalStorage();
		if (token) {
			listWarehouses();
			handleLiveRecords(token);
			handleSearchFilter();
			listContracts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLiveRecords = (token) => {
		let connection = getConnection(token);
		connection.on('NewSOW', (data) => {
			if (data) {
				const jsonData = JSON.parse(data);
				if (isFilterApply) {
					let facilityCertifications = [];
					if (facilityList.length > 0) {
						facilityCertifications = facilityList.map((ele) => {
							return ele.value;
						});
					}
					let filters = {
						Location: location ? location : null,
						Latitude: lat,
						Longitude: long,
						RequiredSqaureFootage:
							requiredSqaureFootage && requiredSqaureFootage > 0
								? parseFloat(requiredSqaureFootage)
								: 0,
						FacilityCertifications:
							facilityCertifications && facilityCertifications.length > 0
								? facilityCertifications.join(',')
								: null,
						StartDate: '0001-01-01',
					};
					listExploreShippers(filters);
				} else {
					
//
					// this.props.addShipperRequest([jsonData]);
				}
			}
		});
	};

	const handleAddressSelect = (place) => {
		if (place && place.formatted_address) {
			const address = place.formatted_address,
				latValue = place.geometry.location.lat(),
				lngValue = place.geometry.location.lng();
			setLocation(address);
			setSearchLocation('');
			setLat(latValue);
			setLong(lngValue);
			setIsFilterApply(true);
		} else {
			setLat(0);
			setLong(0);
			setLocation(null);
			setSearchLocation('');
			setIsFilterApply(false);
		}
	};

	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};
	const handleSearchFilter = () => {
		let facilityCertifications = [];
		if (facilityList && facilityList.length > 0) {
			facilityCertifications = facilityList.map((ele) => {
				return ele.value;
			});
		}
		const obj = {
			FacilityCertifications:
				facilityCertifications && facilityCertifications.length > 0
					? facilityCertifications.join(',')
					: null,
			Latitude: lat,
			Location: location === '' ? null : location,
			Longitude: long,
			RequiredSqaureFootage:
				requiredSqaureFootage === '' ? 0 : Number(requiredSqaureFootage),
			StartDate: startDateConstant,
		};
		listExploreShippers(obj);
	};

	// selectors
	const subscription = localStorage.getItem('subscription');
	const [premiumBG, setPremiumBG] = useState(subscription === '400' ? 1 : 0);
	const currentRef = (index) => {
		setPremiumBG(index);
	};
	return (
		<Fragment>
			<Loader isLoading={loading} />
			{/* Header section  start here */}
			<header className="shadow-sm bg-gray-200">
				{subscription === '400' && <RestrictedHeader />}

				{subscription === '200' && jsonList ? (
					<ExploreShipperHeader
						searchLocation={searchLocation}
						handleAddressSelect={handleAddressSelect}
						setLocation={setLocation}
						setSearchLocation={setSearchLocation}
						GOOGLE_API_KEY={GOOGLE_API_KEY}
						location={location}
						handleSearchFilter={handleSearchFilter}
						requiredSqaureFootageFormatted={requiredSqaureFootageFormatted}
						setRequiredSqaureFootageFormatted={
							setRequiredSqaureFootageFormatted
						}
						setRequiredSqaureFootage={setRequiredSqaureFootage}
						jsonList={jsonList}
						facilityList={facilityList}
						handleDropdownChangeFacility={handleDropdownChangeFacility}
					/>
				) : null}
			</header>
			{/* Header section hoc  end here */}
			<TableWrapperHoc style={{background: premiumBG ? '#fff0db' : 'white'}}>
				{subscription === '400' && (
					<OnBoarding
						currentIndex={currentRef}
						history={history}
						warehouseList={warehouses}
						subscribedUser={subscription}
						setPremiumBG={setPremiumBG}
					/>
				)}
				{subscription === '200' && (
					<ScopeTable
						list={
							exploreShippers?.hasOwnProperty('shippers')
								? exploreShippers.shippers
								: []
						}
						warehouses={warehouses}
					/>
				)}
			</TableWrapperHoc>
		</Fragment>
	);
}
