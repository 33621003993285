import ReactDOM from "react-dom";
import { Toaster } from 'react-hot-toast';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './assets/fonts/ocr-a-bt.ttf';


import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../src/components/Modules/CommonModules/alertTemplate.jsx";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 8000,
  transition: transitions.FADE,
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Toaster
      toastOptions={{
        style: {
          padding: 0,
        }
      }}
      containerStyle={{
        padding: 0
      }}
      position="top-right"
      reverseOrder={false}
    />
    <App />
  </AlertProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
//
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
