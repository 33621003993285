import {useState} from 'react';
import {Transition} from '@headlessui/react';

import {
	BellIcon,
	CogIcon,
	CreditCardIcon,
	KeyIcon,
	MenuIcon,
	UserCircleIcon,
	ViewGridAddIcon,
	XIcon,
} from '@heroicons/react/outline';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import TableWrapperHoc from '../CommonModules/tableWrapperHoc';
import PaymentSection from './components/paymentSection';

const subNavigation = [
	{name: 'Profile', href: '#', icon: UserCircleIcon, current: 0},
	{name: 'Account', href: '#', icon: CogIcon, current: 1},
	{name: 'Password', href: '#', icon: KeyIcon, current: 2},
	{name: 'Notifications', href: '#', icon: BellIcon, current: 3},
	{name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: 4},
	{name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: 5},
];
const plans = [
	{
		name: 'Startup',
		priceMonthly: 29,
		priceYearly: 290,
		limit: 'Up to 5 active job postings',
	},
	{
		name: 'Business',
		priceMonthly: 99,
		priceYearly: 990,
		limit: 'Up to 25 active job postings',
	},
	{
		name: 'Enterprise',
		priceMonthly: 249,
		priceYearly: 2490,
		limit: 'Unlimited active job postings',
	},
];
const payments = [
	{
		id: 1,
		date: '1/1/2020',
		datetime: '2020-01-01',
		description: 'Business Plan - Annual Billing',
		amount: 'CA$109.00',
		href: '#',
	},
	// More payments...
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Settings() {
	const [tabIndex, setTabIndex] = useState(0);

	return (
		<div className="bg-gray-100 ">
			{/* <main className="max-w-7xl mx-auto pb-10 lg:px-8 h-full ">
				<div className="lg:grid lg:grid-cols-12 lg:gap-x-5"> */}
			<TableWrapperHoc>
				<Tabs
					selectedIndex={tabIndex}
					onSelect={(index) => {
						setTabIndex(index);
					}}
					className="flex h-full w-full"
				>
					<div className="flex flex-1 md:flex-row flex-col h-full w-full rounded-lg overflow-hidden">
						<div className="flex md:max-w-sm w-full h-20 md:h-full">
							<TabList className="overflow-hidden">
								<aside>
									<nav className="space-y-1">
										{subNavigation.map((item, i) => (
											<Tab
												key={item.name}
												className={classNames(
													tabIndex === i
														? 'bg-gray-50 text-indigo-600 hover:bg-white'
														: 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
													'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
												)}
											>
												<item.icon
													className={classNames(
														item.current
															? 'text-indigo-500'
															: 'text-gray-400 group-hover:text-gray-500',
														'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
													)}
													aria-hidden="true"
												/>
												<span className="truncate">{item.name}</span>
											</Tab>
										))}
									</nav>
								</aside>
							</TabList>
						</div>
					</div>
					<TabPanel>
						<Transition
							appear
							show={true}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<PaymentSection />
							</div>
						</Transition>
					</TabPanel>
				</Tabs>
			</TableWrapperHoc>
		</div>
	);
}
