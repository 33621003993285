import { API_BASE_URL } from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';

export const getAllSowsShipper = () => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/Shipper/getallsows`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const scopeCreatedAction = (requestObj) => {
    return new Promise((resolve, reject) => {
        RestClient
          .post(
            `${API_BASE_URL}/Shipper/createsow`,
            JSON.stringify(requestObj)
          )
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};
/**
 * 
 * @param {*} scopeId 
 * @param {*} history 
 * @returns 
 */
export const filterWarehouseByScopeId = (scopeId) => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/Shipper/searchbyscope?ScopeId=${scopeId}`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

/**
 * 
 * @param {*} filterObj 
 * @param {*} history 
 * @returns 
 */
export const filterWarehouseByType = (filterObj) => {
    return new Promise((resolve, reject) => {
        RestClient
          .post(
            `${API_BASE_URL}/Shipper/explorewarehouses`,
            JSON.stringify(filterObj)
          )
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const pairWithWarehouse = (scopeId, warehouseId) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Proposal/SaveProposal`, JSON.stringify({
            "scopeId": scopeId,
            "warehouseId": warehouseId
        })).then((resp) => {
            if (resp.status === 200) {
                resolve(resp)
            } else {
                reject(resp)
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

/**
 *
 * Shipper registeration
 *
 */
export const shipperRegisteration = (requestObj) => {
    return new Promise((resolve, reject) => {
        RestClient
          .post(
            `${API_BASE_URL}/Account/registershipper`,
            JSON.stringify(requestObj)
          )
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
}


/**
 * 
 * @param {*} history 
 * @returns 
 */
export const pairwarehouseList = () => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/api/Shipper/GetMyPairedWarehouse`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const deleteScopeActionMethod = (id) => {
    return new Promise((resolve, reject) => {
        RestClient.delete(`${API_BASE_URL}/api/Shipper/archivesow?id=${id}`).then((resp) => {
            if (resp.status === 200) {
                resolve('true')
            } else {
                reject(resp.data.errors)
            }

        }).catch((err) => {
            reject(err)
        });

    })
}

export const viewScopeDetails = (scopeId) => {
    return new Promise((resolve, reject) => {
        RestClient.get(`${API_BASE_URL}/api/Shipper/getsowbyid?id=${scopeId}`).then((resp) => {
            if (resp.status === 200) {
                resolve(resp)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}

/**
 * duplicate Sow action creator
 * @param {*} scopeId 
 * @param {*} scopeName 
 * @returns 
 */
export const duplicateScope = (scopeId, scopeName) => {
    const obj = {
        "sowId": scopeId,
        "sowName": scopeName
    }

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/api/Shipper/duplicatesow`, obj).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}

export const updateSow = (scopeId, editObj) => {

    return new Promise((resolve, reject) => {
        RestClient.put(`${API_BASE_URL}/api/Shipper/editsow?id=${scopeId}`, editObj).then((resp) => {
            if (resp.status === 200) {
                resolve(resp.data.value)
            } else {
                reject(resp.data.errors)
            }
        }).catch((err) => {
            reject(err)
        });
    });
}