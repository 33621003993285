/* eslint-disable jsx-a11y/anchor-is-valid */
import { addCommas, filterByDate } from "../../../../utils";
import { getsowbyidShipper } from "../../../../actions";
import Modal from "../../CommonModules/modals";
import { Fragment, useState } from "react";
import ScopeModal from "../../CommonModules/modals/scopeModal";
import Lottie from "react-lottie";
import threeDots from "../../../../assets/anim/threedots_pulse.json";
import SubmitProposal from "./submitProposal";
import XDate from "../../CommonModules/xDate";

export default function ScopeTable({ list, warehouses = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [scopes, setScopes] = useState(null);
  const [scopeId, setScopeId] = useState(null);
  const [scopeLoc, setScopeLoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showProposal, setShowProposal] = useState(false);
  const handleScope = (id, location) => {
    setScopes(null);
    setScopeId(id);
    getsowbyidShipper(id)
      .then((resp) => {
        setIsOpen(true);
        setScopeLoc(`${resp.scopeName}${" in "}${location}`);
        setScopes(resp);
        setIsLoading(true);
      })
      .catch((err) => {
        setIsOpen(false);
        setScopes(null);
        setIsLoading(false);
        setScopeLoc(null);
        setScopeId(null);
      });
  };
  const closeModal = (val) => {
    setIsOpen(val);
    setIsLoading(false);
    setScopeId(null);
    setScopeLoc(null);
    setShowProposal(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: threeDots,

		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	const changeChild = (val) => {
		if (!showProposal) {
			setShowProposal(val);
		} else {
			
//
		}
	};
	
//
	return (
		<Fragment>
			{isLoading && isOpen && (
				<Modal
					isOpen={isOpen}
					setOpen={closeModal}
					modalTitle={'Scope Information'}
					modalSubTitle={`${scopeLoc}`}
					confirm={true}
					changeChild={changeChild}
					scopeId={scopeId}
				>
					{!showProposal ? (
						<ScopeModal scopes={scopes} />
					) : (
						<SubmitProposal
							list={warehouses}
							scopeId={scopeId}
							setShowProposal={setShowProposal}
							closeModal={closeModal}
						/>
					)}
				</Modal>
			)}
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							{' '}
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Shipper Perferred Location
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Date
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Space Need (Sqft)
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Required Facility Type
										</th>
										<th
											scope="col"
											className="px-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Scope Details
										</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length
										? filterByDate(list).map((shipper, shipperIdx) => (
												<tr
													key={shipperIdx}
													className={
														shipperIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
													}
												>
													<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
														{shipper.location}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
														<XDate date={shipper.modifiedDate} />
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{shipper.approxRequiredSqaureFootage
															? addCommas(shipper.approxRequiredSqaureFootage)
															: '-'}
													</td>
													<td className="px-6 text-sm text-gray-500">
															{shipper.facilityCertificationsValues}
													</td>
													<td className="px-6 py-4 text-sm text-gray-500 text-right">
														{!isOpen && scopeId === shipper.id ? (
															<Lottie
																isClickToPauseDisabled={true}
																height={24}
																width={90}
																options={defaultOptions}
															/>
														) : (
															<button onClick={() => handleScope(shipper.id, shipper.location)} className="bg-indigo-50 rounded-2xl text-indigo-900 px-7 flex cursor-pointer">
																{'View Scope'}
															</button>
														)}
													</td>
												</tr>
										  ))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
