import {Fragment} from 'react';
import {
	UploadIcon,
	ArrowSmRightIcon,
	ArrowSmLeftIcon,
	TrashIcon,
} from '@heroicons/react/outline';
import SpinnerLottie from '../../CommonModules/spinnerLottie';

export default function PhotoSection({
	handleImageFile,
	validateBack,
	onFileDelete,
	warehouseImages,
	completeWarehouse,
	finalStep,
}) {
	return (
		<Fragment>
			<div className="ml-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
				<div className="col-span-6 sm:col-span-6">
					<label
						className={`inline-flex items-end text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
					>
						<UploadIcon
							className="flex h-4 w-auto mr-2 mx-auto self-center"
							aria-hidden="true"
						/>
						<div className="flex flex-col w-auto h-full ml-2 justify-center">
							Upload images
						</div>
						<input
							type="file"
							onChange={handleImageFile}
							multiple
							className="hidden"
							accept="image/*"
						/>
					</label>
				</div>

				<div className="col-span-6 sm:col-span-3">
					<div className="grid grid-cols-4">
						<button
							type="button"
							onClick={(e) => validateBack(e, 3)}
							className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
						>
							<ArrowSmLeftIcon className="h-6 w-6 mr-1" aria-hidden="true" />
							Previous
						</button>
					</div>
				</div>
				<div className="col-span-6 sm:col-span-3">
					<div className="grid grid-cols-4">
						<button
							type="button"
							onClick={(e) => completeWarehouse(e)}
							className="flex flex-row items-center text-gray-800 bg-yellow-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md h-10 col-span-4"
						>
							{finalStep ? (
								<SpinnerLottie isOpen={true} />
							) : (
								<>
									Complete
									<ArrowSmRightIcon
										className="h-6 w-6 mr-1"
										aria-hidden="true"
									/>
								</>
							)}
						</button>
					</div>
				</div>
				<div className="py-4 sm:py-5 w-full col-span-6">
					<dd className="gap-4 flex flex-wrap w-full flex-row">
						{warehouseImages && warehouseImages.length > 0
							? warehouseImages.map((v, i) => {
									return (
										<Fragment key={i}>
											{v.isDeleted === false && (
												<div className="group relative overflow-hidden rounded-md w-24 h-24 bg-gray-400 shadow-md">
													<img
														alt={''}
														src={v.imageURL}
														className="h-full w-full"
														style={{objectFit: 'cover'}}
													/>
													<div
														className="absolute flex rounded-md h-8 w-auto bg-red-600 right-0 bottom-0 left-0 justify-center mx-2 mb-2 cursor-pointer z-10 opacity-0 hover:bg-red-900 group-hover:opacity-100"
														onClick={(e) => onFileDelete(i)}
													>
														<div className="text-white flex flex-row items-center justify-center">
															<TrashIcon
																className="flex h-4 w-4 mr-1 mx-auto self-center"
																aria-hidden="true"
															/>
															<span className="text-sm">Delete</span>
														</div>
													</div>
												</div>
											)}
										</Fragment>
									);
							  })
							: null}
					</dd>
				</div>
			</div>
		</Fragment>
	);
}
