/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getWareHouseById, addRemoveService, updateWarehouseDetail, pairWithWarehouse } from "../../../actions";
import HeaderBox from "./components/headerBox";
import { shipper } from "../../../constants";
import TableWrapperHoc from "../CommonModules/tableWrapperHoc";
import { Donut } from "britecharts-react";
import { arrayGetValueFiltersListValues, GetTokenDetails, getAccessTokenFromLocalStorage, arrayGetValueFilters } from "../../../utils";
import { TrashIcon, UploadIcon, PencilIcon } from "@heroicons/react/outline";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import SpinnerLottie from "../CommonModules/spinnerLottie";
import ImageGallery from "./components/imageGallery";
import ImageThreeD from "./components/imageThreeD";
import Modal from "./components/simpleModal";
import Slider from "../CommonModules/slider";
import Loader from "../CommonModules/loader/index";
import { MultiSelect } from "react-multi-select-component";
import NumberFormat from "react-number-format";
import {usePaymentStore} from "../../../store/paymentStore";
import {useShipperStore} from "../../../store/shipperStore";
import {toastAlert} from "../CommonModules/alertTemplate";

export default function WarehouseDetail() {
  const { warehouseId } = useParams();
  const history = useHistory();
  const alert = useAlert();
  const { allSows } = useShipperStore();
  const [warehouseDetail, setWarehouseDetail] = useState({});
  const [shouldShowLoadingState, setShouldShowLoadingState] = useState(true);
  const [warehouseImages, setWarehouseImages] = useState([]);
  const [photoSpheres, setPhotoSpheres] = useState([]);
  const userDetails = GetTokenDetails(getAccessTokenFromLocalStorage());
  const [selectedScope, setSelectedScope] = useState("");
  const [isProcess, setIsProcess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selected360Index, setSelected360Index] = useState(0);
  const [isOpenBigImage, setIsOpenBigImage] = useState(false);
  const [edit, setEdit] = useState(false);
  const valueFromLocalStorage = localStorage.getItem("filters");
  const jsonList = JSON.parse(valueFromLocalStorage);
  const [facilityList, setFacilityList] = useState(arrayGetValueFilters("", "facilityCertifications"));
  const [securityComponents, setSecurityComponents] = useState(arrayGetValueFilters("", "securityComponents"));
  const [storageInfastructure, setStorageInfastructure] = useState(arrayGetValueFilters("", "storageInfrastructures"));
  const [totalSqaureFootage, setTotalSqaureFootage] = useState("");
  const [totalSqaureFootageFormattted, setTotalSqaureFootageFormattted] = useState("");
  const [availableSqaureFootage, setAvailableSqaureFootage] = useState("");
  const [availableSqaureFootageFormattted, setAvailableSqaureFootageFormattted] = useState("");
  const [valueAddedServices, setValueAddedServices] = useState(arrayGetValueFilters("", "valueAddedServices"));
  const [availableSoonSqaureFootage, setAvailableSoonSqaureFootage] = useState("");
  const [availableSoonSqaureFootageFormattted, setAvailableSoonSqaureFootageFormattted] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchWareHouseDetails(warehouseId, history);
    // eslint-disable-next-line no-use-before-define
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, warehouseId]);

  const convertLoopImageObjetArr = (images) => {
    if (images && images.length > 0) {
      const imageObj = [];
      images.forEach((ele, index) => {
        imageObj.push({
          id: ele.id,
          imageURL: ele.imageURL,
          isDeleted: false,
          tempId: index + 1,
          image: null,
        });
      });
      return imageObj;
    }
  };
  const convertThreeDImages = (threeD) => {
    let photoSpheres = [];
    if (threeD && threeD.length > 0) {
      photoSpheres = [];
      threeD.forEach((ele, index) => {
        photoSpheres.push({
          id: ele.id,
          photoSpheresName: ele.viewName,
          photoSpheresImage: ele.viewURL,
          imageType: ele.imageType,
          isDeleted: false,
          tempId: index + 1,
        });
      });
      return photoSpheres;
    }
  };

  const fetchWareHouseDetails = (id, hs) => {
    getWareHouseById(id, hs)
      .then((resp) => {
        setShouldShowLoadingState(false);
        setWarehouseDetail(resp);
        setFacilityList(arrayGetValueFilters(resp.facilityCertifications, "facilityCertifications"));
        setSecurityComponents(arrayGetValueFilters(resp.securityComponents ? resp.securityComponents : "", "securityComponents"));
        setStorageInfastructure(arrayGetValueFilters(resp.storageInfastructure, "storageInfrastructures"));
        let value = [...valueAddedServices];
        value.push(parseInt(resp.valueAddedServices));
        setValueAddedServices([...value]);
        setTotalSqaureFootageFormattted(resp.totalSqaureFootage);
        setAvailableSqaureFootageFormattted(resp.availableSqaureFootage);
        setAvailableSoonSqaureFootageFormattted(resp.availableSoonSqaureFootage);
        setWarehouseImages(resp.images ? convertLoopImageObjetArr(resp.images) : []);
        setPhotoSpheres(resp.photospheres ? convertThreeDImages(resp.photospheres) : []);
      })
      .catch((err) => {
        // setWarehouseDetail(null);
        console.log("err >> ", err);
        setShouldShowLoadingState(true);
      });
  };
  const backToMyWareHouses = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const deleteImageFromClickImage = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("id", warehouseId);
    formData.append("WarehouseName", warehouseDetail.warehouseName);
    formData.append("LocationAddress", warehouseDetail.locationAddress);
    formData.append("City", warehouseDetail.city);
    formData.append("State", warehouseDetail.state);
    formData.append("Country", warehouseDetail.country);
    formData.append("Zipcode", warehouseDetail.zip);
    formData.append("Latitude", warehouseDetail.latitude);
    formData.append("Longitude", warehouseDetail.longitude);
    formData.append("TotalSqaureFootage", parseFloat(warehouseDetail.totalSqaureFootage));
    formData.append("AvailableSqaureFootage", parseFloat(warehouseDetail.availableSqaureFootage));
    formData.append("StorageInfastructure", warehouseDetail.storageInfastructure);
    formData.append("FacilityCertifications", warehouseDetail.facilityList);
    formData.append("SecurityComponents", warehouseDetail.securityComponents);
    formData.append("IndustriesServed", warehouseDetail.industriesServed);
    formData.append("InboundServices", warehouseDetail.inboundServices);
    formData.append("OutboundServices", warehouseDetail.outboundServices);
    formData.append("ValueAddedServices", warehouseDetail.valueAddedServices);
    formData.append("WMSAvailable", warehouseDetail.wmsAvailable);
    formData.append("AvailableSoonSqaureFootage", warehouseDetail.availableSoonSqaureFootage ? parseFloat(warehouseDetail.availableSoonSqaureFootage) : 0);
    formData.append("WMSSupportIntrgration", warehouseDetail.wmsSupportIntrgration);
    formData.append("AlternateWMSOMSOperate", warehouseDetail.alternateWMSOMSOperate);
    formData.append("EcomFulfilmentFimiliar", warehouseDetail.ecomFulfilmentFimiliar);
    formData.append("WmsCompanyName", warehouseDetail.wmsCompanyName);
    if (warehouseDetail.photospheres && warehouseDetail.photospheres.length > 0) {
      console.log("WmsCompanyName >> ", warehouseDetail.wmsCompanyName);
      warehouseDetail.photospheres.forEach((ele, index) => {
        if (ele) {
          formData.append("PhotoSpheres[" + index + "].Id", ele.id);
          formData.append("PhotoSpheres[" + index + "].ViewName", type);
          formData.append("PhotoSpheres[" + index + "].ViewImage", ele.imageURL_Thumb);
          formData.append("PhotoSpheres[" + index + "].ImageType", `2`);
          formData.append("PhotoSpheres[" + index + "].IsDeleted", false);
        }
      });
    }

    if (type === "1" && warehouseImages && warehouseImages.length > 0) {
      let i = 0;
      warehouseImages.forEach((ele, index) => {
        if (ele.id) {
          formData.append("Images[" + i + "].Id", ele.id);
          formData.append("Images[" + i + "].Image", ele.image ? ele.image : ele.imageURL);
          formData.append("Images[" + i + "].IsDeleted", ele.id === id ? true : false);
          i++;
        }
      });
    }
    updateWarehouseDetail(formData, history)
      .then((resp) => {
        setLoading(false);
        fetchWareHouseDetails(warehouseId, history);
      })
      .catch((err) => {
        setLoading(false);

        //
      });
  };

  const deleteImageFromClick = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (type === "2" && photoSpheres && photoSpheres.length > 0) {
      photoSpheres.forEach((ele, index) => {
        if (ele) {
          formData.append("PhotoSpheres[" + index + "].Id", ele.id);
          formData.append("PhotoSpheres[" + index + "].ViewName", type);
          formData.append("PhotoSpheres[" + index + "].ViewImage", ele.photoSpheresImage);
          formData.append("PhotoSpheres[" + index + "].ImageType", `${type}`);
          formData.append("PhotoSpheres[" + index + "].IsDeleted", ele.id === id ? true : false);
        }
      });
    }

    formData.append("WarehouseId", warehouseId);
    addRemoveService(formData, history)
      .then((result) => {
        if (result.data) {
          toastAlert({ title: "Success", discription: result.data }, { type: "success" });
          setLoading(false);
          fetchWareHouseDetails(warehouseId, history);
        } else {
          setLoading(false);
          toastAlert(
            {
              title: "Error",
              discription: result.data ? result.data : "Something went wrong, please try again later.",
            },
            { type: "error" }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toastAlert(
          {
            title: "Error",
            discription: "Server Error, Please try again.",
          },
          { type: "error" }
        );
      });
  };

  const handleImageFile = (e) => {
    e.preventDefault();
    let index = 0;
    let allFiles = [];
    const imagesData = warehouseImages ? warehouseImages : [];
    let lastIndex = 0;
    if (imagesData && imagesData.length > 0) {
      lastIndex = imagesData[imagesData.length - 1].tempId;
    }
    for (let file of e.target.files) {
      if (file.type.includes("image/")) {
        allFiles.push(file);
      }
    }
    var uploadFile = () => {
      if (index < allFiles.length) {
        let file = allFiles[index];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          imagesData.push({
            id: null,
            imageURL: reader.result,
            isDeleted: false,
            tempId: lastIndex + 1,
            image: file,
          });
          lastIndex = lastIndex + 1;
          index++;
          uploadFile();
        };
        reader.onerror = (error) => {
          index++;
          uploadFile();
        };
      } else {
        setWarehouseImages([...imagesData]);
      }
    };
    uploadFile();
    let formData = new FormData();
    formData.append("id", warehouseId);
    formData.append("WarehouseName", warehouseDetail.warehouseName);
    formData.append("LocationAddress", warehouseDetail.locationAddress);
    formData.append("City", warehouseDetail.city);
    formData.append("State", warehouseDetail.state);
    formData.append("Country", warehouseDetail.country);
    formData.append("Zipcode", warehouseDetail.zip);
    formData.append("Latitude", warehouseDetail.latitude);
    formData.append("Longitude", warehouseDetail.longitude);
    formData.append("TotalSqaureFootage", parseFloat(warehouseDetail.totalSqaureFootage));
    formData.append("AvailableSqaureFootage", parseFloat(warehouseDetail.availableSqaureFootage));
    formData.append("StorageInfastructure", warehouseDetail.storageInfastructure);
    formData.append("FacilityCertifications", warehouseDetail.facilityCertifications);
    formData.append("SecurityComponents", warehouseDetail.securityComponents);
    formData.append("IndustriesServed", warehouseDetail.industriesServed);
    formData.append("InboundServices", warehouseDetail.inboundServices);
    formData.append("OutboundServices", warehouseDetail.outboundServices);
    formData.append("ValueAddedServices", warehouseDetail.valueAddedServices);
    formData.append("WMSAvailable", warehouseDetail.wmsAvailable);
    formData.append("AvailableSoonSqaureFootage", warehouseDetail.availableSoonSqaureFootage ? parseFloat(warehouseDetail.availableSoonSqaureFootage) : 0);
    formData.append("WMSSupportIntrgration", warehouseDetail.wmsSupportIntrgration);
    formData.append("AlternateWMSOMSOperate", warehouseDetail.alternateWMSOMSOperate);
    formData.append("EcomFulfilmentFimiliar", warehouseDetail.ecomFulfilmentFimiliar);
    if (warehouseDetail.photospheres && warehouseDetail.photospheres.length > 0) {
      warehouseDetail.photospheres.forEach((ele, index) => {
        if (ele) {
          formData.append("PhotoSpheres[" + index + "].Id", ele.id);
          formData.append("PhotoSpheres[" + index + "].ViewName", "2");
          formData.append("PhotoSpheres[" + index + "].ViewImage", ele.viewURL);
          formData.append("PhotoSpheres[" + index + "].ImageType", `2`);
          formData.append("PhotoSpheres[" + index + "].IsDeleted", false);
        }
      });
    }

    if (allFiles && allFiles.length > 0) {
      allFiles.forEach((ele, index) => {
        if (ele) {
          // formData.append('Images[' + index + '].Id', null);
          formData.append("Images[" + index + "].Image", ele);
          formData.append("Images[" + index + "].IsDeleted", false);
        }
      });
    }
    updateWarehouseDetail(formData, history)
      .then((resp) => {
        fetchWareHouseDetails(warehouseId, history);
      })
      .catch((err) => {
        //
        toastAlert(
          {
            title: "Error",
            discription: "Something went wrong,Please try again later.",
          },
          { type: "error" }
        );
      });
  };
  const handleMultipleFileUplodThree = (e) => {
    e.preventDefault();
    const imagesData = photoSpheres ? photoSpheres : [];
    let lastIndex = 0;

    let index = 0;
    let allFiles = [];

    if (imagesData && imagesData.length > 0) {
      lastIndex = imagesData[imagesData.length - 1].tempId;
    }
    const formData = new FormData();
    for (let file of e.target.files) {
      if (file.type.includes("image/")) {
        allFiles.push(file);
      }
    }

    var uploadFile = () => {
      if (index < allFiles.length) {
        let file = allFiles[index];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          imagesData.push({
            id: null,
            photoSpheresName: "1",
            imageType: "2",
            photoSpheresImage: reader.result,
            isDeleted: false,
            tempId: lastIndex + 1,
          });
          lastIndex = lastIndex + 1;
          index++;
          uploadFile();
        };
        reader.onerror = (error) => {
          index++;
          uploadFile();
        };
      } else {
        setPhotoSpheres([...imagesData]);
      }
    };
    uploadFile();
    if (allFiles && allFiles.length > 0) {
      let i = 0;
      allFiles.forEach((ele, index) => {
        if (ele) {
          // formData.append('PhotoSpheres[' + i + '].Id', null);
          formData.append("PhotoSpheres[" + i + "].ViewName", "1");
          formData.append("PhotoSpheres[" + i + "].ViewImage", ele);
          formData.append("PhotoSpheres[" + i + "].ImageType", `${"2"}`);
          formData.append("PhotoSpheres[" + i + "].IsDeleted", false);
          i++;
        }
      });
    }
    formData.append("WarehouseId", warehouseId);
    addRemoveService(formData, history)
      .then((result) => {
        if (result.data) {
          toastAlert({ title: "Success", discription: result.data }, { type: "success" });
          fetchWareHouseDetails(warehouseId, history);
        } else {
          toastAlert(
            {
              title: "Error",
              discription: result.data ? result.data : "Something went wrong, please try again later.",
            },
            { type: "error" }
          );
        }
      })
      .catch((err) => {
        toastAlert(
          {
            title: "Error",
            discription: "Server Error, Please try again.",
          },
          { type: "error" }
        );
      });
  };
  const handleChangeScopeName = (e) => {
    e.preventDefault();
    setSelectedScope(e.target.value);
  };
  const handlePairCode = (e) => {
    e.preventDefault();
    setIsProcess(true);
    if (selectedScope !== "") {
      pairWithWarehouse(selectedScope, warehouseId)
        .then((resp) => {
          setIsProcess(false);

          if (resp.status === 200) {
            toastAlert({ title: "Success", discription: resp.data }, { type: "success" });
            setTimeout(() => {
              history.push("/explore-warehouses");
            }, 2000);
          } else {
            toastAlert(
              {
                title: "Error",
                discription: resp.data,
              },
              { type: "error" }
            );
          }
        })
        .catch((err) => {
          setIsProcess(false);
          toastAlert(
            {
              title: "Error",
              discription: "Server Error, Please try again.",
            },
            { type: "error" }
          );
        });
    } else {
      setIsProcess(false);
      toastAlert(
        {
          title: "Error",
          discription: "Please select the scope.",
        },
        { type: "error" }
      );
    }
  };
  const closeModal = (v) => {
    setIsOpen(false);
    setIsOpenBigImage(false);
  };
  const openModal = (id, v) => {
    setSelectedIndex(id);
    setIsOpen(v);
  };
  const open360Modal = (id, v) => {
    setSelected360Index(id);
    setIsOpenBigImage(v);
  };
  const enableEdit = (e) => {
    e.preventDefault();
    setEdit(true);
  };
  const handleDropdownChangeFacility = (e) => {
    setFacilityList(e);
  };
  const handleDropdownChangeSecurity = (e) => {
    setSecurityComponents(e);
  };
  const handleDropdownChangeStorage = (e) => {
    setStorageInfastructure(e);
  };
  const handleCheckBoxChangeValue = (e) => {
    let value = [...valueAddedServices];
    if (e.target.checked) {
      value.push(parseInt(e.target.value));
      setValueAddedServices([...value]);
    } else {
      value = value.filter((item) => item !== parseInt(e.target.value));
      setValueAddedServices([...value]);
    }
  };
  const updateWarehouse = (e) => {
    e.preventDefault();
    setUploading(true);
    let formData = new FormData();
    formData.append("id", warehouseId);
    formData.append("WarehouseName", warehouseDetail.warehouseName);
    formData.append("LocationAddress", warehouseDetail.locationAddress);
    formData.append("City", warehouseDetail.city);
    formData.append("State", warehouseDetail.state);
    formData.append("Country", warehouseDetail.country);
    formData.append("Zipcode", warehouseDetail.zip);
    formData.append("Latitude", warehouseDetail.latitude);
    formData.append("Longitude", warehouseDetail.longitude);
    formData.append("TotalSqaureFootage", totalSqaureFootage ? parseFloat(totalSqaureFootage) : parseFloat(warehouseDetail.totalSqaureFootage));
    formData.append("AvailableSoonSqaureFootage", availableSoonSqaureFootage ? parseFloat(availableSoonSqaureFootage) : parseFloat(warehouseDetail.availableSoonSqaureFootage));
    formData.append("AvailableSqaureFootage", availableSqaureFootage ? parseFloat(availableSqaureFootage) : parseFloat(warehouseDetail.availableSqaureFootage));
    formData.append(
      "StorageInfastructure",
      storageInfastructure
        .map((ele) => {
          return ele.value;
        })
        .join(",")
    );
    formData.append(
      "FacilityCertifications",
      facilityList.length
        ? facilityList
            .map((ele) => {
              return ele.value;
            })
            .join(",")
        : warehouseDetail.facilityCertifications
    );
    formData.append(
      "SecurityComponents",
      securityComponents
        .map((ele) => {
          return ele.value;
        })
        .join(",")
    );
    formData.append("IndustriesServed", warehouseDetail.industriesServed);
    formData.append("InboundServices", warehouseDetail.inboundServices);
    formData.append("OutboundServices", warehouseDetail.outboundServices);
    formData.append("ValueAddedServices", valueAddedServices.join(","));
    formData.append("WMSAvailable", warehouseDetail.wmsAvailable);
    formData.append("WMSSupportIntrgration", warehouseDetail.wmsSupportIntrgration);
    formData.append("AlternateWMSOMSOperate", warehouseDetail.alternateWMSOMSOperate);
    formData.append("EcomFulfilmentFimiliar", warehouseDetail.ecomFulfilmentFimiliar);
    formData.append("WmsCompanyName", warehouseDetail.wmsCompanyName);
    if (warehouseDetail.photospheres && warehouseDetail.photospheres.length > 0) {
      warehouseDetail.photospheres.forEach((ele, index) => {
        if (ele) {
          formData.append("PhotoSpheres[" + index + "].Id", ele.id);
          formData.append("PhotoSpheres[" + index + "].ViewName", "2");
          formData.append("PhotoSpheres[" + index + "].ViewImage", ele.viewURL);
          formData.append("PhotoSpheres[" + index + "].ImageType", `2`);
          formData.append("PhotoSpheres[" + index + "].IsDeleted", false);
        }
      });
    }

    if (warehouseImages && warehouseImages.length > 0) {
      let i = 0;
      warehouseImages.forEach((ele, index) => {
        if (ele.id) {
          formData.append("Images[" + i + "].Id", ele.id);
          formData.append("Images[" + i + "].Image", ele.image ? ele.image : ele.imageURL);
          formData.append("Images[" + i + "].IsDeleted", false);
          i++;
        }
      });
    }
    updateWarehouseDetail(formData, history)
      .then((resp) => {
        setUploading(false);
        setEdit(false);
        fetchWareHouseDetails(warehouseId, history);
      })
      .catch((err) => {
        //
        setUploading(false);
        setEdit(false);
        toastAlert(
          {
            title: "Error",
            discription: "Something went wrong,Please try again later.",
          },
          { type: "error" }
        );
      });
  };
  useEffect(() => {
    const handle = setInterval(() => {
      fetchWareHouseDetails(warehouseId, history);
    }, 1400000);

    return () => {
      clearInterval(handle);
    };
  }, []);
  //
  //
  return (
    <Fragment>
      {isOpen && (
        <ImageGallery isOpen={isOpen} setOpen={closeModal} modalTitle={"Gallery"}>
          <Slider images={warehouseImages && warehouseImages.length > 0 ? warehouseImages : []} index={selectedIndex} />
        </ImageGallery>
      )}
      {isOpenBigImage && (
        <Modal open={isOpenBigImage} setOpen={closeModal}>
          <ImageThreeD images={photoSpheres && photoSpheres.length > 0 ? photoSpheres : []} index={selected360Index} />
        </Modal>
      )}
      <Loader isLoading={loading} />
      <header className="shadow-sm bg-gray-200">
        <HeaderBox backToMyWareHouses={backToMyWareHouses} />
      </header>
      {warehouseDetail ? (
        <TableWrapperHoc>
          <div className="flex flex-col px-4 md:px-0 md:flex-row">
            <div className="flex justify-center donut-container mb-5 flex-col">
              <Donut
                shouldShowLoadingState={shouldShowLoadingState}
                width={280}
                height={280}
                internalRadius={70}
                highlightSliceById={2} //1
                hasFixedHighlightedSlice={true}
                data={[
                  {
                    quantity: warehouseDetail && warehouseDetail.totalSqaureFootage - warehouseDetail.availableSqaureFootage,
                    name: "Unavailable Space", //Total Available Space
                    id: 1,
                  },
                  {
                    quantity: warehouseDetail && warehouseDetail.availableSqaureFootage,
                    name: "Total Available Space", //Unavailable Space
                    id: 2,
                  },
                  {
                    quantity: warehouseDetail && warehouseDetail.availableSoonSqaureFootage,
                    name: "Available Soon",
                    id: 3,
                  },
                ]}
              />
            </div>
            <div className="md:ml-20">
              <div>
                <div className="flex">
                  <h3 style={{ fontFamily: "ocr-a-bt, sans-serif" }} className="text-lg leading-6 font-medium text-gray-900">
                    {`POP ID: ${warehouseDetail && warehouseDetail.id ? warehouseDetail.id.split("-")[0] : ""}`}
                  </h3>
                  {userDetails && userDetails.actort === shipper ? null : <PencilIcon className="flex h-4 w-4 mr-2 mx-auto self-center cursor-pointer" aria-hidden="true" onClick={enableEdit} />}
                </div>

                {
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {`${(warehouseDetail && warehouseDetail.city) || ""}`}
                    {warehouseDetail && warehouseDetail.city ? "," : ""} {`${(warehouseDetail && warehouseDetail.country) || ""}`}
                  </p>
                }
              </div>
              <div className="mt-5 border-t border-gray-200">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Total Space (sqft)</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.totalSqaureFootage ? warehouseDetail.totalSqaureFootage.toLocaleString("en-US") : ""}
                      {edit && (
                        <NumberFormat
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          autoFocus
                          value={totalSqaureFootageFormattted}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setTotalSqaureFootageFormattted(formattedValue);
                            setTotalSqaureFootage(value);
                          }}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Available Space (sqft)</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.availableSqaureFootage ? warehouseDetail.availableSqaureFootage.toLocaleString("en-US") : ""}
                      {edit && (
                        <NumberFormat
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Approx Sq. Ft. Required"
                          value={availableSqaureFootageFormattted}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setAvailableSqaureFootageFormattted(formattedValue);
                            setAvailableSqaureFootage(value);
                          }}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Available Soon Square Footage</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.availableSoonSqaureFootage ? warehouseDetail.availableSoonSqaureFootage.toLocaleString("en-US") : ""}
                      {edit && (
                        <NumberFormat
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Approx Sq. Ft. Required"
                          value={availableSoonSqaureFootageFormattted}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setAvailableSoonSqaureFootageFormattted(formattedValue);
                            setAvailableSoonSqaureFootage(value);
                          }}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Storage Infrastructure</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.storageInfastructure ? arrayGetValueFiltersListValues(warehouseDetail.storageInfastructure, "storageInfrastructures") : ""}
                      {edit && (
                        <MultiSelect
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
                          options={jsonList && jsonList.storageInfrastructures}
                          value={storageInfastructure}
                          onChange={handleDropdownChangeStorage}
                          labelledBy={"Storage Infrastructure"}
                          overrideStrings={{
                            selectSomeItems: "Storage Infrastructure",
                          }}
                          disableSearch={true}
                          hasSelectAll={false}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Security Components</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.securityComponents ? arrayGetValueFiltersListValues(warehouseDetail.securityComponents, "securityComponents") : ""}
                      {edit && (
                        <MultiSelect
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
                          options={jsonList && jsonList.securityComponents}
                          value={securityComponents}
                          onChange={handleDropdownChangeSecurity}
                          labelledBy={"Security Components"}
                          overrideStrings={{
                            selectSomeItems: "Security Components",
                          }}
                          disableSearch={true}
                          hasSelectAll={false}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Facility Types</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.facilityCertifications ? arrayGetValueFiltersListValues(warehouseDetail.facilityCertifications, "facilityCertifications") : ""}
                      {edit && (
                        <MultiSelect
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 border rounded-md bg-white h-10"
                          options={jsonList && jsonList.facilityCertifications}
                          value={facilityList}
                          onChange={handleDropdownChangeFacility}
                          labelledBy={"Facility Certifications"}
                          overrideStrings={{
                            selectSomeItems: "Facility Certifications",
                          }}
                          disableSearch={true}
                          hasSelectAll={false}
                        />
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Value Added Services</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {!edit && warehouseDetail.valueAddedServices ? arrayGetValueFiltersListValues(warehouseDetail.valueAddedServices, "valueAddedServices") : ""}
                      {edit &&
                        jsonList &&
                        jsonList.valueAddedServices.map((item, index) => (
                          <div className="flex items-center h-5" key={index}>
                            <label className="container">
                              {item.label}
                              <input className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" type="checkbox" value={item.value} name="valueAddedServices" checked={valueAddedServices.indexOf(item.value) >= 0 ? "true" : ""} onChange={handleCheckBoxChangeValue} />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        ))}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">WMS Available</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{!edit && warehouseDetail.wmsAvailable ? "Yes" : "No"}</dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Warehouse is familliar with eComm Fulfillment</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{!edit && warehouseDetail.ecomFulfilmentFimiliar ? "Yes" : "No"}</dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">WMS Technology Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{!edit && warehouseDetail.wmsCompanyName ? warehouseDetail.wmsCompanyName : "Not Listed"}</dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">WMS Supports Integration</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{!edit && warehouseDetail.wmsSupportIntrgration ? "Yes" : "No"}</dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">{userDetails && userDetails.actort === shipper ? "Virtual Tour (360°)" : "Upload Virtual Tour (360°)"}</dt>
                    <dd className="grid grid-flow-row grid-cols-4 gap-4 col-span-2 max-w-md">
                      {photoSpheres && photoSpheres.length > 0
                        ? photoSpheres.map((v, i) => {
                            return (
                              <Fragment key={i}>
                                <div className="group relative overflow-hidden rounded-md w-24 h-24">
                                  <img
                                    alt={""}
                                    src={v.photoSpheresImage}
                                    className=" h-full w-full"
                                    crossOrigin="anonymous"
                                    style={{ objectFit: "cover" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      open360Modal(i, true);
                                    }}
                                  />
                                  {userDetails && userDetails.actort === shipper ? null : (
                                    <div
                                      className={`absolute flex rounded-md h-8 w-auto bg-red-600 right-0 bottom-0 left-0 justify-center mx-2 mb-2 cursor-pointer z-10 opacity-0  group-hover:opacity-100`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        deleteImageFromClick(e, v.id, "2");
                                      }}
                                    >
                                      <div className="text-white flex flex-row items-center justify-center">
                                        <TrashIcon className="flex h-4 w-4 mr-1 mx-auto self-center" aria-hidden="true" />
                                        <span className="text-sm">Delete</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Fragment>
                            );
                          })
                        : null}
                    </dd>
                    {userDetails && userDetails.actort === shipper ? null : (
                      <div className="col-start-2 col-end-3 items-end">
                        <label className={`inline-flex items-end text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                          <UploadIcon className="flex h-4 w-4 mr-2 mx-auto self-center" aria-hidden="true" />
                          <div className="flex flex-col h-full ml-2 justify-center">Upload 360 images</div>
                          <input type="file" name="files" onChange={handleMultipleFileUplodThree} className="hidden" multiple />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">{userDetails && userDetails.actort === shipper ? "Still Images" : "Upload Still Images"}</dt>
                    <dd className="grid grid-flow-row grid-cols-4 gap-4 col-span-2 max-w-md">
                      {warehouseImages && warehouseImages.length > 0
                        ? warehouseImages.map((v, i) => {
                            return (
                              <Fragment key={i}>
                                <div className="group relative overflow-hidden rounded-md w-24 h-24 bg-gray-400">
                                  <img
                                    alt={""}
                                    src={v.imageURL}
                                    crossOrigin="anonymous"
                                    className="h-full w-full"
                                    style={{ objectFit: "cover" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      openModal(i, true);
                                    }}
                                  />
                                  {userDetails && userDetails.actort === shipper ? null : (
                                    <div
                                      className="absolute flex rounded-md h-8 w-auto bg-red-600 right-0 bottom-0 left-0 justify-center mx-2 mb-2 cursor-pointer z-10 opacity-0  group-hover:opacity-100"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        deleteImageFromClickImage(e, v.id, "1");
                                      }}
                                    >
                                      <div className="text-white flex flex-row items-center justify-center">
                                        <TrashIcon className="flex h-4 w-4 mr-1 mx-auto self-center" aria-hidden="true" />
                                        <span className="text-sm">Delete</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Fragment>
                            );
                          })
                        : null}
                    </dd>
                    {userDetails && userDetails.actort === shipper ? null : (
                      <div className="col-start-2 col-end-3 items-end">
                        <label className={`inline-flex items-end text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                          <UploadIcon className="flex h-4 w-4 mr-2 mx-auto self-center" aria-hidden="true" />
                          <div className="flex flex-col h-full ml-2 justify-center">Upload images</div>
                          <input type="file" onChange={handleImageFile} multiple className="hidden" />
                        </label>
                      </div>
                    )}
                  </div>
                  {edit && (
                    <>
                      <div onClick={updateWarehouse} className={"inline-flex items-end text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-6 mb-10 cursor-pointer"}>
                        {uploading ? "Updating" : "Update"}
                      </div>
                      <div onClick={(e) => setEdit(false)} className={"inline-flex items-end text-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"}>
                        Cancel
                      </div>{" "}
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </TableWrapperHoc>
      ) : (
        "Error"
      )}
      {userDetails && userDetails.actort === shipper ? (
        <div className="bg-blue-100 p-4">
          <div className="grid grid-cols-10 gap-4 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="col-span-6">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 rounded-2xl p-3 text-gray-100 bg-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <div className="flex flex-col ml-3">
                  <div className="font-medium leading-none text-gray-900">Did this warehouse catch your eyes?</div>
                  <p className="text-sm text-gray-500 leading-none mt-1">Submit one of your scopes to get a quick quote and get started!</p>
                </div>
              </div>
            </div>
            <select className="col-start-7 col-span-3 p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md h-10" name="scopeName" value={selectedScope} onChange={handleChangeScopeName}>
              <option value={""}>Select Scope</option>
              {allSows.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.scopeName}{" "}
                </option>
              ))}
            </select>
            <button type="button" onClick={(e) => (isProcess ? null : handlePairCode(e))} className="flex flex-row items-center text-white bg-yellow-600 hover:text-opacity-40 px-3 py-2 font-medium text-sm rounded-md h-10 w-30 text-center content-center">
              {isProcess ? <SpinnerLottie isOpen={isProcess} /> : "Send Scope"}
            </button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
