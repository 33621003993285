import moment from 'moment';
/**
 *
 * @param {date} param0
 * @returns
 */
const XDate = ({date}) => {
	const calculateDays = (date) => {
		const current = moment().startOf('day');
		const given = moment(date, 'YYYY-MM-DD');
		const diff = moment.duration(current.diff(given)).asDays();
		if (diff === 0) {
			return (
				<div>
					<span className="bg-green-100 px-3 py-1 rounded-lg text-green-600">New</span>
					<div className="mt-2">{`< 24 Hours`}</div>
				</div>
			);
		} else if (diff > 0) {
			return (
				<div>
					<span>{`${Math.floor(diff)} Days Ago`}</span>
					<br/>
					<span className="text-xs text-gray-700">{`${moment(date).format('MM-DD-YYYY')}`}</span>
				</div>
			);
		}
	};
	return <>{date ? calculateDays(date) : ''}</>;
};
export default XDate;
