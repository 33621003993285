import Geocode from 'react-geocode';
import { GOOGLE_API_KEY } from './index'
Geocode.setApiKey(GOOGLE_API_KEY);

export const getStreetAddress = (addressArray) => {
    let address = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'street_number' === addressArray[i].types[0]) {
            address = address + addressArray[i].long_name + ", ";
        }
        if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
            address = address + addressArray[i].long_name;
        }
    }
    return address;
};

export const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
            city = addressArray[i].long_name;
            return city;
        }
    }
};

export const getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                    area = addressArray[i].long_name;
                    return area;
                }
            }
        }
    }
};

export const getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
            state = addressArray[i].long_name;
            return state;
        }
    }
};

export const getCountry = (addressArray) => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
            country = addressArray[i].long_name;
            return country;
        }
    }
};

export const getZipcode = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
            state = addressArray[i].long_name;
            return state;
        }
    }
};

export const addressFinder = (lat, long) => {
    return new Promise((resolve, reject) => {
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            })
    })
}