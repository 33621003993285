/* This example requires Tailwind CSS v2.0+ */
import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useLocation} from 'react-router-dom';

export default function Modal(props) {
	const {
		isOpen,
		modalTitle,
		modalSubTitle,
		setOpen,
		children,
		confirm = false,
		changeChild = null,
		scopeId = null,
		hideBottom = false,
	} = props;
	const location = useLocation();

	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-10 inset-0 overflow-y-auto"
				open={isOpen}
				onClose={() => setOpen(false)}
			>
				<div className="flex items-end justify-center min-h-screen pt-24 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-200"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{backdropFilter: 'blur(5px)'}}
						/>
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-100"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-100"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="w-full max-w-4xl mx-2 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-6">
							<div className="px-4 py-5 sm:px-6">
								<h3 className="text-lg leading-6 font-medium text-gray-900">
									{modalTitle || ''}
								</h3>
								<p className="mt-1 max-w-2xl text-sm text-gray-500">
									{modalSubTitle || ''}
								</p>
							</div>
							{children}
							<div
								className={`mt-5 sm:mt-6 grid grid-flow-col ${
									hideBottom ? '' : 'grid-cols-4 gap-4'
								}`}
							>
								{hideBottom ? (
									<button
										type="button"
										className="col-start-1 col-end-3 md:col-start-3 inline-flex justify-center w-full rounded-md border-indigo-600 shadow-sm px-4 py-2 text-base font-medium text-indigo-600 border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
										onClick={() => setOpen(false)}
									>
										Cancel
									</button>
								) : (
									<>
										<button
											type="button"
											className="col-start-1 col-end-3 md:col-start-3 inline-flex justify-center w-full rounded-md border-indigo-600 shadow-sm px-4 py-2 text-base font-medium text-indigo-600 border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
											onClick={() => setOpen(false)}
										>
											Cancel
										</button>

										<button
											type="button"
											className="col-start-3 col-end-5 md:col-start-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
											onClick={() =>
												confirm ? changeChild(true) : setOpen(false)
											}
										>
											{confirm ? 'Send Quote' : 'Continue'}
										</button>
									</>
								)}
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
