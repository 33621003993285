import {Fragment} from 'react';
import {contractHeaderList} from '../../../../constants';
import {classNames} from '../../../../utils';

export default function ContractHeader(props) {
	const {ctHeader, headerctHeader} = props;
	return (
		<div className="flex flex-row max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
			<div className="w-full md:w-5/12 grid md:grid-flow-col gap-3">
				{contractHeaderList.flatMap((val, index) => {
					return (
						<Fragment key={index}>
							<button
								type="button"
								onClick={(e) => headerctHeader(e, val)}
								className={classNames(
									val === ctHeader
										? 'bg-indigo-700 text-gray-100'
										: 'text-gray-500 bg-gray-300 hover:text-gray-700',
									'px-3 py-2 font-medium text-sm rounded-md h-10'
								)}
							>
								{val}
							</button>
						</Fragment>
					);
				})}
			</div>
		</div>
	);
}
