import { API_BASE_URL } from '../utils/reduxMethods'
import RestClient from '../utils/RestClient';
import axios from 'axios';

export const verifyAction = (email, code) => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/Account/verifycode?Email=${email}&code=${code}`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const getDropdownDataAction = () => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/Dropdown/getall`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};
/**
 * Fetching dropdown values
 * 
 */

export const getDropdownDataAPI = (d) => {
    const token = 'Bearer ' + d
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/Dropdown/getall`, {
            headers: {
                Authorization: token
            }
        }).then((data) => {
            localStorage.setItem('filters', JSON.stringify(data.data.value));

        }).catch(err => {
            reject(err);
        })
    })
}

export const resenCodeAction = (email) => {
    return new Promise((resolve, reject) => {
        RestClient
          .get(`${API_BASE_URL}/Account/resendcode?email=${email}`)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const loginAction = (obj) => {
    return new Promise((resolve, reject) => {
        RestClient
          .post(
            `${API_BASE_URL}/Account/signin`,
            JSON.stringify(obj)
          )
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
};

export const logoutAction = history => {
    deleteLocalStorageExcept('app_version')
    history.push('/');
}
export const deleteLocalStorageExcept = (key) => {
    const itemValue = localStorage.getItem(key);
    localStorage.clear();
    localStorage.setItem(key, itemValue);
}


/**
 * Fetching old notifications
 * 
 */

export const getNotificationAPI = () => {
    const token = 'Bearer ' + localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/api/Notification/GetMyNotifications`, {
            headers: {
                Authorization: token
            }
        }).then((data) => {
            resolve(data.data.value)

        }).catch(err => {
            reject([]);
        })
    })
}

export const readMotificationAPI = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/api/Notification/MarkRead`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * forgot password Account/forgotpassword
 */

export const forgotPassword = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Account/forgotpassword`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}

/**Reset Password /Account/resetpassword*/
export const resetPassword = (obj) => {

    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Account/resetpassword`, JSON.stringify(obj)).then((resp) => {
            resolve(resp.data)
        }).catch(err => {
            reject(err);
        })
    })
}
// save oneSignal Id token into DB
export const saveOneSignalToken = (tokenObj) => {
    return new Promise((resolve, reject) => {
        RestClient.post(`${API_BASE_URL}/Token/SaveToken`, JSON.stringify(tokenObj)).then((resp) => {
            //

            //
        })
    })
}