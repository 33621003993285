/* eslint-disable jsx-a11y/anchor-is-valid */
import {CheckIcon} from '@heroicons/react/solid';
import {Fragment, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function DirectorSteps({index}) {
	// const [tabIndex, setTabIndex] = useState(index);
	const steps = [
		{
			name: 'Create your account',
			description: 'Enter your account details to begin your registration.',
			href: '#',
			status: index === 0 ? 'current' : index > 0 ? 'complete' : 'upcoming',
		},
		{
			name: 'Verification Step',
			description: 'Verify your contact information.',
			href: '#',
			status: index === 1 ? 'current' : index > 1 ? 'complete' : 'upcoming',
		},
	];

	return (
		<>
			<Tabs selectedIndex={index} className="h-full w-full">
				<nav aria-label="Progress" className="hidden md:block">
					<TabList>
						<ol className="overflow-hidden">
							{steps.map((step, stepIdx) => (
								<li
									key={step.name}
									className={classNames(
										stepIdx !== steps.length - 1 ? 'pb-10' : '',
										'relative'
									)}
								>
									{step.status === 'complete' ? (
										<>
											{stepIdx !== steps.length - 1 ? (
												<div
													className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-200 bg-opacity-40"
													aria-hidden="true"
												/>
											) : null}
											<a
												href={step.href}
												className="relative flex items-start group"
											>
												<span className="h-9 flex items-center text-indigo-900">
													<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-yellow-500 rounded-full group-hover:bg-yellow-400">
														<CheckIcon
															className="w-5 h-5 text-indigo-900"
															aria-hidden="true"
														/>
													</span>
												</span>
												<span className="ml-4 min-w-0 flex flex-col">
													<span className="text-xs text-gray-200 font-semibold tracking-wide uppercase">
														{step.name}
													</span>
													<span className="text-sm text-gray-400">
														{step.description}
													</span>
												</span>
											</a>
										</>
									) : step.status === 'current' ? (
										<>
											{stepIdx !== steps.length - 1 ? (
												<div
													className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-200 bg-opacity-40"
													aria-hidden="true"
												/>
											) : null}
											<a
												href={step.href}
												className="relative flex items-start group"
												aria-current="step"
											>
												<span
													className="h-9 flex items-center"
													aria-hidden="true"
												>
													<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-900 border-2 border-yellow-500 rounded-full">
														<span className="h-2.5 w-2.5 bg-yellow-500 rounded-full" />
													</span>
												</span>
												<span className="ml-4 min-w-0 flex flex-col">
													<span className="text-xs font-semibold tracking-wide uppercase text-white">
														{step.name}
													</span>
													<span className="text-sm text-gray-400">
														{step.description}
													</span>
												</span>
											</a>
										</>
									) : (
										<>
											{stepIdx !== steps.length - 1 ? (
												<div
													className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-200 bg-opacity-40"
													aria-hidden="true"
												/>
											) : null}
											<a
												href={step.href}
												className="relative flex items-start group"
											>
												<span
													className="h-9 flex items-center"
													aria-hidden="true"
												>
													<span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-900 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
														<span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
													</span>
												</span>
												<span className="ml-4 min-w-0 flex flex-col">
													<span className="text-xs font-semibold tracking-wide uppercase text-white text-opacity-40">
														{step.name}
													</span>
													<span className="text-sm text-white text-opacity-30">
														{step.description}
													</span>
												</span>
											</a>
										</>
									)}
								</li>
							))}
						</ol>
					</TabList>
				</nav>
				<nav
					aria-label="Progress flex justify-center hidden"
					className="md:hidden"
				>
					<TabList>
						<ol className="flex justify-center">
							{steps.map((step, stepIdx) => (
								<li
									key={step.name}
									className={classNames(
										stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '',
										'relative'
									)}
								>
									{step.status === 'complete' ? (
										<>
											<div
												className="absolute inset-0 flex items-center"
												aria-hidden="true"
											>
												<div className="h-0.5 w-full bg-yellow-500" />
											</div>
											<a
												href="#"
												className="relative w-8 h-8 flex items-center justify-center bg-yellow-500 rounded-full hover:bg-indigo-900"
											>
												<CheckIcon
													className="w-5 h-5 text-white"
													aria-hidden="true"
												/>
												<span className="sr-only">{step.name}</span>
											</a>
										</>
									) : step.status === 'current' ? (
										<>
											<div
												className="absolute inset-0 flex items-center"
												aria-hidden="true"
											>
												<div className="h-0.5 w-full bg-gray-200" />
											</div>
											<a
												href="#"
												className="relative w-8 h-8 flex items-center justify-center bg-indigo-900 border-2 border-yellow-500 rounded-full"
												aria-current="step"
											>
												<span
													className="h-2.5 w-2.5 bg-yellow-500 rounded-full"
													aria-hidden="true"
												/>
												<span className="sr-only">{step.name}</span>
											</a>
										</>
									) : (
										<>
											<div
												className="absolute inset-0 flex items-center"
												aria-hidden="true"
											>
												<div className="h-0.5 w-full bg-gray-200" />
											</div>
											<a
												href="#"
												className="group relative w-8 h-8 flex items-center justify-center bg-indigo-900 border-2 border-gray-300 rounded-full hover:border-gray-400"
											>
												<span
													className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
													aria-hidden="true"
												/>
												<span className="sr-only">{step.name}</span>
											</a>
										</>
									)}
								</li>
							))}
						</ol>
						<div className="text-center mt-4 text-white">
							{index === 0 ? 'Create your account' : 'Verification Step'}
						</div>
					</TabList>
				</nav>
			</Tabs>
		</>
	);
}
